
.bootstrap-touchspin .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 10px 10px;
  margin-left: -1px;
  position: relative;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
  border-top-right-radius: 4px;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: -2px;
  border-radius: 0;
  border-bottom-right-radius: 4px;
}

.bootstrap-touchspin .input-group-btn-vertical i {
  position: absolute;
  top: 3px;
  left: 5px;
  font-size: 14px;
  font-weight: normal;
}


.bootstrap-touchspin .input-group-btn button{
  padding: 0.85rem 1rem;
}