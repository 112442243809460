.white {
  color: #FFFFFF !important; }

.bg-white {
  background-color: #FFFFFF !important; }
  .bg-white .card-header, .bg-white .card-footer {
    background-color: transparent; }

.alert-white {
  border-color: #FFFFFF !important;
  background-color: white !important;
  color: #a6a6a6 !important; }
  .alert-white .alert-link {
    color: #919191 !important; }

.border-white {
  border-color: #FFFFFF; }

.bg-white.badge-glow, .border-white.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #FFFFFF;
  box-shadow: 0px 0px 10px #FFFFFF; }

.overlay-white {
  background: #FFFFFF;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8); }

/* .card-outline-white{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-white {
  border-color: !important;
  background-color: #FFFFFF !important;
  color: #FFFFFF; }
  .btn-white:hover {
    border-color: !important;
    background-color: !important;
    color: #FFF !important; }
  .btn-white:focus, .btn-white:active {
    border-color: !important;
    background-color: !important;
    color: #FFF !important; }

.btn-outline-white {
  border-color: #FFFFFF;
  background-color: transparent;
  color: #FFFFFF; }
  .btn-outline-white:hover {
    background-color: #FFFFFF;
    color: #FFF !important; }

input[type="checkbox"].bg-white + .custom-control-label:before, input[type="radio"].bg-white + .custom-control-label:before {
  background-color: #FFFFFF !important; }

input:focus ~ .bg-white {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important; }

.border-white {
  border: 1px solid #FFFFFF !important; }

.border-top-white {
  border-top: 1px solid #FFFFFF; }

.border-bottom-white {
  border-bottom: 1px solid #FFFFFF; }

.border-left-white {
  border-left: 1px solid #FFFFFF; }

.border-right-white {
  border-right: 1px solid #FFFFFF; }

.black {
  color: #000000 !important; }

.bg-black {
  background-color: #000000 !important; }
  .bg-black .card-header, .bg-black .card-footer {
    background-color: transparent; }

.alert-black {
  border-color: #000000 !important;
  background-color: #242424 !important;
  color: black !important; }
  .alert-black .alert-link {
    color: black !important; }

.border-black {
  border-color: #000000; }

.bg-black.badge-glow, .border-black.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #000000;
  box-shadow: 0px 0px 10px #000000; }

.overlay-black {
  background: #000000;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.8); }

/* .card-outline-black{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-black {
  border-color: !important;
  background-color: #000000 !important;
  color: #FFFFFF; }
  .btn-black:hover {
    border-color: !important;
    background-color: !important;
    color: #FFF !important; }
  .btn-black:focus, .btn-black:active {
    border-color: !important;
    background-color: !important;
    color: #FFF !important; }

.btn-outline-black {
  border-color: #000000;
  background-color: transparent;
  color: #000000; }
  .btn-outline-black:hover {
    background-color: #000000;
    color: #FFF !important; }

input[type="checkbox"].bg-black + .custom-control-label:before, input[type="radio"].bg-black + .custom-control-label:before {
  background-color: #000000 !important; }

input:focus ~ .bg-black {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000 !important; }

.border-black {
  border: 1px solid #000000 !important; }

.border-top-black {
  border-top: 1px solid #000000; }

.border-bottom-black {
  border-bottom: 1px solid #000000; }

.border-left-black {
  border-left: 1px solid #000000; }

.border-right-black {
  border-right: 1px solid #000000; }

.primary.lighten-5 {
  color: #E0F6F6 !important; }

.bg-primary.bg-lighten-5 {
  background-color: #E0F6F6 !important; }

.btn-primary.btn-lighten-5 {
  border-color: #00A5A8 !important;
  background-color: #E0F6F6 !important; }
  .btn-primary.btn-lighten-5:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-lighten-5:focus, .btn-primary.btn-lighten-5:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-lighten-5 {
  border-color: #E0F6F6 !important;
  color: #E0F6F6 !important; }
  .btn-outline-primary.btn-outline-lighten-5:hover {
    background-color: #E0F6F6 !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E0F6F6 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E0F6F6 !important; }

.border-primary.border-lighten-5 {
  border: 1px solid #E0F6F6 !important; }

.border-top-primary.border-top-lighten-5 {
  border-top: 1px solid #E0F6F6 !important; }

.border-bottom-primary.border-bottom-lighten-5 {
  border-bottom: 1px solid #E0F6F6 !important; }

.border-left-primary.border-left-lighten-5 {
  border-left: 1px solid #E0F6F6 !important; }

.border-right-primary.border-right-lighten-5 {
  border-right: 1px solid #E0F6F6 !important; }

.overlay-primary.overlay-lighten-5 {
  background: #E0F6F6;
  /* The Fallback */
  background: rgba(224, 246, 246, 0.8); }

.primary.lighten-4 {
  color: #B3E9EA !important; }

.bg-primary.bg-lighten-4 {
  background-color: #B3E9EA !important; }

.btn-primary.btn-lighten-4 {
  border-color: #00A5A8 !important;
  background-color: #B3E9EA !important; }
  .btn-primary.btn-lighten-4:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-lighten-4:focus, .btn-primary.btn-lighten-4:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-lighten-4 {
  border-color: #B3E9EA !important;
  color: #B3E9EA !important; }
  .btn-outline-primary.btn-outline-lighten-4:hover {
    background-color: #B3E9EA !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B3E9EA !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B3E9EA !important; }

.border-primary.border-lighten-4 {
  border: 1px solid #B3E9EA !important; }

.border-top-primary.border-top-lighten-4 {
  border-top: 1px solid #B3E9EA !important; }

.border-bottom-primary.border-bottom-lighten-4 {
  border-bottom: 1px solid #B3E9EA !important; }

.border-left-primary.border-left-lighten-4 {
  border-left: 1px solid #B3E9EA !important; }

.border-right-primary.border-right-lighten-4 {
  border-right: 1px solid #B3E9EA !important; }

.overlay-primary.overlay-lighten-4 {
  background: #B3E9EA;
  /* The Fallback */
  background: rgba(179, 233, 234, 0.8); }

.primary.lighten-3 {
  color: #80DADC !important; }

.bg-primary.bg-lighten-3 {
  background-color: #80DADC !important; }

.btn-primary.btn-lighten-3 {
  border-color: #00A5A8 !important;
  background-color: #80DADC !important; }
  .btn-primary.btn-lighten-3:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-lighten-3:focus, .btn-primary.btn-lighten-3:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-lighten-3 {
  border-color: #80DADC !important;
  color: #80DADC !important; }
  .btn-outline-primary.btn-outline-lighten-3:hover {
    background-color: #80DADC !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80DADC !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80DADC !important; }

.border-primary.border-lighten-3 {
  border: 1px solid #80DADC !important; }

.border-top-primary.border-top-lighten-3 {
  border-top: 1px solid #80DADC !important; }

.border-bottom-primary.border-bottom-lighten-3 {
  border-bottom: 1px solid #80DADC !important; }

.border-left-primary.border-left-lighten-3 {
  border-left: 1px solid #80DADC !important; }

.border-right-primary.border-right-lighten-3 {
  border-right: 1px solid #80DADC !important; }

.overlay-primary.overlay-lighten-3 {
  background: #80DADC;
  /* The Fallback */
  background: rgba(128, 218, 220, 0.8); }

.primary.lighten-2 {
  color: #4DCBCD !important; }

.bg-primary.bg-lighten-2 {
  background-color: #4DCBCD !important; }

.btn-primary.btn-lighten-2 {
  border-color: #00A5A8 !important;
  background-color: #4DCBCD !important; }
  .btn-primary.btn-lighten-2:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-lighten-2:focus, .btn-primary.btn-lighten-2:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-lighten-2 {
  border-color: #4DCBCD !important;
  color: #4DCBCD !important; }
  .btn-outline-primary.btn-outline-lighten-2:hover {
    background-color: #4DCBCD !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4DCBCD !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4DCBCD !important; }

.border-primary.border-lighten-2 {
  border: 1px solid #4DCBCD !important; }

.border-top-primary.border-top-lighten-2 {
  border-top: 1px solid #4DCBCD !important; }

.border-bottom-primary.border-bottom-lighten-2 {
  border-bottom: 1px solid #4DCBCD !important; }

.border-left-primary.border-left-lighten-2 {
  border-left: 1px solid #4DCBCD !important; }

.border-right-primary.border-right-lighten-2 {
  border-right: 1px solid #4DCBCD !important; }

.overlay-primary.overlay-lighten-2 {
  background: #4DCBCD;
  /* The Fallback */
  background: rgba(77, 203, 205, 0.8); }

.primary.lighten-1 {
  color: #26C0C3 !important; }

.bg-primary.bg-lighten-1 {
  background-color: #26C0C3 !important; }

.btn-primary.btn-lighten-1 {
  border-color: #00A5A8 !important;
  background-color: #26C0C3 !important; }
  .btn-primary.btn-lighten-1:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-lighten-1:focus, .btn-primary.btn-lighten-1:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-lighten-1 {
  border-color: #26C0C3 !important;
  color: #26C0C3 !important; }
  .btn-outline-primary.btn-outline-lighten-1:hover {
    background-color: #26C0C3 !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26C0C3 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26C0C3 !important; }

.border-primary.border-lighten-1 {
  border: 1px solid #26C0C3 !important; }

.border-top-primary.border-top-lighten-1 {
  border-top: 1px solid #26C0C3 !important; }

.border-bottom-primary.border-bottom-lighten-1 {
  border-bottom: 1px solid #26C0C3 !important; }

.border-left-primary.border-left-lighten-1 {
  border-left: 1px solid #26C0C3 !important; }

.border-right-primary.border-right-lighten-1 {
  border-right: 1px solid #26C0C3 !important; }

.overlay-primary.overlay-lighten-1 {
  background: #26C0C3;
  /* The Fallback */
  background: rgba(38, 192, 195, 0.8); }

.primary {
  color: #00B5B8 !important; }

.bg-primary {
  background-color: #00B5B8 !important; }
  .bg-primary .card-header, .bg-primary .card-footer {
    background-color: transparent; }

.alert-primary {
  border-color: #00B5B8 !important;
  background-color: #00fbff !important;
  color: #000506 !important; }
  .alert-primary .alert-link {
    color: black !important; }

.border-primary {
  border-color: #00B5B8; }

.bg-primary.badge-glow, .border-primary.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #00B5B8;
  box-shadow: 0px 0px 10px #00B5B8; }

.overlay-primary {
  background: #00B5B8;
  /* The Fallback */
  background: rgba(0, 181, 184, 0.8); }

/* .card-outline-primary{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-primary {
  border-color: #00A5A8 !important;
  background-color: #00B5B8 !important;
  color: #FFFFFF; }
  .btn-primary:hover {
    border-color: #00AEB1 !important;
    background-color: #26C0C3 !important;
    color: #FFF !important; }
  .btn-primary:focus, .btn-primary:active {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important;
    color: #FFF !important; }

.btn-outline-primary {
  border-color: #00B5B8;
  background-color: transparent;
  color: #00B5B8; }
  .btn-outline-primary:hover {
    background-color: #00B5B8;
    color: #FFF !important; }

input[type="checkbox"].bg-primary + .custom-control-label:before, input[type="radio"].bg-primary + .custom-control-label:before {
  background-color: #00B5B8 !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00B5B8 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00B5B8 !important; }

.border-primary {
  border: 1px solid #00B5B8 !important; }

.border-top-primary {
  border-top: 1px solid #00B5B8; }

.border-bottom-primary {
  border-bottom: 1px solid #00B5B8; }

.border-left-primary {
  border-left: 1px solid #00B5B8; }

.border-right-primary {
  border-right: 1px solid #00B5B8; }

.primary.darken-1 {
  color: #00AEB1 !important; }

.bg-primary.bg-darken-1 {
  background-color: #00AEB1 !important; }

.btn-primary.btn-darken-1 {
  border-color: #00A5A8 !important;
  background-color: #00AEB1 !important; }
  .btn-primary.btn-darken-1:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-darken-1:focus, .btn-primary.btn-darken-1:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-darken-1 {
  border-color: #00AEB1 !important;
  color: #00AEB1 !important; }
  .btn-outline-primary.btn-outline-darken-1:hover {
    background-color: #00AEB1 !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00AEB1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00AEB1 !important; }

.border-primary.border-darken-1 {
  border: 1px solid #00AEB1 !important; }

.border-top-primary.border-top-darken-1 {
  border-top: 1px solid #00AEB1 !important; }

.border-bottom-primary.border-bottom-darken-1 {
  border-bottom: 1px solid #00AEB1 !important; }

.border-left-primary.border-left-darken-1 {
  border-left: 1px solid #00AEB1 !important; }

.border-right-primary.border-right-darken-1 {
  border-right: 1px solid #00AEB1 !important; }

.overlay-primary.overlay-darken-1 {
  background: #00AEB1;
  /* The Fallback */
  background: rgba(0, 174, 177, 0.8); }

.primary.darken-2 {
  color: #00A5A8 !important; }

.bg-primary.bg-darken-2 {
  background-color: #00A5A8 !important; }

.btn-primary.btn-darken-2 {
  border-color: #00A5A8 !important;
  background-color: #00A5A8 !important; }
  .btn-primary.btn-darken-2:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-darken-2:focus, .btn-primary.btn-darken-2:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-darken-2 {
  border-color: #00A5A8 !important;
  color: #00A5A8 !important; }
  .btn-outline-primary.btn-outline-darken-2:hover {
    background-color: #00A5A8 !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00A5A8 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00A5A8 !important; }

.border-primary.border-darken-2 {
  border: 1px solid #00A5A8 !important; }

.border-top-primary.border-top-darken-2 {
  border-top: 1px solid #00A5A8 !important; }

.border-bottom-primary.border-bottom-darken-2 {
  border-bottom: 1px solid #00A5A8 !important; }

.border-left-primary.border-left-darken-2 {
  border-left: 1px solid #00A5A8 !important; }

.border-right-primary.border-right-darken-2 {
  border-right: 1px solid #00A5A8 !important; }

.overlay-primary.overlay-darken-2 {
  background: #00A5A8;
  /* The Fallback */
  background: rgba(0, 165, 168, 0.8); }

.primary.darken-3 {
  color: #009DA0 !important; }

.bg-primary.bg-darken-3 {
  background-color: #009DA0 !important; }

.btn-primary.btn-darken-3 {
  border-color: #00A5A8 !important;
  background-color: #009DA0 !important; }
  .btn-primary.btn-darken-3:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-darken-3:focus, .btn-primary.btn-darken-3:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-darken-3 {
  border-color: #009DA0 !important;
  color: #009DA0 !important; }
  .btn-outline-primary.btn-outline-darken-3:hover {
    background-color: #009DA0 !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009DA0 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009DA0 !important; }

.border-primary.border-darken-3 {
  border: 1px solid #009DA0 !important; }

.border-top-primary.border-top-darken-3 {
  border-top: 1px solid #009DA0 !important; }

.border-bottom-primary.border-bottom-darken-3 {
  border-bottom: 1px solid #009DA0 !important; }

.border-left-primary.border-left-darken-3 {
  border-left: 1px solid #009DA0 !important; }

.border-right-primary.border-right-darken-3 {
  border-right: 1px solid #009DA0 !important; }

.overlay-primary.overlay-darken-3 {
  background: #009DA0;
  /* The Fallback */
  background: rgba(0, 157, 160, 0.8); }

.primary.darken-4 {
  color: #008D91 !important; }

.bg-primary.bg-darken-4 {
  background-color: #008D91 !important; }

.btn-primary.btn-darken-4 {
  border-color: #00A5A8 !important;
  background-color: #008D91 !important; }
  .btn-primary.btn-darken-4:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-darken-4:focus, .btn-primary.btn-darken-4:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-darken-4 {
  border-color: #008D91 !important;
  color: #008D91 !important; }
  .btn-outline-primary.btn-outline-darken-4:hover {
    background-color: #008D91 !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #008D91 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #008D91 !important; }

.border-primary.border-darken-4 {
  border: 1px solid #008D91 !important; }

.border-top-primary.border-top-darken-4 {
  border-top: 1px solid #008D91 !important; }

.border-bottom-primary.border-bottom-darken-4 {
  border-bottom: 1px solid #008D91 !important; }

.border-left-primary.border-left-darken-4 {
  border-left: 1px solid #008D91 !important; }

.border-right-primary.border-right-darken-4 {
  border-right: 1px solid #008D91 !important; }

.overlay-primary.overlay-darken-4 {
  background: #008D91;
  /* The Fallback */
  background: rgba(0, 141, 145, 0.8); }

.primary.accent-1 {
  color: #BDFDFF !important; }

.bg-primary.bg-accent-1 {
  background-color: #BDFDFF !important; }

.btn-primary.btn-accent-1 {
  border-color: #00A5A8 !important;
  background-color: #BDFDFF !important; }
  .btn-primary.btn-accent-1:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-accent-1:focus, .btn-primary.btn-accent-1:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-accent-1 {
  border-color: #BDFDFF !important;
  color: #BDFDFF !important; }
  .btn-outline-primary.btn-outline-accent-1:hover {
    background-color: #BDFDFF !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BDFDFF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BDFDFF !important; }

.border-primary.border-accent-1 {
  border: 1px solid #BDFDFF !important; }

.border-top-primary.border-top-accent-1 {
  border-top: 1px solid #BDFDFF !important; }

.border-bottom-primary.border-bottom-accent-1 {
  border-bottom: 1px solid #BDFDFF !important; }

.border-left-primary.border-left-accent-1 {
  border-left: 1px solid #BDFDFF !important; }

.border-right-primary.border-right-accent-1 {
  border-right: 1px solid #BDFDFF !important; }

.overlay-primary.overlay-accent-1 {
  background: #BDFDFF;
  /* The Fallback */
  background: rgba(189, 253, 255, 0.8); }

.primary.accent-2 {
  color: #8AFBFF !important; }

.bg-primary.bg-accent-2 {
  background-color: #8AFBFF !important; }

.btn-primary.btn-accent-2 {
  border-color: #00A5A8 !important;
  background-color: #8AFBFF !important; }
  .btn-primary.btn-accent-2:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-accent-2:focus, .btn-primary.btn-accent-2:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-accent-2 {
  border-color: #8AFBFF !important;
  color: #8AFBFF !important; }
  .btn-outline-primary.btn-outline-accent-2:hover {
    background-color: #8AFBFF !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8AFBFF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8AFBFF !important; }

.border-primary.border-accent-2 {
  border: 1px solid #8AFBFF !important; }

.border-top-primary.border-top-accent-2 {
  border-top: 1px solid #8AFBFF !important; }

.border-bottom-primary.border-bottom-accent-2 {
  border-bottom: 1px solid #8AFBFF !important; }

.border-left-primary.border-left-accent-2 {
  border-left: 1px solid #8AFBFF !important; }

.border-right-primary.border-right-accent-2 {
  border-right: 1px solid #8AFBFF !important; }

.overlay-primary.overlay-accent-2 {
  background: #8AFBFF;
  /* The Fallback */
  background: rgba(138, 251, 255, 0.8); }

.primary.accent-3 {
  color: #57FAFF !important; }

.bg-primary.bg-accent-3 {
  background-color: #57FAFF !important; }

.btn-primary.btn-accent-3 {
  border-color: #00A5A8 !important;
  background-color: #57FAFF !important; }
  .btn-primary.btn-accent-3:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-accent-3:focus, .btn-primary.btn-accent-3:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-accent-3 {
  border-color: #57FAFF !important;
  color: #57FAFF !important; }
  .btn-outline-primary.btn-outline-accent-3:hover {
    background-color: #57FAFF !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #57FAFF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #57FAFF !important; }

.border-primary.border-accent-3 {
  border: 1px solid #57FAFF !important; }

.border-top-primary.border-top-accent-3 {
  border-top: 1px solid #57FAFF !important; }

.border-bottom-primary.border-bottom-accent-3 {
  border-bottom: 1px solid #57FAFF !important; }

.border-left-primary.border-left-accent-3 {
  border-left: 1px solid #57FAFF !important; }

.border-right-primary.border-right-accent-3 {
  border-right: 1px solid #57FAFF !important; }

.overlay-primary.overlay-accent-3 {
  background: #57FAFF;
  /* The Fallback */
  background: rgba(87, 250, 255, 0.8); }

.primary.accent-4 {
  color: #3DF9FF !important; }

.bg-primary.bg-accent-4 {
  background-color: #3DF9FF !important; }

.btn-primary.btn-accent-4 {
  border-color: #00A5A8 !important;
  background-color: #3DF9FF !important; }
  .btn-primary.btn-accent-4:hover {
    border-color: #00A5A8 !important;
    background-color: #009DA0 !important; }
  .btn-primary.btn-accent-4:focus, .btn-primary.btn-accent-4:active {
    border-color: #009DA0 !important;
    background-color: #008D91 !important; }

.btn-outline-primary.btn-outline-accent-4 {
  border-color: #3DF9FF !important;
  color: #3DF9FF !important; }
  .btn-outline-primary.btn-outline-accent-4:hover {
    background-color: #3DF9FF !important; }

input:focus ~ .bg-primary {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3DF9FF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3DF9FF !important; }

.border-primary.border-accent-4 {
  border: 1px solid #3DF9FF !important; }

.border-top-primary.border-top-accent-4 {
  border-top: 1px solid #3DF9FF !important; }

.border-bottom-primary.border-bottom-accent-4 {
  border-bottom: 1px solid #3DF9FF !important; }

.border-left-primary.border-left-accent-4 {
  border-left: 1px solid #3DF9FF !important; }

.border-right-primary.border-right-accent-4 {
  border-right: 1px solid #3DF9FF !important; }

.overlay-primary.overlay-accent-4 {
  background: #3DF9FF;
  /* The Fallback */
  background: rgba(61, 249, 255, 0.8); }

.success.lighten-5 {
  color: #E3FAF3 !important; }

.bg-success.bg-lighten-5 {
  background-color: #E3FAF3 !important; }

.btn-success.btn-lighten-5 {
  border-color: #10C888 !important;
  background-color: #E3FAF3 !important; }
  .btn-success.btn-lighten-5:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-lighten-5:focus, .btn-success.btn-lighten-5:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-lighten-5 {
  border-color: #E3FAF3 !important;
  color: #E3FAF3 !important; }
  .btn-outline-success.btn-outline-lighten-5:hover {
    background-color: #E3FAF3 !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E3FAF3 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E3FAF3 !important; }

.border-success.border-lighten-5 {
  border: 1px solid #E3FAF3 !important; }

.border-top-success.border-top-lighten-5 {
  border-top: 1px solid #E3FAF3 !important; }

.border-bottom-success.border-bottom-lighten-5 {
  border-bottom: 1px solid #E3FAF3 !important; }

.border-left-success.border-left-lighten-5 {
  border-left: 1px solid #E3FAF3 !important; }

.border-right-success.border-right-lighten-5 {
  border-right: 1px solid #E3FAF3 !important; }

.overlay-success.overlay-lighten-5 {
  background: #E3FAF3;
  /* The Fallback */
  background: rgba(227, 250, 243, 0.8); }

.success.lighten-4 {
  color: #B9F2E1 !important; }

.bg-success.bg-lighten-4 {
  background-color: #B9F2E1 !important; }

.btn-success.btn-lighten-4 {
  border-color: #10C888 !important;
  background-color: #B9F2E1 !important; }
  .btn-success.btn-lighten-4:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-lighten-4:focus, .btn-success.btn-lighten-4:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-lighten-4 {
  border-color: #B9F2E1 !important;
  color: #B9F2E1 !important; }
  .btn-outline-success.btn-outline-lighten-4:hover {
    background-color: #B9F2E1 !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B9F2E1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B9F2E1 !important; }

.border-success.border-lighten-4 {
  border: 1px solid #B9F2E1 !important; }

.border-top-success.border-top-lighten-4 {
  border-top: 1px solid #B9F2E1 !important; }

.border-bottom-success.border-bottom-lighten-4 {
  border-bottom: 1px solid #B9F2E1 !important; }

.border-left-success.border-left-lighten-4 {
  border-left: 1px solid #B9F2E1 !important; }

.border-right-success.border-right-lighten-4 {
  border-right: 1px solid #B9F2E1 !important; }

.overlay-success.overlay-lighten-4 {
  background: #B9F2E1;
  /* The Fallback */
  background: rgba(185, 242, 225, 0.8); }

.success.lighten-3 {
  color: #8BE9CD !important; }

.bg-success.bg-lighten-3 {
  background-color: #8BE9CD !important; }

.btn-success.btn-lighten-3 {
  border-color: #10C888 !important;
  background-color: #8BE9CD !important; }
  .btn-success.btn-lighten-3:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-lighten-3:focus, .btn-success.btn-lighten-3:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-lighten-3 {
  border-color: #8BE9CD !important;
  color: #8BE9CD !important; }
  .btn-outline-success.btn-outline-lighten-3:hover {
    background-color: #8BE9CD !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8BE9CD !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8BE9CD !important; }

.border-success.border-lighten-3 {
  border: 1px solid #8BE9CD !important; }

.border-top-success.border-top-lighten-3 {
  border-top: 1px solid #8BE9CD !important; }

.border-bottom-success.border-bottom-lighten-3 {
  border-bottom: 1px solid #8BE9CD !important; }

.border-left-success.border-left-lighten-3 {
  border-left: 1px solid #8BE9CD !important; }

.border-right-success.border-right-lighten-3 {
  border-right: 1px solid #8BE9CD !important; }

.overlay-success.overlay-lighten-3 {
  background: #8BE9CD;
  /* The Fallback */
  background: rgba(139, 233, 205, 0.8); }

.success.lighten-2 {
  color: #5CE0B8 !important; }

.bg-success.bg-lighten-2 {
  background-color: #5CE0B8 !important; }

.btn-success.btn-lighten-2 {
  border-color: #10C888 !important;
  background-color: #5CE0B8 !important; }
  .btn-success.btn-lighten-2:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-lighten-2:focus, .btn-success.btn-lighten-2:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-lighten-2 {
  border-color: #5CE0B8 !important;
  color: #5CE0B8 !important; }
  .btn-outline-success.btn-outline-lighten-2:hover {
    background-color: #5CE0B8 !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5CE0B8 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5CE0B8 !important; }

.border-success.border-lighten-2 {
  border: 1px solid #5CE0B8 !important; }

.border-top-success.border-top-lighten-2 {
  border-top: 1px solid #5CE0B8 !important; }

.border-bottom-success.border-bottom-lighten-2 {
  border-bottom: 1px solid #5CE0B8 !important; }

.border-left-success.border-left-lighten-2 {
  border-left: 1px solid #5CE0B8 !important; }

.border-right-success.border-right-lighten-2 {
  border-right: 1px solid #5CE0B8 !important; }

.overlay-success.overlay-lighten-2 {
  background: #5CE0B8;
  /* The Fallback */
  background: rgba(92, 224, 184, 0.8); }

.success.lighten-1 {
  color: #39DAA9 !important; }

.bg-success.bg-lighten-1 {
  background-color: #39DAA9 !important; }

.btn-success.btn-lighten-1 {
  border-color: #10C888 !important;
  background-color: #39DAA9 !important; }
  .btn-success.btn-lighten-1:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-lighten-1:focus, .btn-success.btn-lighten-1:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-lighten-1 {
  border-color: #39DAA9 !important;
  color: #39DAA9 !important; }
  .btn-outline-success.btn-outline-lighten-1:hover {
    background-color: #39DAA9 !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #39DAA9 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #39DAA9 !important; }

.border-success.border-lighten-1 {
  border: 1px solid #39DAA9 !important; }

.border-top-success.border-top-lighten-1 {
  border-top: 1px solid #39DAA9 !important; }

.border-bottom-success.border-bottom-lighten-1 {
  border-bottom: 1px solid #39DAA9 !important; }

.border-left-success.border-left-lighten-1 {
  border-left: 1px solid #39DAA9 !important; }

.border-right-success.border-right-lighten-1 {
  border-right: 1px solid #39DAA9 !important; }

.overlay-success.overlay-lighten-1 {
  background: #39DAA9;
  /* The Fallback */
  background: rgba(57, 218, 169, 0.8); }

.success {
  color: #16D39A !important; }

.bg-success {
  background-color: #16D39A !important; }
  .bg-success .card-header, .bg-success .card-footer {
    background-color: transparent; }

.alert-success {
  border-color: #16D39A !important;
  background-color: #45ecb9 !important;
  color: #053124 !important; }
  .alert-success .alert-link {
    color: #010c09 !important; }

.border-success {
  border-color: #16D39A; }

.bg-success.badge-glow, .border-success.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #16D39A;
  box-shadow: 0px 0px 10px #16D39A; }

.overlay-success {
  background: #16D39A;
  /* The Fallback */
  background: rgba(22, 211, 154, 0.8); }

/* .card-outline-success{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-success {
  border-color: #10C888 !important;
  background-color: #16D39A !important;
  color: #FFFFFF; }
  .btn-success:hover {
    border-color: #13CE92 !important;
    background-color: #39DAA9 !important;
    color: #FFF !important; }
  .btn-success:focus, .btn-success:active {
    border-color: #10C888 !important;
    background-color: #0CC27E !important;
    color: #FFF !important; }

.btn-outline-success {
  border-color: #16D39A;
  background-color: transparent;
  color: #16D39A; }
  .btn-outline-success:hover {
    background-color: #16D39A;
    color: #FFF !important; }

input[type="checkbox"].bg-success + .custom-control-label:before, input[type="radio"].bg-success + .custom-control-label:before {
  background-color: #16D39A !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #16D39A !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #16D39A !important; }

.border-success {
  border: 1px solid #16D39A !important; }

.border-top-success {
  border-top: 1px solid #16D39A; }

.border-bottom-success {
  border-bottom: 1px solid #16D39A; }

.border-left-success {
  border-left: 1px solid #16D39A; }

.border-right-success {
  border-right: 1px solid #16D39A; }

.success.darken-1 {
  color: #13CE92 !important; }

.bg-success.bg-darken-1 {
  background-color: #13CE92 !important; }

.btn-success.btn-darken-1 {
  border-color: #10C888 !important;
  background-color: #13CE92 !important; }
  .btn-success.btn-darken-1:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-darken-1:focus, .btn-success.btn-darken-1:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-darken-1 {
  border-color: #13CE92 !important;
  color: #13CE92 !important; }
  .btn-outline-success.btn-outline-darken-1:hover {
    background-color: #13CE92 !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #13CE92 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #13CE92 !important; }

.border-success.border-darken-1 {
  border: 1px solid #13CE92 !important; }

.border-top-success.border-top-darken-1 {
  border-top: 1px solid #13CE92 !important; }

.border-bottom-success.border-bottom-darken-1 {
  border-bottom: 1px solid #13CE92 !important; }

.border-left-success.border-left-darken-1 {
  border-left: 1px solid #13CE92 !important; }

.border-right-success.border-right-darken-1 {
  border-right: 1px solid #13CE92 !important; }

.overlay-success.overlay-darken-1 {
  background: #13CE92;
  /* The Fallback */
  background: rgba(19, 206, 146, 0.8); }

.success.darken-2 {
  color: #10C888 !important; }

.bg-success.bg-darken-2 {
  background-color: #10C888 !important; }

.btn-success.btn-darken-2 {
  border-color: #10C888 !important;
  background-color: #10C888 !important; }
  .btn-success.btn-darken-2:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-darken-2:focus, .btn-success.btn-darken-2:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-darken-2 {
  border-color: #10C888 !important;
  color: #10C888 !important; }
  .btn-outline-success.btn-outline-darken-2:hover {
    background-color: #10C888 !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #10C888 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #10C888 !important; }

.border-success.border-darken-2 {
  border: 1px solid #10C888 !important; }

.border-top-success.border-top-darken-2 {
  border-top: 1px solid #10C888 !important; }

.border-bottom-success.border-bottom-darken-2 {
  border-bottom: 1px solid #10C888 !important; }

.border-left-success.border-left-darken-2 {
  border-left: 1px solid #10C888 !important; }

.border-right-success.border-right-darken-2 {
  border-right: 1px solid #10C888 !important; }

.overlay-success.overlay-darken-2 {
  background: #10C888;
  /* The Fallback */
  background: rgba(16, 200, 136, 0.8); }

.success.darken-3 {
  color: #0CC27E !important; }

.bg-success.bg-darken-3 {
  background-color: #0CC27E !important; }

.btn-success.btn-darken-3 {
  border-color: #10C888 !important;
  background-color: #0CC27E !important; }
  .btn-success.btn-darken-3:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-darken-3:focus, .btn-success.btn-darken-3:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-darken-3 {
  border-color: #0CC27E !important;
  color: #0CC27E !important; }
  .btn-outline-success.btn-outline-darken-3:hover {
    background-color: #0CC27E !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0CC27E !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0CC27E !important; }

.border-success.border-darken-3 {
  border: 1px solid #0CC27E !important; }

.border-top-success.border-top-darken-3 {
  border-top: 1px solid #0CC27E !important; }

.border-bottom-success.border-bottom-darken-3 {
  border-bottom: 1px solid #0CC27E !important; }

.border-left-success.border-left-darken-3 {
  border-left: 1px solid #0CC27E !important; }

.border-right-success.border-right-darken-3 {
  border-right: 1px solid #0CC27E !important; }

.overlay-success.overlay-darken-3 {
  background: #0CC27E;
  /* The Fallback */
  background: rgba(12, 194, 126, 0.8); }

.success.darken-4 {
  color: #06B76C !important; }

.bg-success.bg-darken-4 {
  background-color: #06B76C !important; }

.btn-success.btn-darken-4 {
  border-color: #10C888 !important;
  background-color: #06B76C !important; }
  .btn-success.btn-darken-4:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-darken-4:focus, .btn-success.btn-darken-4:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-darken-4 {
  border-color: #06B76C !important;
  color: #06B76C !important; }
  .btn-outline-success.btn-outline-darken-4:hover {
    background-color: #06B76C !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #06B76C !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #06B76C !important; }

.border-success.border-darken-4 {
  border: 1px solid #06B76C !important; }

.border-top-success.border-top-darken-4 {
  border-top: 1px solid #06B76C !important; }

.border-bottom-success.border-bottom-darken-4 {
  border-bottom: 1px solid #06B76C !important; }

.border-left-success.border-left-darken-4 {
  border-left: 1px solid #06B76C !important; }

.border-right-success.border-right-darken-4 {
  border-right: 1px solid #06B76C !important; }

.overlay-success.overlay-darken-4 {
  background: #06B76C;
  /* The Fallback */
  background: rgba(6, 183, 108, 0.8); }

.success.accent-1 {
  color: #E1FFF1 !important; }

.bg-success.bg-accent-1 {
  background-color: #E1FFF1 !important; }

.btn-success.btn-accent-1 {
  border-color: #10C888 !important;
  background-color: #E1FFF1 !important; }
  .btn-success.btn-accent-1:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-accent-1:focus, .btn-success.btn-accent-1:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-accent-1 {
  border-color: #E1FFF1 !important;
  color: #E1FFF1 !important; }
  .btn-outline-success.btn-outline-accent-1:hover {
    background-color: #E1FFF1 !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E1FFF1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E1FFF1 !important; }

.border-success.border-accent-1 {
  border: 1px solid #E1FFF1 !important; }

.border-top-success.border-top-accent-1 {
  border-top: 1px solid #E1FFF1 !important; }

.border-bottom-success.border-bottom-accent-1 {
  border-bottom: 1px solid #E1FFF1 !important; }

.border-left-success.border-left-accent-1 {
  border-left: 1px solid #E1FFF1 !important; }

.border-right-success.border-right-accent-1 {
  border-right: 1px solid #E1FFF1 !important; }

.overlay-success.overlay-accent-1 {
  background: #E1FFF1;
  /* The Fallback */
  background: rgba(225, 255, 241, 0.8); }

.success.accent-2 {
  color: #AEFFD9 !important; }

.bg-success.bg-accent-2 {
  background-color: #AEFFD9 !important; }

.btn-success.btn-accent-2 {
  border-color: #10C888 !important;
  background-color: #AEFFD9 !important; }
  .btn-success.btn-accent-2:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-accent-2:focus, .btn-success.btn-accent-2:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-accent-2 {
  border-color: #AEFFD9 !important;
  color: #AEFFD9 !important; }
  .btn-outline-success.btn-outline-accent-2:hover {
    background-color: #AEFFD9 !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AEFFD9 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AEFFD9 !important; }

.border-success.border-accent-2 {
  border: 1px solid #AEFFD9 !important; }

.border-top-success.border-top-accent-2 {
  border-top: 1px solid #AEFFD9 !important; }

.border-bottom-success.border-bottom-accent-2 {
  border-bottom: 1px solid #AEFFD9 !important; }

.border-left-success.border-left-accent-2 {
  border-left: 1px solid #AEFFD9 !important; }

.border-right-success.border-right-accent-2 {
  border-right: 1px solid #AEFFD9 !important; }

.overlay-success.overlay-accent-2 {
  background: #AEFFD9;
  /* The Fallback */
  background: rgba(174, 255, 217, 0.8); }

.success.accent-3 {
  color: #7BFFC1 !important; }

.bg-success.bg-accent-3 {
  background-color: #7BFFC1 !important; }

.btn-success.btn-accent-3 {
  border-color: #10C888 !important;
  background-color: #7BFFC1 !important; }
  .btn-success.btn-accent-3:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-accent-3:focus, .btn-success.btn-accent-3:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-accent-3 {
  border-color: #7BFFC1 !important;
  color: #7BFFC1 !important; }
  .btn-outline-success.btn-outline-accent-3:hover {
    background-color: #7BFFC1 !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7BFFC1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7BFFC1 !important; }

.border-success.border-accent-3 {
  border: 1px solid #7BFFC1 !important; }

.border-top-success.border-top-accent-3 {
  border-top: 1px solid #7BFFC1 !important; }

.border-bottom-success.border-bottom-accent-3 {
  border-bottom: 1px solid #7BFFC1 !important; }

.border-left-success.border-left-accent-3 {
  border-left: 1px solid #7BFFC1 !important; }

.border-right-success.border-right-accent-3 {
  border-right: 1px solid #7BFFC1 !important; }

.overlay-success.overlay-accent-3 {
  background: #7BFFC1;
  /* The Fallback */
  background: rgba(123, 255, 193, 0.8); }

.success.accent-4 {
  color: #62FFB5 !important; }

.bg-success.bg-accent-4 {
  background-color: #62FFB5 !important; }

.btn-success.btn-accent-4 {
  border-color: #10C888 !important;
  background-color: #62FFB5 !important; }
  .btn-success.btn-accent-4:hover {
    border-color: #10C888 !important;
    background-color: #0CC27E !important; }
  .btn-success.btn-accent-4:focus, .btn-success.btn-accent-4:active {
    border-color: #0CC27E !important;
    background-color: #06B76C !important; }

.btn-outline-success.btn-outline-accent-4 {
  border-color: #62FFB5 !important;
  color: #62FFB5 !important; }
  .btn-outline-success.btn-outline-accent-4:hover {
    background-color: #62FFB5 !important; }

input:focus ~ .bg-success {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #62FFB5 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #62FFB5 !important; }

.border-success.border-accent-4 {
  border: 1px solid #62FFB5 !important; }

.border-top-success.border-top-accent-4 {
  border-top: 1px solid #62FFB5 !important; }

.border-bottom-success.border-bottom-accent-4 {
  border-bottom: 1px solid #62FFB5 !important; }

.border-left-success.border-left-accent-4 {
  border-left: 1px solid #62FFB5 !important; }

.border-right-success.border-right-accent-4 {
  border-right: 1px solid #62FFB5 !important; }

.overlay-success.overlay-accent-4 {
  background: #62FFB5;
  /* The Fallback */
  background: rgba(98, 255, 181, 0.8); }

.info.lighten-5 {
  color: #E6F9FC !important; }

.bg-info.bg-lighten-5 {
  background-color: #E6F9FC !important; }

.btn-info.btn-lighten-5 {
  border-color: #22C2DC !important;
  background-color: #E6F9FC !important; }
  .btn-info.btn-lighten-5:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-lighten-5:focus, .btn-info.btn-lighten-5:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-lighten-5 {
  border-color: #E6F9FC !important;
  color: #E6F9FC !important; }
  .btn-outline-info.btn-outline-lighten-5:hover {
    background-color: #E6F9FC !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E6F9FC !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E6F9FC !important; }

.border-info.border-lighten-5 {
  border: 1px solid #E6F9FC !important; }

.border-top-info.border-top-lighten-5 {
  border-top: 1px solid #E6F9FC !important; }

.border-bottom-info.border-bottom-lighten-5 {
  border-bottom: 1px solid #E6F9FC !important; }

.border-left-info.border-left-lighten-5 {
  border-left: 1px solid #E6F9FC !important; }

.border-right-info.border-right-lighten-5 {
  border-right: 1px solid #E6F9FC !important; }

.overlay-info.overlay-lighten-5 {
  background: #E6F9FC;
  /* The Fallback */
  background: rgba(230, 249, 252, 0.8); }

.info.lighten-4 {
  color: #C0F0F7 !important; }

.bg-info.bg-lighten-4 {
  background-color: #C0F0F7 !important; }

.btn-info.btn-lighten-4 {
  border-color: #22C2DC !important;
  background-color: #C0F0F7 !important; }
  .btn-info.btn-lighten-4:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-lighten-4:focus, .btn-info.btn-lighten-4:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-lighten-4 {
  border-color: #C0F0F7 !important;
  color: #C0F0F7 !important; }
  .btn-outline-info.btn-outline-lighten-4:hover {
    background-color: #C0F0F7 !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C0F0F7 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C0F0F7 !important; }

.border-info.border-lighten-4 {
  border: 1px solid #C0F0F7 !important; }

.border-top-info.border-top-lighten-4 {
  border-top: 1px solid #C0F0F7 !important; }

.border-bottom-info.border-bottom-lighten-4 {
  border-bottom: 1px solid #C0F0F7 !important; }

.border-left-info.border-left-lighten-4 {
  border-left: 1px solid #C0F0F7 !important; }

.border-right-info.border-right-lighten-4 {
  border-right: 1px solid #C0F0F7 !important; }

.overlay-info.overlay-lighten-4 {
  background: #C0F0F7;
  /* The Fallback */
  background: rgba(192, 240, 247, 0.8); }

.info.lighten-3 {
  color: #96E7F1 !important; }

.bg-info.bg-lighten-3 {
  background-color: #96E7F1 !important; }

.btn-info.btn-lighten-3 {
  border-color: #22C2DC !important;
  background-color: #96E7F1 !important; }
  .btn-info.btn-lighten-3:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-lighten-3:focus, .btn-info.btn-lighten-3:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-lighten-3 {
  border-color: #96E7F1 !important;
  color: #96E7F1 !important; }
  .btn-outline-info.btn-outline-lighten-3:hover {
    background-color: #96E7F1 !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #96E7F1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #96E7F1 !important; }

.border-info.border-lighten-3 {
  border: 1px solid #96E7F1 !important; }

.border-top-info.border-top-lighten-3 {
  border-top: 1px solid #96E7F1 !important; }

.border-bottom-info.border-bottom-lighten-3 {
  border-bottom: 1px solid #96E7F1 !important; }

.border-left-info.border-left-lighten-3 {
  border-left: 1px solid #96E7F1 !important; }

.border-right-info.border-right-lighten-3 {
  border-right: 1px solid #96E7F1 !important; }

.overlay-info.overlay-lighten-3 {
  background: #96E7F1;
  /* The Fallback */
  background: rgba(150, 231, 241, 0.8); }

.info.lighten-2 {
  color: #6CDDEB !important; }

.bg-info.bg-lighten-2 {
  background-color: #6CDDEB !important; }

.btn-info.btn-lighten-2 {
  border-color: #22C2DC !important;
  background-color: #6CDDEB !important; }
  .btn-info.btn-lighten-2:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-lighten-2:focus, .btn-info.btn-lighten-2:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-lighten-2 {
  border-color: #6CDDEB !important;
  color: #6CDDEB !important; }
  .btn-outline-info.btn-outline-lighten-2:hover {
    background-color: #6CDDEB !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6CDDEB !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6CDDEB !important; }

.border-info.border-lighten-2 {
  border: 1px solid #6CDDEB !important; }

.border-top-info.border-top-lighten-2 {
  border-top: 1px solid #6CDDEB !important; }

.border-bottom-info.border-bottom-lighten-2 {
  border-bottom: 1px solid #6CDDEB !important; }

.border-left-info.border-left-lighten-2 {
  border-left: 1px solid #6CDDEB !important; }

.border-right-info.border-right-lighten-2 {
  border-right: 1px solid #6CDDEB !important; }

.overlay-info.overlay-lighten-2 {
  background: #6CDDEB;
  /* The Fallback */
  background: rgba(108, 221, 235, 0.8); }

.info.lighten-1 {
  color: #4DD5E7 !important; }

.bg-info.bg-lighten-1 {
  background-color: #4DD5E7 !important; }

.btn-info.btn-lighten-1 {
  border-color: #22C2DC !important;
  background-color: #4DD5E7 !important; }
  .btn-info.btn-lighten-1:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-lighten-1:focus, .btn-info.btn-lighten-1:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-lighten-1 {
  border-color: #4DD5E7 !important;
  color: #4DD5E7 !important; }
  .btn-outline-info.btn-outline-lighten-1:hover {
    background-color: #4DD5E7 !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4DD5E7 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4DD5E7 !important; }

.border-info.border-lighten-1 {
  border: 1px solid #4DD5E7 !important; }

.border-top-info.border-top-lighten-1 {
  border-top: 1px solid #4DD5E7 !important; }

.border-bottom-info.border-bottom-lighten-1 {
  border-bottom: 1px solid #4DD5E7 !important; }

.border-left-info.border-left-lighten-1 {
  border-left: 1px solid #4DD5E7 !important; }

.border-right-info.border-right-lighten-1 {
  border-right: 1px solid #4DD5E7 !important; }

.overlay-info.overlay-lighten-1 {
  background: #4DD5E7;
  /* The Fallback */
  background: rgba(77, 213, 231, 0.8); }

.info {
  color: #2DCEE3 !important; }

.bg-info {
  background-color: #2DCEE3 !important; }
  .bg-info .card-header, .bg-info .card-footer {
    background-color: transparent; }

.alert-info {
  border-color: #2DCEE3 !important;
  background-color: #6cddeb !important;
  color: #0b4a53 !important; }
  .alert-info .alert-link {
    color: #062a2f !important; }

.border-info {
  border-color: #2DCEE3; }

.bg-info.badge-glow, .border-info.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #2DCEE3;
  box-shadow: 0px 0px 10px #2DCEE3; }

.overlay-info {
  background: #2DCEE3;
  /* The Fallback */
  background: rgba(45, 206, 227, 0.8); }

/* .card-outline-info{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-info {
  border-color: #22C2DC !important;
  background-color: #2DCEE3 !important;
  color: #FFFFFF; }
  .btn-info:hover {
    border-color: #28C9E0 !important;
    background-color: #4DD5E7 !important;
    color: #FFF !important; }
  .btn-info:focus, .btn-info:active {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important;
    color: #FFF !important; }

.btn-outline-info {
  border-color: #2DCEE3;
  background-color: transparent;
  color: #2DCEE3; }
  .btn-outline-info:hover {
    background-color: #2DCEE3;
    color: #FFF !important; }

input[type="checkbox"].bg-info + .custom-control-label:before, input[type="radio"].bg-info + .custom-control-label:before {
  background-color: #2DCEE3 !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2DCEE3 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2DCEE3 !important; }

.border-info {
  border: 1px solid #2DCEE3 !important; }

.border-top-info {
  border-top: 1px solid #2DCEE3; }

.border-bottom-info {
  border-bottom: 1px solid #2DCEE3; }

.border-left-info {
  border-left: 1px solid #2DCEE3; }

.border-right-info {
  border-right: 1px solid #2DCEE3; }

.info.darken-1 {
  color: #28C9E0 !important; }

.bg-info.bg-darken-1 {
  background-color: #28C9E0 !important; }

.btn-info.btn-darken-1 {
  border-color: #22C2DC !important;
  background-color: #28C9E0 !important; }
  .btn-info.btn-darken-1:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-darken-1:focus, .btn-info.btn-darken-1:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-darken-1 {
  border-color: #28C9E0 !important;
  color: #28C9E0 !important; }
  .btn-outline-info.btn-outline-darken-1:hover {
    background-color: #28C9E0 !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #28C9E0 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #28C9E0 !important; }

.border-info.border-darken-1 {
  border: 1px solid #28C9E0 !important; }

.border-top-info.border-top-darken-1 {
  border-top: 1px solid #28C9E0 !important; }

.border-bottom-info.border-bottom-darken-1 {
  border-bottom: 1px solid #28C9E0 !important; }

.border-left-info.border-left-darken-1 {
  border-left: 1px solid #28C9E0 !important; }

.border-right-info.border-right-darken-1 {
  border-right: 1px solid #28C9E0 !important; }

.overlay-info.overlay-darken-1 {
  background: #28C9E0;
  /* The Fallback */
  background: rgba(40, 201, 224, 0.8); }

.info.darken-2 {
  color: #22C2DC !important; }

.bg-info.bg-darken-2 {
  background-color: #22C2DC !important; }

.btn-info.btn-darken-2 {
  border-color: #22C2DC !important;
  background-color: #22C2DC !important; }
  .btn-info.btn-darken-2:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-darken-2:focus, .btn-info.btn-darken-2:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-darken-2 {
  border-color: #22C2DC !important;
  color: #22C2DC !important; }
  .btn-outline-info.btn-outline-darken-2:hover {
    background-color: #22C2DC !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #22C2DC !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #22C2DC !important; }

.border-info.border-darken-2 {
  border: 1px solid #22C2DC !important; }

.border-top-info.border-top-darken-2 {
  border-top: 1px solid #22C2DC !important; }

.border-bottom-info.border-bottom-darken-2 {
  border-bottom: 1px solid #22C2DC !important; }

.border-left-info.border-left-darken-2 {
  border-left: 1px solid #22C2DC !important; }

.border-right-info.border-right-darken-2 {
  border-right: 1px solid #22C2DC !important; }

.overlay-info.overlay-darken-2 {
  background: #22C2DC;
  /* The Fallback */
  background: rgba(34, 194, 220, 0.8); }

.info.darken-3 {
  color: #1CBCD8 !important; }

.bg-info.bg-darken-3 {
  background-color: #1CBCD8 !important; }

.btn-info.btn-darken-3 {
  border-color: #22C2DC !important;
  background-color: #1CBCD8 !important; }
  .btn-info.btn-darken-3:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-darken-3:focus, .btn-info.btn-darken-3:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-darken-3 {
  border-color: #1CBCD8 !important;
  color: #1CBCD8 !important; }
  .btn-outline-info.btn-outline-darken-3:hover {
    background-color: #1CBCD8 !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1CBCD8 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1CBCD8 !important; }

.border-info.border-darken-3 {
  border: 1px solid #1CBCD8 !important; }

.border-top-info.border-top-darken-3 {
  border-top: 1px solid #1CBCD8 !important; }

.border-bottom-info.border-bottom-darken-3 {
  border-bottom: 1px solid #1CBCD8 !important; }

.border-left-info.border-left-darken-3 {
  border-left: 1px solid #1CBCD8 !important; }

.border-right-info.border-right-darken-3 {
  border-right: 1px solid #1CBCD8 !important; }

.overlay-info.overlay-darken-3 {
  background: #1CBCD8;
  /* The Fallback */
  background: rgba(28, 188, 216, 0.8); }

.info.darken-4 {
  color: #11B0D0 !important; }

.bg-info.bg-darken-4 {
  background-color: #11B0D0 !important; }

.btn-info.btn-darken-4 {
  border-color: #22C2DC !important;
  background-color: #11B0D0 !important; }
  .btn-info.btn-darken-4:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-darken-4:focus, .btn-info.btn-darken-4:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-darken-4 {
  border-color: #11B0D0 !important;
  color: #11B0D0 !important; }
  .btn-outline-info.btn-outline-darken-4:hover {
    background-color: #11B0D0 !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #11B0D0 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #11B0D0 !important; }

.border-info.border-darken-4 {
  border: 1px solid #11B0D0 !important; }

.border-top-info.border-top-darken-4 {
  border-top: 1px solid #11B0D0 !important; }

.border-bottom-info.border-bottom-darken-4 {
  border-bottom: 1px solid #11B0D0 !important; }

.border-left-info.border-left-darken-4 {
  border-left: 1px solid #11B0D0 !important; }

.border-right-info.border-right-darken-4 {
  border-right: 1px solid #11B0D0 !important; }

.overlay-info.overlay-darken-4 {
  background: #11B0D0;
  /* The Fallback */
  background: rgba(17, 176, 208, 0.8); }

.info.accent-1 {
  color: #FEFFFF !important; }

.bg-info.bg-accent-1 {
  background-color: #FEFFFF !important; }

.btn-info.btn-accent-1 {
  border-color: #22C2DC !important;
  background-color: #FEFFFF !important; }
  .btn-info.btn-accent-1:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-accent-1:focus, .btn-info.btn-accent-1:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-accent-1 {
  border-color: #FEFFFF !important;
  color: #FEFFFF !important; }
  .btn-outline-info.btn-outline-accent-1:hover {
    background-color: #FEFFFF !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FEFFFF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FEFFFF !important; }

.border-info.border-accent-1 {
  border: 1px solid #FEFFFF !important; }

.border-top-info.border-top-accent-1 {
  border-top: 1px solid #FEFFFF !important; }

.border-bottom-info.border-bottom-accent-1 {
  border-bottom: 1px solid #FEFFFF !important; }

.border-left-info.border-left-accent-1 {
  border-left: 1px solid #FEFFFF !important; }

.border-right-info.border-right-accent-1 {
  border-right: 1px solid #FEFFFF !important; }

.overlay-info.overlay-accent-1 {
  background: #FEFFFF;
  /* The Fallback */
  background: rgba(254, 255, 255, 0.8); }

.info.accent-2 {
  color: #CBF5FF !important; }

.bg-info.bg-accent-2 {
  background-color: #CBF5FF !important; }

.btn-info.btn-accent-2 {
  border-color: #22C2DC !important;
  background-color: #CBF5FF !important; }
  .btn-info.btn-accent-2:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-accent-2:focus, .btn-info.btn-accent-2:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-accent-2 {
  border-color: #CBF5FF !important;
  color: #CBF5FF !important; }
  .btn-outline-info.btn-outline-accent-2:hover {
    background-color: #CBF5FF !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CBF5FF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CBF5FF !important; }

.border-info.border-accent-2 {
  border: 1px solid #CBF5FF !important; }

.border-top-info.border-top-accent-2 {
  border-top: 1px solid #CBF5FF !important; }

.border-bottom-info.border-bottom-accent-2 {
  border-bottom: 1px solid #CBF5FF !important; }

.border-left-info.border-left-accent-2 {
  border-left: 1px solid #CBF5FF !important; }

.border-right-info.border-right-accent-2 {
  border-right: 1px solid #CBF5FF !important; }

.overlay-info.overlay-accent-2 {
  background: #CBF5FF;
  /* The Fallback */
  background: rgba(203, 245, 255, 0.8); }

.info.accent-3 {
  color: #98ECFF !important; }

.bg-info.bg-accent-3 {
  background-color: #98ECFF !important; }

.btn-info.btn-accent-3 {
  border-color: #22C2DC !important;
  background-color: #98ECFF !important; }
  .btn-info.btn-accent-3:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-accent-3:focus, .btn-info.btn-accent-3:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-accent-3 {
  border-color: #98ECFF !important;
  color: #98ECFF !important; }
  .btn-outline-info.btn-outline-accent-3:hover {
    background-color: #98ECFF !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #98ECFF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #98ECFF !important; }

.border-info.border-accent-3 {
  border: 1px solid #98ECFF !important; }

.border-top-info.border-top-accent-3 {
  border-top: 1px solid #98ECFF !important; }

.border-bottom-info.border-bottom-accent-3 {
  border-bottom: 1px solid #98ECFF !important; }

.border-left-info.border-left-accent-3 {
  border-left: 1px solid #98ECFF !important; }

.border-right-info.border-right-accent-3 {
  border-right: 1px solid #98ECFF !important; }

.overlay-info.overlay-accent-3 {
  background: #98ECFF;
  /* The Fallback */
  background: rgba(152, 236, 255, 0.8); }

.info.accent-4 {
  color: #7FE7FF !important; }

.bg-info.bg-accent-4 {
  background-color: #7FE7FF !important; }

.btn-info.btn-accent-4 {
  border-color: #22C2DC !important;
  background-color: #7FE7FF !important; }
  .btn-info.btn-accent-4:hover {
    border-color: #22C2DC !important;
    background-color: #1CBCD8 !important; }
  .btn-info.btn-accent-4:focus, .btn-info.btn-accent-4:active {
    border-color: #1CBCD8 !important;
    background-color: #11B0D0 !important; }

.btn-outline-info.btn-outline-accent-4 {
  border-color: #7FE7FF !important;
  color: #7FE7FF !important; }
  .btn-outline-info.btn-outline-accent-4:hover {
    background-color: #7FE7FF !important; }

input:focus ~ .bg-info {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7FE7FF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7FE7FF !important; }

.border-info.border-accent-4 {
  border: 1px solid #7FE7FF !important; }

.border-top-info.border-top-accent-4 {
  border-top: 1px solid #7FE7FF !important; }

.border-bottom-info.border-bottom-accent-4 {
  border-bottom: 1px solid #7FE7FF !important; }

.border-left-info.border-left-accent-4 {
  border-left: 1px solid #7FE7FF !important; }

.border-right-info.border-right-accent-4 {
  border-right: 1px solid #7FE7FF !important; }

.overlay-info.overlay-accent-4 {
  background: #7FE7FF;
  /* The Fallback */
  background: rgba(127, 231, 255, 0.8); }

.warning.lighten-5 {
  color: #FFF5EF !important; }

.bg-warning.bg-lighten-5 {
  background-color: #FFF5EF !important; }

.btn-warning.btn-lighten-5 {
  border-color: #FF976A !important;
  background-color: #FFF5EF !important; }
  .btn-warning.btn-lighten-5:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-lighten-5:focus, .btn-warning.btn-lighten-5:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-lighten-5 {
  border-color: #FFF5EF !important;
  color: #FFF5EF !important; }
  .btn-outline-warning.btn-outline-lighten-5:hover {
    background-color: #FFF5EF !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF5EF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF5EF !important; }

.border-warning.border-lighten-5 {
  border: 1px solid #FFF5EF !important; }

.border-top-warning.border-top-lighten-5 {
  border-top: 1px solid #FFF5EF !important; }

.border-bottom-warning.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFF5EF !important; }

.border-left-warning.border-left-lighten-5 {
  border-left: 1px solid #FFF5EF !important; }

.border-right-warning.border-right-lighten-5 {
  border-right: 1px solid #FFF5EF !important; }

.overlay-warning.overlay-lighten-5 {
  background: #FFF5EF;
  /* The Fallback */
  background: rgba(255, 245, 239, 0.8); }

.warning.lighten-4 {
  color: #FFE5D8 !important; }

.bg-warning.bg-lighten-4 {
  background-color: #FFE5D8 !important; }

.btn-warning.btn-lighten-4 {
  border-color: #FF976A !important;
  background-color: #FFE5D8 !important; }
  .btn-warning.btn-lighten-4:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-lighten-4:focus, .btn-warning.btn-lighten-4:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-lighten-4 {
  border-color: #FFE5D8 !important;
  color: #FFE5D8 !important; }
  .btn-outline-warning.btn-outline-lighten-4:hover {
    background-color: #FFE5D8 !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE5D8 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE5D8 !important; }

.border-warning.border-lighten-4 {
  border: 1px solid #FFE5D8 !important; }

.border-top-warning.border-top-lighten-4 {
  border-top: 1px solid #FFE5D8 !important; }

.border-bottom-warning.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFE5D8 !important; }

.border-left-warning.border-left-lighten-4 {
  border-left: 1px solid #FFE5D8 !important; }

.border-right-warning.border-right-lighten-4 {
  border-right: 1px solid #FFE5D8 !important; }

.overlay-warning.overlay-lighten-4 {
  background: #FFE5D8;
  /* The Fallback */
  background: rgba(255, 229, 216, 0.8); }

.warning.lighten-3 {
  color: #FFD4BE !important; }

.bg-warning.bg-lighten-3 {
  background-color: #FFD4BE !important; }

.btn-warning.btn-lighten-3 {
  border-color: #FF976A !important;
  background-color: #FFD4BE !important; }
  .btn-warning.btn-lighten-3:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-lighten-3:focus, .btn-warning.btn-lighten-3:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-lighten-3 {
  border-color: #FFD4BE !important;
  color: #FFD4BE !important; }
  .btn-outline-warning.btn-outline-lighten-3:hover {
    background-color: #FFD4BE !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD4BE !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD4BE !important; }

.border-warning.border-lighten-3 {
  border: 1px solid #FFD4BE !important; }

.border-top-warning.border-top-lighten-3 {
  border-top: 1px solid #FFD4BE !important; }

.border-bottom-warning.border-bottom-lighten-3 {
  border-bottom: 1px solid #FFD4BE !important; }

.border-left-warning.border-left-lighten-3 {
  border-left: 1px solid #FFD4BE !important; }

.border-right-warning.border-right-lighten-3 {
  border-right: 1px solid #FFD4BE !important; }

.overlay-warning.overlay-lighten-3 {
  background: #FFD4BE;
  /* The Fallback */
  background: rgba(255, 212, 190, 0.8); }

.warning.lighten-2 {
  color: #FFC2A4 !important; }

.bg-warning.bg-lighten-2 {
  background-color: #FFC2A4 !important; }

.btn-warning.btn-lighten-2 {
  border-color: #FF976A !important;
  background-color: #FFC2A4 !important; }
  .btn-warning.btn-lighten-2:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-lighten-2:focus, .btn-warning.btn-lighten-2:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-lighten-2 {
  border-color: #FFC2A4 !important;
  color: #FFC2A4 !important; }
  .btn-outline-warning.btn-outline-lighten-2:hover {
    background-color: #FFC2A4 !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFC2A4 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFC2A4 !important; }

.border-warning.border-lighten-2 {
  border: 1px solid #FFC2A4 !important; }

.border-top-warning.border-top-lighten-2 {
  border-top: 1px solid #FFC2A4 !important; }

.border-bottom-warning.border-bottom-lighten-2 {
  border-bottom: 1px solid #FFC2A4 !important; }

.border-left-warning.border-left-lighten-2 {
  border-left: 1px solid #FFC2A4 !important; }

.border-right-warning.border-right-lighten-2 {
  border-right: 1px solid #FFC2A4 !important; }

.overlay-warning.overlay-lighten-2 {
  background: #FFC2A4;
  /* The Fallback */
  background: rgba(255, 194, 164, 0.8); }

.warning.lighten-1 {
  color: #FFB591 !important; }

.bg-warning.bg-lighten-1 {
  background-color: #FFB591 !important; }

.btn-warning.btn-lighten-1 {
  border-color: #FF976A !important;
  background-color: #FFB591 !important; }
  .btn-warning.btn-lighten-1:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-lighten-1:focus, .btn-warning.btn-lighten-1:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-lighten-1 {
  border-color: #FFB591 !important;
  color: #FFB591 !important; }
  .btn-outline-warning.btn-outline-lighten-1:hover {
    background-color: #FFB591 !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFB591 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFB591 !important; }

.border-warning.border-lighten-1 {
  border: 1px solid #FFB591 !important; }

.border-top-warning.border-top-lighten-1 {
  border-top: 1px solid #FFB591 !important; }

.border-bottom-warning.border-bottom-lighten-1 {
  border-bottom: 1px solid #FFB591 !important; }

.border-left-warning.border-left-lighten-1 {
  border-left: 1px solid #FFB591 !important; }

.border-right-warning.border-right-lighten-1 {
  border-right: 1px solid #FFB591 !important; }

.overlay-warning.overlay-lighten-1 {
  background: #FFB591;
  /* The Fallback */
  background: rgba(255, 181, 145, 0.8); }

.warning {
  color: #FFA87D !important; }

.bg-warning {
  background-color: #FFA87D !important; }
  .bg-warning .card-header, .bg-warning .card-footer {
    background-color: transparent; }

.alert-warning {
  border-color: #FFA87D !important;
  background-color: #ffd8c4 !important;
  color: #ca4300 !important; }
  .alert-warning .alert-link {
    color: #a13500 !important; }

.border-warning {
  border-color: #FFA87D; }

.bg-warning.badge-glow, .border-warning.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #FFA87D;
  box-shadow: 0px 0px 10px #FFA87D; }

.overlay-warning {
  background: #FFA87D;
  /* The Fallback */
  background: rgba(255, 168, 125, 0.8); }

/* .card-outline-warning{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-warning {
  border-color: #FF976A !important;
  background-color: #FFA87D !important;
  color: #FFFFFF; }
  .btn-warning:hover {
    border-color: #FFA075 !important;
    background-color: #FFB591 !important;
    color: #FFF !important; }
  .btn-warning:focus, .btn-warning:active {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important;
    color: #FFF !important; }

.btn-outline-warning {
  border-color: #FFA87D;
  background-color: transparent;
  color: #FFA87D; }
  .btn-outline-warning:hover {
    background-color: #FFA87D;
    color: #FFF !important; }

input[type="checkbox"].bg-warning + .custom-control-label:before, input[type="radio"].bg-warning + .custom-control-label:before {
  background-color: #FFA87D !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFA87D !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFA87D !important; }

.border-warning {
  border: 1px solid #FFA87D !important; }

.border-top-warning {
  border-top: 1px solid #FFA87D; }

.border-bottom-warning {
  border-bottom: 1px solid #FFA87D; }

.border-left-warning {
  border-left: 1px solid #FFA87D; }

.border-right-warning {
  border-right: 1px solid #FFA87D; }

.warning.darken-1 {
  color: #FFA075 !important; }

.bg-warning.bg-darken-1 {
  background-color: #FFA075 !important; }

.btn-warning.btn-darken-1 {
  border-color: #FF976A !important;
  background-color: #FFA075 !important; }
  .btn-warning.btn-darken-1:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-darken-1:focus, .btn-warning.btn-darken-1:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-darken-1 {
  border-color: #FFA075 !important;
  color: #FFA075 !important; }
  .btn-outline-warning.btn-outline-darken-1:hover {
    background-color: #FFA075 !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFA075 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFA075 !important; }

.border-warning.border-darken-1 {
  border: 1px solid #FFA075 !important; }

.border-top-warning.border-top-darken-1 {
  border-top: 1px solid #FFA075 !important; }

.border-bottom-warning.border-bottom-darken-1 {
  border-bottom: 1px solid #FFA075 !important; }

.border-left-warning.border-left-darken-1 {
  border-left: 1px solid #FFA075 !important; }

.border-right-warning.border-right-darken-1 {
  border-right: 1px solid #FFA075 !important; }

.overlay-warning.overlay-darken-1 {
  background: #FFA075;
  /* The Fallback */
  background: rgba(255, 160, 117, 0.8); }

.warning.darken-2 {
  color: #FF976A !important; }

.bg-warning.bg-darken-2 {
  background-color: #FF976A !important; }

.btn-warning.btn-darken-2 {
  border-color: #FF976A !important;
  background-color: #FF976A !important; }
  .btn-warning.btn-darken-2:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-darken-2:focus, .btn-warning.btn-darken-2:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-darken-2 {
  border-color: #FF976A !important;
  color: #FF976A !important; }
  .btn-outline-warning.btn-outline-darken-2:hover {
    background-color: #FF976A !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF976A !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF976A !important; }

.border-warning.border-darken-2 {
  border: 1px solid #FF976A !important; }

.border-top-warning.border-top-darken-2 {
  border-top: 1px solid #FF976A !important; }

.border-bottom-warning.border-bottom-darken-2 {
  border-bottom: 1px solid #FF976A !important; }

.border-left-warning.border-left-darken-2 {
  border-left: 1px solid #FF976A !important; }

.border-right-warning.border-right-darken-2 {
  border-right: 1px solid #FF976A !important; }

.overlay-warning.overlay-darken-2 {
  background: #FF976A;
  /* The Fallback */
  background: rgba(255, 151, 106, 0.8); }

.warning.darken-3 {
  color: #FF8D60 !important; }

.bg-warning.bg-darken-3 {
  background-color: #FF8D60 !important; }

.btn-warning.btn-darken-3 {
  border-color: #FF976A !important;
  background-color: #FF8D60 !important; }
  .btn-warning.btn-darken-3:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-darken-3:focus, .btn-warning.btn-darken-3:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-darken-3 {
  border-color: #FF8D60 !important;
  color: #FF8D60 !important; }
  .btn-outline-warning.btn-outline-darken-3:hover {
    background-color: #FF8D60 !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8D60 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8D60 !important; }

.border-warning.border-darken-3 {
  border: 1px solid #FF8D60 !important; }

.border-top-warning.border-top-darken-3 {
  border-top: 1px solid #FF8D60 !important; }

.border-bottom-warning.border-bottom-darken-3 {
  border-bottom: 1px solid #FF8D60 !important; }

.border-left-warning.border-left-darken-3 {
  border-left: 1px solid #FF8D60 !important; }

.border-right-warning.border-right-darken-3 {
  border-right: 1px solid #FF8D60 !important; }

.overlay-warning.overlay-darken-3 {
  background: #FF8D60;
  /* The Fallback */
  background: rgba(255, 141, 96, 0.8); }

.warning.darken-4 {
  color: #FF7D4D !important; }

.bg-warning.bg-darken-4 {
  background-color: #FF7D4D !important; }

.btn-warning.btn-darken-4 {
  border-color: #FF976A !important;
  background-color: #FF7D4D !important; }
  .btn-warning.btn-darken-4:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-darken-4:focus, .btn-warning.btn-darken-4:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-darken-4 {
  border-color: #FF7D4D !important;
  color: #FF7D4D !important; }
  .btn-outline-warning.btn-outline-darken-4:hover {
    background-color: #FF7D4D !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF7D4D !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF7D4D !important; }

.border-warning.border-darken-4 {
  border: 1px solid #FF7D4D !important; }

.border-top-warning.border-top-darken-4 {
  border-top: 1px solid #FF7D4D !important; }

.border-bottom-warning.border-bottom-darken-4 {
  border-bottom: 1px solid #FF7D4D !important; }

.border-left-warning.border-left-darken-4 {
  border-left: 1px solid #FF7D4D !important; }

.border-right-warning.border-right-darken-4 {
  border-right: 1px solid #FF7D4D !important; }

.overlay-warning.overlay-darken-4 {
  background: #FF7D4D;
  /* The Fallback */
  background: rgba(255, 125, 77, 0.8); }

.warning.accent-1 {
  color: #FFF5EF !important; }

.bg-warning.bg-accent-1 {
  background-color: #FFF5EF !important; }

.btn-warning.btn-accent-1 {
  border-color: #FF976A !important;
  background-color: #FFF5EF !important; }
  .btn-warning.btn-accent-1:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-accent-1:focus, .btn-warning.btn-accent-1:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-accent-1 {
  border-color: #FFF5EF !important;
  color: #FFF5EF !important; }
  .btn-outline-warning.btn-outline-accent-1:hover {
    background-color: #FFF5EF !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF5EF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF5EF !important; }

.border-warning.border-accent-1 {
  border: 1px solid #FFF5EF !important; }

.border-top-warning.border-top-accent-1 {
  border-top: 1px solid #FFF5EF !important; }

.border-bottom-warning.border-bottom-accent-1 {
  border-bottom: 1px solid #FFF5EF !important; }

.border-left-warning.border-left-accent-1 {
  border-left: 1px solid #FFF5EF !important; }

.border-right-warning.border-right-accent-1 {
  border-right: 1px solid #FFF5EF !important; }

.overlay-warning.overlay-accent-1 {
  background: #FFF5EF;
  /* The Fallback */
  background: rgba(255, 245, 239, 0.8); }

.warning.accent-2 {
  color: #FFE5D8 !important; }

.bg-warning.bg-accent-2 {
  background-color: #FFE5D8 !important; }

.btn-warning.btn-accent-2 {
  border-color: #FF976A !important;
  background-color: #FFE5D8 !important; }
  .btn-warning.btn-accent-2:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-accent-2:focus, .btn-warning.btn-accent-2:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-accent-2 {
  border-color: #FFE5D8 !important;
  color: #FFE5D8 !important; }
  .btn-outline-warning.btn-outline-accent-2:hover {
    background-color: #FFE5D8 !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE5D8 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE5D8 !important; }

.border-warning.border-accent-2 {
  border: 1px solid #FFE5D8 !important; }

.border-top-warning.border-top-accent-2 {
  border-top: 1px solid #FFE5D8 !important; }

.border-bottom-warning.border-bottom-accent-2 {
  border-bottom: 1px solid #FFE5D8 !important; }

.border-left-warning.border-left-accent-2 {
  border-left: 1px solid #FFE5D8 !important; }

.border-right-warning.border-right-accent-2 {
  border-right: 1px solid #FFE5D8 !important; }

.overlay-warning.overlay-accent-2 {
  background: #FFE5D8;
  /* The Fallback */
  background: rgba(255, 229, 216, 0.8); }

.warning.accent-3 {
  color: #FFF6F3 !important; }

.bg-warning.bg-accent-3 {
  background-color: #FFF6F3 !important; }

.btn-warning.btn-accent-3 {
  border-color: #FF976A !important;
  background-color: #FFF6F3 !important; }
  .btn-warning.btn-accent-3:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-accent-3:focus, .btn-warning.btn-accent-3:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-accent-3 {
  border-color: #FFF6F3 !important;
  color: #FFF6F3 !important; }
  .btn-outline-warning.btn-outline-accent-3:hover {
    background-color: #FFF6F3 !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF6F3 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF6F3 !important; }

.border-warning.border-accent-3 {
  border: 1px solid #FFF6F3 !important; }

.border-top-warning.border-top-accent-3 {
  border-top: 1px solid #FFF6F3 !important; }

.border-bottom-warning.border-bottom-accent-3 {
  border-bottom: 1px solid #FFF6F3 !important; }

.border-left-warning.border-left-accent-3 {
  border-left: 1px solid #FFF6F3 !important; }

.border-right-warning.border-right-accent-3 {
  border-right: 1px solid #FFF6F3 !important; }

.overlay-warning.overlay-accent-3 {
  background: #FFF6F3;
  /* The Fallback */
  background: rgba(255, 246, 243, 0.8); }

.warning.accent-4 {
  color: #FFE3DA !important; }

.bg-warning.bg-accent-4 {
  background-color: #FFE3DA !important; }

.btn-warning.btn-accent-4 {
  border-color: #FF976A !important;
  background-color: #FFE3DA !important; }
  .btn-warning.btn-accent-4:hover {
    border-color: #FF976A !important;
    background-color: #FF8D60 !important; }
  .btn-warning.btn-accent-4:focus, .btn-warning.btn-accent-4:active {
    border-color: #FF8D60 !important;
    background-color: #FF7D4D !important; }

.btn-outline-warning.btn-outline-accent-4 {
  border-color: #FFE3DA !important;
  color: #FFE3DA !important; }
  .btn-outline-warning.btn-outline-accent-4:hover {
    background-color: #FFE3DA !important; }

input:focus ~ .bg-warning {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE3DA !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE3DA !important; }

.border-warning.border-accent-4 {
  border: 1px solid #FFE3DA !important; }

.border-top-warning.border-top-accent-4 {
  border-top: 1px solid #FFE3DA !important; }

.border-bottom-warning.border-bottom-accent-4 {
  border-bottom: 1px solid #FFE3DA !important; }

.border-left-warning.border-left-accent-4 {
  border-left: 1px solid #FFE3DA !important; }

.border-right-warning.border-right-accent-4 {
  border-right: 1px solid #FFE3DA !important; }

.overlay-warning.overlay-accent-4 {
  background: #FFE3DA;
  /* The Fallback */
  background: rgba(255, 227, 218, 0.8); }

.danger.lighten-5 {
  color: #FFEEF1 !important; }

.bg-danger.bg-lighten-5 {
  background-color: #FFEEF1 !important; }

.btn-danger.btn-lighten-5 {
  border-color: #FF6275 !important;
  background-color: #FFEEF1 !important; }
  .btn-danger.btn-lighten-5:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-lighten-5:focus, .btn-danger.btn-lighten-5:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-lighten-5 {
  border-color: #FFEEF1 !important;
  color: #FFEEF1 !important; }
  .btn-outline-danger.btn-outline-lighten-5:hover {
    background-color: #FFEEF1 !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEEF1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEEF1 !important; }

.border-danger.border-lighten-5 {
  border: 1px solid #FFEEF1 !important; }

.border-top-danger.border-top-lighten-5 {
  border-top: 1px solid #FFEEF1 !important; }

.border-bottom-danger.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFEEF1 !important; }

.border-left-danger.border-left-lighten-5 {
  border-left: 1px solid #FFEEF1 !important; }

.border-right-danger.border-right-lighten-5 {
  border-right: 1px solid #FFEEF1 !important; }

.overlay-danger.overlay-lighten-5 {
  background: #FFEEF1;
  /* The Fallback */
  background: rgba(255, 238, 241, 0.8); }

.danger.lighten-4 {
  color: #FFD6DB !important; }

.bg-danger.bg-lighten-4 {
  background-color: #FFD6DB !important; }

.btn-danger.btn-lighten-4 {
  border-color: #FF6275 !important;
  background-color: #FFD6DB !important; }
  .btn-danger.btn-lighten-4:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-lighten-4:focus, .btn-danger.btn-lighten-4:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-lighten-4 {
  border-color: #FFD6DB !important;
  color: #FFD6DB !important; }
  .btn-outline-danger.btn-outline-lighten-4:hover {
    background-color: #FFD6DB !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD6DB !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD6DB !important; }

.border-danger.border-lighten-4 {
  border: 1px solid #FFD6DB !important; }

.border-top-danger.border-top-lighten-4 {
  border-top: 1px solid #FFD6DB !important; }

.border-bottom-danger.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFD6DB !important; }

.border-left-danger.border-left-lighten-4 {
  border-left: 1px solid #FFD6DB !important; }

.border-right-danger.border-right-lighten-4 {
  border-right: 1px solid #FFD6DB !important; }

.overlay-danger.overlay-lighten-4 {
  background: #FFD6DB;
  /* The Fallback */
  background: rgba(255, 214, 219, 0.8); }

.danger.lighten-3 {
  color: #FFBAC4 !important; }

.bg-danger.bg-lighten-3 {
  background-color: #FFBAC4 !important; }

.btn-danger.btn-lighten-3 {
  border-color: #FF6275 !important;
  background-color: #FFBAC4 !important; }
  .btn-danger.btn-lighten-3:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-lighten-3:focus, .btn-danger.btn-lighten-3:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-lighten-3 {
  border-color: #FFBAC4 !important;
  color: #FFBAC4 !important; }
  .btn-outline-danger.btn-outline-lighten-3:hover {
    background-color: #FFBAC4 !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFBAC4 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFBAC4 !important; }

.border-danger.border-lighten-3 {
  border: 1px solid #FFBAC4 !important; }

.border-top-danger.border-top-lighten-3 {
  border-top: 1px solid #FFBAC4 !important; }

.border-bottom-danger.border-bottom-lighten-3 {
  border-bottom: 1px solid #FFBAC4 !important; }

.border-left-danger.border-left-lighten-3 {
  border-left: 1px solid #FFBAC4 !important; }

.border-right-danger.border-right-lighten-3 {
  border-right: 1px solid #FFBAC4 !important; }

.overlay-danger.overlay-lighten-3 {
  background: #FFBAC4;
  /* The Fallback */
  background: rgba(255, 186, 196, 0.8); }

.danger.lighten-2 {
  color: #FF9EAC !important; }

.bg-danger.bg-lighten-2 {
  background-color: #FF9EAC !important; }

.btn-danger.btn-lighten-2 {
  border-color: #FF6275 !important;
  background-color: #FF9EAC !important; }
  .btn-danger.btn-lighten-2:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-lighten-2:focus, .btn-danger.btn-lighten-2:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-lighten-2 {
  border-color: #FF9EAC !important;
  color: #FF9EAC !important; }
  .btn-outline-danger.btn-outline-lighten-2:hover {
    background-color: #FF9EAC !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF9EAC !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF9EAC !important; }

.border-danger.border-lighten-2 {
  border: 1px solid #FF9EAC !important; }

.border-top-danger.border-top-lighten-2 {
  border-top: 1px solid #FF9EAC !important; }

.border-bottom-danger.border-bottom-lighten-2 {
  border-bottom: 1px solid #FF9EAC !important; }

.border-left-danger.border-left-lighten-2 {
  border-left: 1px solid #FF9EAC !important; }

.border-right-danger.border-right-lighten-2 {
  border-right: 1px solid #FF9EAC !important; }

.overlay-danger.overlay-lighten-2 {
  background: #FF9EAC;
  /* The Fallback */
  background: rgba(255, 158, 172, 0.8); }

.danger.lighten-1 {
  color: #FF8A9A !important; }

.bg-danger.bg-lighten-1 {
  background-color: #FF8A9A !important; }

.btn-danger.btn-lighten-1 {
  border-color: #FF6275 !important;
  background-color: #FF8A9A !important; }
  .btn-danger.btn-lighten-1:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-lighten-1:focus, .btn-danger.btn-lighten-1:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-lighten-1 {
  border-color: #FF8A9A !important;
  color: #FF8A9A !important; }
  .btn-outline-danger.btn-outline-lighten-1:hover {
    background-color: #FF8A9A !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8A9A !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8A9A !important; }

.border-danger.border-lighten-1 {
  border: 1px solid #FF8A9A !important; }

.border-top-danger.border-top-lighten-1 {
  border-top: 1px solid #FF8A9A !important; }

.border-bottom-danger.border-bottom-lighten-1 {
  border-bottom: 1px solid #FF8A9A !important; }

.border-left-danger.border-left-lighten-1 {
  border-left: 1px solid #FF8A9A !important; }

.border-right-danger.border-right-lighten-1 {
  border-right: 1px solid #FF8A9A !important; }

.overlay-danger.overlay-lighten-1 {
  background: #FF8A9A;
  /* The Fallback */
  background: rgba(255, 138, 154, 0.8); }

.danger {
  color: #FF7588 !important; }

.bg-danger {
  background-color: #FF7588 !important; }
  .bg-danger .card-header, .bg-danger .card-footer {
    background-color: transparent; }

.alert-danger {
  border-color: #FF7588 !important;
  background-color: #ffbcc6 !important;
  color: #c2001b !important; }
  .alert-danger .alert-link {
    color: #990015 !important; }

.border-danger {
  border-color: #FF7588; }

.bg-danger.badge-glow, .border-danger.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #FF7588;
  box-shadow: 0px 0px 10px #FF7588; }

.overlay-danger {
  background: #FF7588;
  /* The Fallback */
  background: rgba(255, 117, 136, 0.8); }

/* .card-outline-danger{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-danger {
  border-color: #FF6275 !important;
  background-color: #FF7588 !important;
  color: #FFFFFF; }
  .btn-danger:hover {
    border-color: #FF6D80 !important;
    background-color: #FF8A9A !important;
    color: #FFF !important; }
  .btn-danger:focus, .btn-danger:active {
    border-color: #FF6275 !important;
    background-color: #FF586B !important;
    color: #FFF !important; }

.btn-outline-danger {
  border-color: #FF7588;
  background-color: transparent;
  color: #FF7588; }
  .btn-outline-danger:hover {
    background-color: #FF7588;
    color: #FFF !important; }

input[type="checkbox"].bg-danger + .custom-control-label:before, input[type="radio"].bg-danger + .custom-control-label:before {
  background-color: #FF7588 !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF7588 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF7588 !important; }

.border-danger {
  border: 1px solid #FF7588 !important; }

.border-top-danger {
  border-top: 1px solid #FF7588; }

.border-bottom-danger {
  border-bottom: 1px solid #FF7588; }

.border-left-danger {
  border-left: 1px solid #FF7588; }

.border-right-danger {
  border-right: 1px solid #FF7588; }

.danger.darken-1 {
  color: #FF6D80 !important; }

.bg-danger.bg-darken-1 {
  background-color: #FF6D80 !important; }

.btn-danger.btn-darken-1 {
  border-color: #FF6275 !important;
  background-color: #FF6D80 !important; }
  .btn-danger.btn-darken-1:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-darken-1:focus, .btn-danger.btn-darken-1:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-darken-1 {
  border-color: #FF6D80 !important;
  color: #FF6D80 !important; }
  .btn-outline-danger.btn-outline-darken-1:hover {
    background-color: #FF6D80 !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF6D80 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF6D80 !important; }

.border-danger.border-darken-1 {
  border: 1px solid #FF6D80 !important; }

.border-top-danger.border-top-darken-1 {
  border-top: 1px solid #FF6D80 !important; }

.border-bottom-danger.border-bottom-darken-1 {
  border-bottom: 1px solid #FF6D80 !important; }

.border-left-danger.border-left-darken-1 {
  border-left: 1px solid #FF6D80 !important; }

.border-right-danger.border-right-darken-1 {
  border-right: 1px solid #FF6D80 !important; }

.overlay-danger.overlay-darken-1 {
  background: #FF6D80;
  /* The Fallback */
  background: rgba(255, 109, 128, 0.8); }

.danger.darken-2 {
  color: #FF6275 !important; }

.bg-danger.bg-darken-2 {
  background-color: #FF6275 !important; }

.btn-danger.btn-darken-2 {
  border-color: #FF6275 !important;
  background-color: #FF6275 !important; }
  .btn-danger.btn-darken-2:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-darken-2:focus, .btn-danger.btn-darken-2:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-darken-2 {
  border-color: #FF6275 !important;
  color: #FF6275 !important; }
  .btn-outline-danger.btn-outline-darken-2:hover {
    background-color: #FF6275 !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF6275 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF6275 !important; }

.border-danger.border-darken-2 {
  border: 1px solid #FF6275 !important; }

.border-top-danger.border-top-darken-2 {
  border-top: 1px solid #FF6275 !important; }

.border-bottom-danger.border-bottom-darken-2 {
  border-bottom: 1px solid #FF6275 !important; }

.border-left-danger.border-left-darken-2 {
  border-left: 1px solid #FF6275 !important; }

.border-right-danger.border-right-darken-2 {
  border-right: 1px solid #FF6275 !important; }

.overlay-danger.overlay-darken-2 {
  background: #FF6275;
  /* The Fallback */
  background: rgba(255, 98, 117, 0.8); }

.danger.darken-3 {
  color: #FF586B !important; }

.bg-danger.bg-darken-3 {
  background-color: #FF586B !important; }

.btn-danger.btn-darken-3 {
  border-color: #FF6275 !important;
  background-color: #FF586B !important; }
  .btn-danger.btn-darken-3:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-darken-3:focus, .btn-danger.btn-darken-3:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-darken-3 {
  border-color: #FF586B !important;
  color: #FF586B !important; }
  .btn-outline-danger.btn-outline-darken-3:hover {
    background-color: #FF586B !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF586B !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF586B !important; }

.border-danger.border-darken-3 {
  border: 1px solid #FF586B !important; }

.border-top-danger.border-top-darken-3 {
  border-top: 1px solid #FF586B !important; }

.border-bottom-danger.border-bottom-darken-3 {
  border-bottom: 1px solid #FF586B !important; }

.border-left-danger.border-left-darken-3 {
  border-left: 1px solid #FF586B !important; }

.border-right-danger.border-right-darken-3 {
  border-right: 1px solid #FF586B !important; }

.overlay-danger.overlay-darken-3 {
  background: #FF586B;
  /* The Fallback */
  background: rgba(255, 88, 107, 0.8); }

.danger.darken-4 {
  color: #FF4558 !important; }

.bg-danger.bg-darken-4 {
  background-color: #FF4558 !important; }

.btn-danger.btn-darken-4 {
  border-color: #FF6275 !important;
  background-color: #FF4558 !important; }
  .btn-danger.btn-darken-4:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-darken-4:focus, .btn-danger.btn-darken-4:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-darken-4 {
  border-color: #FF4558 !important;
  color: #FF4558 !important; }
  .btn-outline-danger.btn-outline-darken-4:hover {
    background-color: #FF4558 !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF4558 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF4558 !important; }

.border-danger.border-darken-4 {
  border: 1px solid #FF4558 !important; }

.border-top-danger.border-top-darken-4 {
  border-top: 1px solid #FF4558 !important; }

.border-bottom-danger.border-bottom-darken-4 {
  border-bottom: 1px solid #FF4558 !important; }

.border-left-danger.border-left-darken-4 {
  border-left: 1px solid #FF4558 !important; }

.border-right-danger.border-right-darken-4 {
  border-right: 1px solid #FF4558 !important; }

.overlay-danger.overlay-darken-4 {
  background: #FF4558;
  /* The Fallback */
  background: rgba(255, 69, 88, 0.8); }

.danger.accent-1 {
  color: #FFEEF1 !important; }

.bg-danger.bg-accent-1 {
  background-color: #FFEEF1 !important; }

.btn-danger.btn-accent-1 {
  border-color: #FF6275 !important;
  background-color: #FFEEF1 !important; }
  .btn-danger.btn-accent-1:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-accent-1:focus, .btn-danger.btn-accent-1:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-accent-1 {
  border-color: #FFEEF1 !important;
  color: #FFEEF1 !important; }
  .btn-outline-danger.btn-outline-accent-1:hover {
    background-color: #FFEEF1 !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEEF1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEEF1 !important; }

.border-danger.border-accent-1 {
  border: 1px solid #FFEEF1 !important; }

.border-top-danger.border-top-accent-1 {
  border-top: 1px solid #FFEEF1 !important; }

.border-bottom-danger.border-bottom-accent-1 {
  border-bottom: 1px solid #FFEEF1 !important; }

.border-left-danger.border-left-accent-1 {
  border-left: 1px solid #FFEEF1 !important; }

.border-right-danger.border-right-accent-1 {
  border-right: 1px solid #FFEEF1 !important; }

.overlay-danger.overlay-accent-1 {
  background: #FFEEF1;
  /* The Fallback */
  background: rgba(255, 238, 241, 0.8); }

.danger.accent-2 {
  color: #FFD6DB !important; }

.bg-danger.bg-accent-2 {
  background-color: #FFD6DB !important; }

.btn-danger.btn-accent-2 {
  border-color: #FF6275 !important;
  background-color: #FFD6DB !important; }
  .btn-danger.btn-accent-2:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-accent-2:focus, .btn-danger.btn-accent-2:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-accent-2 {
  border-color: #FFD6DB !important;
  color: #FFD6DB !important; }
  .btn-outline-danger.btn-outline-accent-2:hover {
    background-color: #FFD6DB !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD6DB !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD6DB !important; }

.border-danger.border-accent-2 {
  border: 1px solid #FFD6DB !important; }

.border-top-danger.border-top-accent-2 {
  border-top: 1px solid #FFD6DB !important; }

.border-bottom-danger.border-bottom-accent-2 {
  border-bottom: 1px solid #FFD6DB !important; }

.border-left-danger.border-left-accent-2 {
  border-left: 1px solid #FFD6DB !important; }

.border-right-danger.border-right-accent-2 {
  border-right: 1px solid #FFD6DB !important; }

.overlay-danger.overlay-accent-2 {
  background: #FFD6DB;
  /* The Fallback */
  background: rgba(255, 214, 219, 0.8); }

.danger.accent-3 {
  color: #FFECEE !important; }

.bg-danger.bg-accent-3 {
  background-color: #FFECEE !important; }

.btn-danger.btn-accent-3 {
  border-color: #FF6275 !important;
  background-color: #FFECEE !important; }
  .btn-danger.btn-accent-3:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-accent-3:focus, .btn-danger.btn-accent-3:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-accent-3 {
  border-color: #FFECEE !important;
  color: #FFECEE !important; }
  .btn-outline-danger.btn-outline-accent-3:hover {
    background-color: #FFECEE !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFECEE !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFECEE !important; }

.border-danger.border-accent-3 {
  border: 1px solid #FFECEE !important; }

.border-top-danger.border-top-accent-3 {
  border-top: 1px solid #FFECEE !important; }

.border-bottom-danger.border-bottom-accent-3 {
  border-bottom: 1px solid #FFECEE !important; }

.border-left-danger.border-left-accent-3 {
  border-left: 1px solid #FFECEE !important; }

.border-right-danger.border-right-accent-3 {
  border-right: 1px solid #FFECEE !important; }

.overlay-danger.overlay-accent-3 {
  background: #FFECEE;
  /* The Fallback */
  background: rgba(255, 236, 238, 0.8); }

.danger.accent-4 {
  color: #FFD3D7 !important; }

.bg-danger.bg-accent-4 {
  background-color: #FFD3D7 !important; }

.btn-danger.btn-accent-4 {
  border-color: #FF6275 !important;
  background-color: #FFD3D7 !important; }
  .btn-danger.btn-accent-4:hover {
    border-color: #FF6275 !important;
    background-color: #FF586B !important; }
  .btn-danger.btn-accent-4:focus, .btn-danger.btn-accent-4:active {
    border-color: #FF586B !important;
    background-color: #FF4558 !important; }

.btn-outline-danger.btn-outline-accent-4 {
  border-color: #FFD3D7 !important;
  color: #FFD3D7 !important; }
  .btn-outline-danger.btn-outline-accent-4:hover {
    background-color: #FFD3D7 !important; }

input:focus ~ .bg-danger {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD3D7 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD3D7 !important; }

.border-danger.border-accent-4 {
  border: 1px solid #FFD3D7 !important; }

.border-top-danger.border-top-accent-4 {
  border-top: 1px solid #FFD3D7 !important; }

.border-bottom-danger.border-bottom-accent-4 {
  border-bottom: 1px solid #FFD3D7 !important; }

.border-left-danger.border-left-accent-4 {
  border-left: 1px solid #FFD3D7 !important; }

.border-right-danger.border-right-accent-4 {
  border-right: 1px solid #FFD3D7 !important; }

.overlay-danger.overlay-accent-4 {
  background: #FFD3D7;
  /* The Fallback */
  background: rgba(255, 211, 215, 0.8); }

.red.lighten-5 {
  color: #FFEBEE !important; }

.bg-red.bg-lighten-5 {
  background-color: #FFEBEE !important; }

.btn-red.btn-lighten-5 {
  border-color: #D32F2F !important;
  background-color: #FFEBEE !important; }
  .btn-red.btn-lighten-5:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-lighten-5:focus, .btn-red.btn-lighten-5:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-lighten-5 {
  border-color: #FFEBEE !important;
  color: #FFEBEE !important; }
  .btn-outline-red.btn-outline-lighten-5:hover {
    background-color: #FFEBEE !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEBEE !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEBEE !important; }

.border-red.border-lighten-5 {
  border: 1px solid #FFEBEE !important; }

.border-top-red.border-top-lighten-5 {
  border-top: 1px solid #FFEBEE !important; }

.border-bottom-red.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFEBEE !important; }

.border-left-red.border-left-lighten-5 {
  border-left: 1px solid #FFEBEE !important; }

.border-right-red.border-right-lighten-5 {
  border-right: 1px solid #FFEBEE !important; }

.overlay-red.overlay-lighten-5 {
  background: #FFEBEE;
  /* The Fallback */
  background: rgba(255, 235, 238, 0.8); }

.red.lighten-4 {
  color: #FFCDD2 !important; }

.bg-red.bg-lighten-4 {
  background-color: #FFCDD2 !important; }

.btn-red.btn-lighten-4 {
  border-color: #D32F2F !important;
  background-color: #FFCDD2 !important; }
  .btn-red.btn-lighten-4:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-lighten-4:focus, .btn-red.btn-lighten-4:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-lighten-4 {
  border-color: #FFCDD2 !important;
  color: #FFCDD2 !important; }
  .btn-outline-red.btn-outline-lighten-4:hover {
    background-color: #FFCDD2 !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCDD2 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCDD2 !important; }

.border-red.border-lighten-4 {
  border: 1px solid #FFCDD2 !important; }

.border-top-red.border-top-lighten-4 {
  border-top: 1px solid #FFCDD2 !important; }

.border-bottom-red.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFCDD2 !important; }

.border-left-red.border-left-lighten-4 {
  border-left: 1px solid #FFCDD2 !important; }

.border-right-red.border-right-lighten-4 {
  border-right: 1px solid #FFCDD2 !important; }

.overlay-red.overlay-lighten-4 {
  background: #FFCDD2;
  /* The Fallback */
  background: rgba(255, 205, 210, 0.8); }

.red.lighten-3 {
  color: #EF9A9A !important; }

.bg-red.bg-lighten-3 {
  background-color: #EF9A9A !important; }

.btn-red.btn-lighten-3 {
  border-color: #D32F2F !important;
  background-color: #EF9A9A !important; }
  .btn-red.btn-lighten-3:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-lighten-3:focus, .btn-red.btn-lighten-3:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-lighten-3 {
  border-color: #EF9A9A !important;
  color: #EF9A9A !important; }
  .btn-outline-red.btn-outline-lighten-3:hover {
    background-color: #EF9A9A !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EF9A9A !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EF9A9A !important; }

.border-red.border-lighten-3 {
  border: 1px solid #EF9A9A !important; }

.border-top-red.border-top-lighten-3 {
  border-top: 1px solid #EF9A9A !important; }

.border-bottom-red.border-bottom-lighten-3 {
  border-bottom: 1px solid #EF9A9A !important; }

.border-left-red.border-left-lighten-3 {
  border-left: 1px solid #EF9A9A !important; }

.border-right-red.border-right-lighten-3 {
  border-right: 1px solid #EF9A9A !important; }

.overlay-red.overlay-lighten-3 {
  background: #EF9A9A;
  /* The Fallback */
  background: rgba(239, 154, 154, 0.8); }

.red.lighten-2 {
  color: #E57373 !important; }

.bg-red.bg-lighten-2 {
  background-color: #E57373 !important; }

.btn-red.btn-lighten-2 {
  border-color: #D32F2F !important;
  background-color: #E57373 !important; }
  .btn-red.btn-lighten-2:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-lighten-2:focus, .btn-red.btn-lighten-2:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-lighten-2 {
  border-color: #E57373 !important;
  color: #E57373 !important; }
  .btn-outline-red.btn-outline-lighten-2:hover {
    background-color: #E57373 !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E57373 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E57373 !important; }

.border-red.border-lighten-2 {
  border: 1px solid #E57373 !important; }

.border-top-red.border-top-lighten-2 {
  border-top: 1px solid #E57373 !important; }

.border-bottom-red.border-bottom-lighten-2 {
  border-bottom: 1px solid #E57373 !important; }

.border-left-red.border-left-lighten-2 {
  border-left: 1px solid #E57373 !important; }

.border-right-red.border-right-lighten-2 {
  border-right: 1px solid #E57373 !important; }

.overlay-red.overlay-lighten-2 {
  background: #E57373;
  /* The Fallback */
  background: rgba(229, 115, 115, 0.8); }

.red.lighten-1 {
  color: #EF5350 !important; }

.bg-red.bg-lighten-1 {
  background-color: #EF5350 !important; }

.btn-red.btn-lighten-1 {
  border-color: #D32F2F !important;
  background-color: #EF5350 !important; }
  .btn-red.btn-lighten-1:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-lighten-1:focus, .btn-red.btn-lighten-1:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-lighten-1 {
  border-color: #EF5350 !important;
  color: #EF5350 !important; }
  .btn-outline-red.btn-outline-lighten-1:hover {
    background-color: #EF5350 !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EF5350 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EF5350 !important; }

.border-red.border-lighten-1 {
  border: 1px solid #EF5350 !important; }

.border-top-red.border-top-lighten-1 {
  border-top: 1px solid #EF5350 !important; }

.border-bottom-red.border-bottom-lighten-1 {
  border-bottom: 1px solid #EF5350 !important; }

.border-left-red.border-left-lighten-1 {
  border-left: 1px solid #EF5350 !important; }

.border-right-red.border-right-lighten-1 {
  border-right: 1px solid #EF5350 !important; }

.overlay-red.overlay-lighten-1 {
  background: #EF5350;
  /* The Fallback */
  background: rgba(239, 83, 80, 0.8); }

.red {
  color: #F44336 !important; }

.bg-red {
  background-color: #F44336 !important; }
  .bg-red .card-header, .bg-red .card-footer {
    background-color: transparent; }

.alert-red {
  border-color: #F44336 !important;
  background-color: #f8827a !important;
  color: #710e06 !important; }
  .alert-red .alert-link {
    color: #4b0904 !important; }

.border-red {
  border-color: #F44336; }

.bg-red.badge-glow, .border-red.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #F44336;
  box-shadow: 0px 0px 10px #F44336; }

.overlay-red {
  background: #F44336;
  /* The Fallback */
  background: rgba(244, 67, 54, 0.8); }

/* .card-outline-red{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-red {
  border-color: #D32F2F !important;
  background-color: #F44336 !important;
  color: #FFFFFF; }
  .btn-red:hover {
    border-color: #E53935 !important;
    background-color: #EF5350 !important;
    color: #FFF !important; }
  .btn-red:focus, .btn-red:active {
    border-color: #D32F2F !important;
    background-color: #C62828 !important;
    color: #FFF !important; }

.btn-outline-red {
  border-color: #F44336;
  background-color: transparent;
  color: #F44336; }
  .btn-outline-red:hover {
    background-color: #F44336;
    color: #FFF !important; }

input[type="checkbox"].bg-red + .custom-control-label:before, input[type="radio"].bg-red + .custom-control-label:before {
  background-color: #F44336 !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F44336 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F44336 !important; }

.border-red {
  border: 1px solid #F44336 !important; }

.border-top-red {
  border-top: 1px solid #F44336; }

.border-bottom-red {
  border-bottom: 1px solid #F44336; }

.border-left-red {
  border-left: 1px solid #F44336; }

.border-right-red {
  border-right: 1px solid #F44336; }

.red.darken-1 {
  color: #E53935 !important; }

.bg-red.bg-darken-1 {
  background-color: #E53935 !important; }

.btn-red.btn-darken-1 {
  border-color: #D32F2F !important;
  background-color: #E53935 !important; }
  .btn-red.btn-darken-1:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-darken-1:focus, .btn-red.btn-darken-1:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-darken-1 {
  border-color: #E53935 !important;
  color: #E53935 !important; }
  .btn-outline-red.btn-outline-darken-1:hover {
    background-color: #E53935 !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E53935 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E53935 !important; }

.border-red.border-darken-1 {
  border: 1px solid #E53935 !important; }

.border-top-red.border-top-darken-1 {
  border-top: 1px solid #E53935 !important; }

.border-bottom-red.border-bottom-darken-1 {
  border-bottom: 1px solid #E53935 !important; }

.border-left-red.border-left-darken-1 {
  border-left: 1px solid #E53935 !important; }

.border-right-red.border-right-darken-1 {
  border-right: 1px solid #E53935 !important; }

.overlay-red.overlay-darken-1 {
  background: #E53935;
  /* The Fallback */
  background: rgba(229, 57, 53, 0.8); }

.red.darken-2 {
  color: #D32F2F !important; }

.bg-red.bg-darken-2 {
  background-color: #D32F2F !important; }

.btn-red.btn-darken-2 {
  border-color: #D32F2F !important;
  background-color: #D32F2F !important; }
  .btn-red.btn-darken-2:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-darken-2:focus, .btn-red.btn-darken-2:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-darken-2 {
  border-color: #D32F2F !important;
  color: #D32F2F !important; }
  .btn-outline-red.btn-outline-darken-2:hover {
    background-color: #D32F2F !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D32F2F !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D32F2F !important; }

.border-red.border-darken-2 {
  border: 1px solid #D32F2F !important; }

.border-top-red.border-top-darken-2 {
  border-top: 1px solid #D32F2F !important; }

.border-bottom-red.border-bottom-darken-2 {
  border-bottom: 1px solid #D32F2F !important; }

.border-left-red.border-left-darken-2 {
  border-left: 1px solid #D32F2F !important; }

.border-right-red.border-right-darken-2 {
  border-right: 1px solid #D32F2F !important; }

.overlay-red.overlay-darken-2 {
  background: #D32F2F;
  /* The Fallback */
  background: rgba(211, 47, 47, 0.8); }

.red.darken-3 {
  color: #C62828 !important; }

.bg-red.bg-darken-3 {
  background-color: #C62828 !important; }

.btn-red.btn-darken-3 {
  border-color: #D32F2F !important;
  background-color: #C62828 !important; }
  .btn-red.btn-darken-3:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-darken-3:focus, .btn-red.btn-darken-3:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-darken-3 {
  border-color: #C62828 !important;
  color: #C62828 !important; }
  .btn-outline-red.btn-outline-darken-3:hover {
    background-color: #C62828 !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C62828 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C62828 !important; }

.border-red.border-darken-3 {
  border: 1px solid #C62828 !important; }

.border-top-red.border-top-darken-3 {
  border-top: 1px solid #C62828 !important; }

.border-bottom-red.border-bottom-darken-3 {
  border-bottom: 1px solid #C62828 !important; }

.border-left-red.border-left-darken-3 {
  border-left: 1px solid #C62828 !important; }

.border-right-red.border-right-darken-3 {
  border-right: 1px solid #C62828 !important; }

.overlay-red.overlay-darken-3 {
  background: #C62828;
  /* The Fallback */
  background: rgba(198, 40, 40, 0.8); }

.red.darken-4 {
  color: #B71C1C !important; }

.bg-red.bg-darken-4 {
  background-color: #B71C1C !important; }

.btn-red.btn-darken-4 {
  border-color: #D32F2F !important;
  background-color: #B71C1C !important; }
  .btn-red.btn-darken-4:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-darken-4:focus, .btn-red.btn-darken-4:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-darken-4 {
  border-color: #B71C1C !important;
  color: #B71C1C !important; }
  .btn-outline-red.btn-outline-darken-4:hover {
    background-color: #B71C1C !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B71C1C !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B71C1C !important; }

.border-red.border-darken-4 {
  border: 1px solid #B71C1C !important; }

.border-top-red.border-top-darken-4 {
  border-top: 1px solid #B71C1C !important; }

.border-bottom-red.border-bottom-darken-4 {
  border-bottom: 1px solid #B71C1C !important; }

.border-left-red.border-left-darken-4 {
  border-left: 1px solid #B71C1C !important; }

.border-right-red.border-right-darken-4 {
  border-right: 1px solid #B71C1C !important; }

.overlay-red.overlay-darken-4 {
  background: #B71C1C;
  /* The Fallback */
  background: rgba(183, 28, 28, 0.8); }

.red.accent-1 {
  color: #FF8A80 !important; }

.bg-red.bg-accent-1 {
  background-color: #FF8A80 !important; }

.btn-red.btn-accent-1 {
  border-color: #D32F2F !important;
  background-color: #FF8A80 !important; }
  .btn-red.btn-accent-1:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-accent-1:focus, .btn-red.btn-accent-1:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-accent-1 {
  border-color: #FF8A80 !important;
  color: #FF8A80 !important; }
  .btn-outline-red.btn-outline-accent-1:hover {
    background-color: #FF8A80 !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8A80 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8A80 !important; }

.border-red.border-accent-1 {
  border: 1px solid #FF8A80 !important; }

.border-top-red.border-top-accent-1 {
  border-top: 1px solid #FF8A80 !important; }

.border-bottom-red.border-bottom-accent-1 {
  border-bottom: 1px solid #FF8A80 !important; }

.border-left-red.border-left-accent-1 {
  border-left: 1px solid #FF8A80 !important; }

.border-right-red.border-right-accent-1 {
  border-right: 1px solid #FF8A80 !important; }

.overlay-red.overlay-accent-1 {
  background: #FF8A80;
  /* The Fallback */
  background: rgba(255, 138, 128, 0.8); }

.red.accent-2 {
  color: #FF5252 !important; }

.bg-red.bg-accent-2 {
  background-color: #FF5252 !important; }

.btn-red.btn-accent-2 {
  border-color: #D32F2F !important;
  background-color: #FF5252 !important; }
  .btn-red.btn-accent-2:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-accent-2:focus, .btn-red.btn-accent-2:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-accent-2 {
  border-color: #FF5252 !important;
  color: #FF5252 !important; }
  .btn-outline-red.btn-outline-accent-2:hover {
    background-color: #FF5252 !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF5252 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF5252 !important; }

.border-red.border-accent-2 {
  border: 1px solid #FF5252 !important; }

.border-top-red.border-top-accent-2 {
  border-top: 1px solid #FF5252 !important; }

.border-bottom-red.border-bottom-accent-2 {
  border-bottom: 1px solid #FF5252 !important; }

.border-left-red.border-left-accent-2 {
  border-left: 1px solid #FF5252 !important; }

.border-right-red.border-right-accent-2 {
  border-right: 1px solid #FF5252 !important; }

.overlay-red.overlay-accent-2 {
  background: #FF5252;
  /* The Fallback */
  background: rgba(255, 82, 82, 0.8); }

.red.accent-3 {
  color: #FF1744 !important; }

.bg-red.bg-accent-3 {
  background-color: #FF1744 !important; }

.btn-red.btn-accent-3 {
  border-color: #D32F2F !important;
  background-color: #FF1744 !important; }
  .btn-red.btn-accent-3:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-accent-3:focus, .btn-red.btn-accent-3:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-accent-3 {
  border-color: #FF1744 !important;
  color: #FF1744 !important; }
  .btn-outline-red.btn-outline-accent-3:hover {
    background-color: #FF1744 !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF1744 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF1744 !important; }

.border-red.border-accent-3 {
  border: 1px solid #FF1744 !important; }

.border-top-red.border-top-accent-3 {
  border-top: 1px solid #FF1744 !important; }

.border-bottom-red.border-bottom-accent-3 {
  border-bottom: 1px solid #FF1744 !important; }

.border-left-red.border-left-accent-3 {
  border-left: 1px solid #FF1744 !important; }

.border-right-red.border-right-accent-3 {
  border-right: 1px solid #FF1744 !important; }

.overlay-red.overlay-accent-3 {
  background: #FF1744;
  /* The Fallback */
  background: rgba(255, 23, 68, 0.8); }

.red.accent-4 {
  color: #D50000 !important; }

.bg-red.bg-accent-4 {
  background-color: #D50000 !important; }

.btn-red.btn-accent-4 {
  border-color: #D32F2F !important;
  background-color: #D50000 !important; }
  .btn-red.btn-accent-4:hover {
    border-color: #D32F2F !important;
    background-color: #C62828 !important; }
  .btn-red.btn-accent-4:focus, .btn-red.btn-accent-4:active {
    border-color: #C62828 !important;
    background-color: #B71C1C !important; }

.btn-outline-red.btn-outline-accent-4 {
  border-color: #D50000 !important;
  color: #D50000 !important; }
  .btn-outline-red.btn-outline-accent-4:hover {
    background-color: #D50000 !important; }

input:focus ~ .bg-red {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D50000 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D50000 !important; }

.border-red.border-accent-4 {
  border: 1px solid #D50000 !important; }

.border-top-red.border-top-accent-4 {
  border-top: 1px solid #D50000 !important; }

.border-bottom-red.border-bottom-accent-4 {
  border-bottom: 1px solid #D50000 !important; }

.border-left-red.border-left-accent-4 {
  border-left: 1px solid #D50000 !important; }

.border-right-red.border-right-accent-4 {
  border-right: 1px solid #D50000 !important; }

.overlay-red.overlay-accent-4 {
  background: #D50000;
  /* The Fallback */
  background: rgba(213, 0, 0, 0.8); }

.pink.lighten-5 {
  color: #FCE4EC !important; }

.bg-pink.bg-lighten-5 {
  background-color: #FCE4EC !important; }

.btn-pink.btn-lighten-5 {
  border-color: #C2185B !important;
  background-color: #FCE4EC !important; }
  .btn-pink.btn-lighten-5:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-lighten-5:focus, .btn-pink.btn-lighten-5:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-lighten-5 {
  border-color: #FCE4EC !important;
  color: #FCE4EC !important; }
  .btn-outline-pink.btn-outline-lighten-5:hover {
    background-color: #FCE4EC !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FCE4EC !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FCE4EC !important; }

.border-pink.border-lighten-5 {
  border: 1px solid #FCE4EC !important; }

.border-top-pink.border-top-lighten-5 {
  border-top: 1px solid #FCE4EC !important; }

.border-bottom-pink.border-bottom-lighten-5 {
  border-bottom: 1px solid #FCE4EC !important; }

.border-left-pink.border-left-lighten-5 {
  border-left: 1px solid #FCE4EC !important; }

.border-right-pink.border-right-lighten-5 {
  border-right: 1px solid #FCE4EC !important; }

.overlay-pink.overlay-lighten-5 {
  background: #FCE4EC;
  /* The Fallback */
  background: rgba(252, 228, 236, 0.8); }

.pink.lighten-4 {
  color: #F8BBD0 !important; }

.bg-pink.bg-lighten-4 {
  background-color: #F8BBD0 !important; }

.btn-pink.btn-lighten-4 {
  border-color: #C2185B !important;
  background-color: #F8BBD0 !important; }
  .btn-pink.btn-lighten-4:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-lighten-4:focus, .btn-pink.btn-lighten-4:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-lighten-4 {
  border-color: #F8BBD0 !important;
  color: #F8BBD0 !important; }
  .btn-outline-pink.btn-outline-lighten-4:hover {
    background-color: #F8BBD0 !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F8BBD0 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F8BBD0 !important; }

.border-pink.border-lighten-4 {
  border: 1px solid #F8BBD0 !important; }

.border-top-pink.border-top-lighten-4 {
  border-top: 1px solid #F8BBD0 !important; }

.border-bottom-pink.border-bottom-lighten-4 {
  border-bottom: 1px solid #F8BBD0 !important; }

.border-left-pink.border-left-lighten-4 {
  border-left: 1px solid #F8BBD0 !important; }

.border-right-pink.border-right-lighten-4 {
  border-right: 1px solid #F8BBD0 !important; }

.overlay-pink.overlay-lighten-4 {
  background: #F8BBD0;
  /* The Fallback */
  background: rgba(248, 187, 208, 0.8); }

.pink.lighten-3 {
  color: #F48FB1 !important; }

.bg-pink.bg-lighten-3 {
  background-color: #F48FB1 !important; }

.btn-pink.btn-lighten-3 {
  border-color: #C2185B !important;
  background-color: #F48FB1 !important; }
  .btn-pink.btn-lighten-3:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-lighten-3:focus, .btn-pink.btn-lighten-3:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-lighten-3 {
  border-color: #F48FB1 !important;
  color: #F48FB1 !important; }
  .btn-outline-pink.btn-outline-lighten-3:hover {
    background-color: #F48FB1 !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F48FB1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F48FB1 !important; }

.border-pink.border-lighten-3 {
  border: 1px solid #F48FB1 !important; }

.border-top-pink.border-top-lighten-3 {
  border-top: 1px solid #F48FB1 !important; }

.border-bottom-pink.border-bottom-lighten-3 {
  border-bottom: 1px solid #F48FB1 !important; }

.border-left-pink.border-left-lighten-3 {
  border-left: 1px solid #F48FB1 !important; }

.border-right-pink.border-right-lighten-3 {
  border-right: 1px solid #F48FB1 !important; }

.overlay-pink.overlay-lighten-3 {
  background: #F48FB1;
  /* The Fallback */
  background: rgba(244, 143, 177, 0.8); }

.pink.lighten-2 {
  color: #F06292 !important; }

.bg-pink.bg-lighten-2 {
  background-color: #F06292 !important; }

.btn-pink.btn-lighten-2 {
  border-color: #C2185B !important;
  background-color: #F06292 !important; }
  .btn-pink.btn-lighten-2:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-lighten-2:focus, .btn-pink.btn-lighten-2:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-lighten-2 {
  border-color: #F06292 !important;
  color: #F06292 !important; }
  .btn-outline-pink.btn-outline-lighten-2:hover {
    background-color: #F06292 !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F06292 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F06292 !important; }

.border-pink.border-lighten-2 {
  border: 1px solid #F06292 !important; }

.border-top-pink.border-top-lighten-2 {
  border-top: 1px solid #F06292 !important; }

.border-bottom-pink.border-bottom-lighten-2 {
  border-bottom: 1px solid #F06292 !important; }

.border-left-pink.border-left-lighten-2 {
  border-left: 1px solid #F06292 !important; }

.border-right-pink.border-right-lighten-2 {
  border-right: 1px solid #F06292 !important; }

.overlay-pink.overlay-lighten-2 {
  background: #F06292;
  /* The Fallback */
  background: rgba(240, 98, 146, 0.8); }

.pink.lighten-1 {
  color: #EC407A !important; }

.bg-pink.bg-lighten-1 {
  background-color: #EC407A !important; }

.btn-pink.btn-lighten-1 {
  border-color: #C2185B !important;
  background-color: #EC407A !important; }
  .btn-pink.btn-lighten-1:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-lighten-1:focus, .btn-pink.btn-lighten-1:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-lighten-1 {
  border-color: #EC407A !important;
  color: #EC407A !important; }
  .btn-outline-pink.btn-outline-lighten-1:hover {
    background-color: #EC407A !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EC407A !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EC407A !important; }

.border-pink.border-lighten-1 {
  border: 1px solid #EC407A !important; }

.border-top-pink.border-top-lighten-1 {
  border-top: 1px solid #EC407A !important; }

.border-bottom-pink.border-bottom-lighten-1 {
  border-bottom: 1px solid #EC407A !important; }

.border-left-pink.border-left-lighten-1 {
  border-left: 1px solid #EC407A !important; }

.border-right-pink.border-right-lighten-1 {
  border-right: 1px solid #EC407A !important; }

.overlay-pink.overlay-lighten-1 {
  background: #EC407A;
  /* The Fallback */
  background: rgba(236, 64, 122, 0.8); }

.pink {
  color: #E91E63 !important; }

.bg-pink {
  background-color: #E91E63 !important; }
  .bg-pink .card-header, .bg-pink .card-footer {
    background-color: transparent; }

.alert-pink {
  border-color: #E91E63 !important;
  background-color: #ef5f90 !important;
  color: #4d081f !important; }
  .alert-pink .alert-link {
    color: #280410 !important; }

.border-pink {
  border-color: #E91E63; }

.bg-pink.badge-glow, .border-pink.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #E91E63;
  box-shadow: 0px 0px 10px #E91E63; }

.overlay-pink {
  background: #E91E63;
  /* The Fallback */
  background: rgba(233, 30, 99, 0.8); }

/* .card-outline-pink{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-pink {
  border-color: #C2185B !important;
  background-color: #E91E63 !important;
  color: #FFFFFF; }
  .btn-pink:hover {
    border-color: #D81B60 !important;
    background-color: #EC407A !important;
    color: #FFF !important; }
  .btn-pink:focus, .btn-pink:active {
    border-color: #C2185B !important;
    background-color: #AD1457 !important;
    color: #FFF !important; }

.btn-outline-pink {
  border-color: #E91E63;
  background-color: transparent;
  color: #E91E63; }
  .btn-outline-pink:hover {
    background-color: #E91E63;
    color: #FFF !important; }

input[type="checkbox"].bg-pink + .custom-control-label:before, input[type="radio"].bg-pink + .custom-control-label:before {
  background-color: #E91E63 !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E91E63 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E91E63 !important; }

.border-pink {
  border: 1px solid #E91E63 !important; }

.border-top-pink {
  border-top: 1px solid #E91E63; }

.border-bottom-pink {
  border-bottom: 1px solid #E91E63; }

.border-left-pink {
  border-left: 1px solid #E91E63; }

.border-right-pink {
  border-right: 1px solid #E91E63; }

.pink.darken-1 {
  color: #D81B60 !important; }

.bg-pink.bg-darken-1 {
  background-color: #D81B60 !important; }

.btn-pink.btn-darken-1 {
  border-color: #C2185B !important;
  background-color: #D81B60 !important; }
  .btn-pink.btn-darken-1:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-darken-1:focus, .btn-pink.btn-darken-1:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-darken-1 {
  border-color: #D81B60 !important;
  color: #D81B60 !important; }
  .btn-outline-pink.btn-outline-darken-1:hover {
    background-color: #D81B60 !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D81B60 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D81B60 !important; }

.border-pink.border-darken-1 {
  border: 1px solid #D81B60 !important; }

.border-top-pink.border-top-darken-1 {
  border-top: 1px solid #D81B60 !important; }

.border-bottom-pink.border-bottom-darken-1 {
  border-bottom: 1px solid #D81B60 !important; }

.border-left-pink.border-left-darken-1 {
  border-left: 1px solid #D81B60 !important; }

.border-right-pink.border-right-darken-1 {
  border-right: 1px solid #D81B60 !important; }

.overlay-pink.overlay-darken-1 {
  background: #D81B60;
  /* The Fallback */
  background: rgba(216, 27, 96, 0.8); }

.pink.darken-2 {
  color: #C2185B !important; }

.bg-pink.bg-darken-2 {
  background-color: #C2185B !important; }

.btn-pink.btn-darken-2 {
  border-color: #C2185B !important;
  background-color: #C2185B !important; }
  .btn-pink.btn-darken-2:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-darken-2:focus, .btn-pink.btn-darken-2:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-darken-2 {
  border-color: #C2185B !important;
  color: #C2185B !important; }
  .btn-outline-pink.btn-outline-darken-2:hover {
    background-color: #C2185B !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C2185B !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C2185B !important; }

.border-pink.border-darken-2 {
  border: 1px solid #C2185B !important; }

.border-top-pink.border-top-darken-2 {
  border-top: 1px solid #C2185B !important; }

.border-bottom-pink.border-bottom-darken-2 {
  border-bottom: 1px solid #C2185B !important; }

.border-left-pink.border-left-darken-2 {
  border-left: 1px solid #C2185B !important; }

.border-right-pink.border-right-darken-2 {
  border-right: 1px solid #C2185B !important; }

.overlay-pink.overlay-darken-2 {
  background: #C2185B;
  /* The Fallback */
  background: rgba(194, 24, 91, 0.8); }

.pink.darken-3 {
  color: #AD1457 !important; }

.bg-pink.bg-darken-3 {
  background-color: #AD1457 !important; }

.btn-pink.btn-darken-3 {
  border-color: #C2185B !important;
  background-color: #AD1457 !important; }
  .btn-pink.btn-darken-3:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-darken-3:focus, .btn-pink.btn-darken-3:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-darken-3 {
  border-color: #AD1457 !important;
  color: #AD1457 !important; }
  .btn-outline-pink.btn-outline-darken-3:hover {
    background-color: #AD1457 !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AD1457 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AD1457 !important; }

.border-pink.border-darken-3 {
  border: 1px solid #AD1457 !important; }

.border-top-pink.border-top-darken-3 {
  border-top: 1px solid #AD1457 !important; }

.border-bottom-pink.border-bottom-darken-3 {
  border-bottom: 1px solid #AD1457 !important; }

.border-left-pink.border-left-darken-3 {
  border-left: 1px solid #AD1457 !important; }

.border-right-pink.border-right-darken-3 {
  border-right: 1px solid #AD1457 !important; }

.overlay-pink.overlay-darken-3 {
  background: #AD1457;
  /* The Fallback */
  background: rgba(173, 20, 87, 0.8); }

.pink.darken-4 {
  color: #880E4F !important; }

.bg-pink.bg-darken-4 {
  background-color: #880E4F !important; }

.btn-pink.btn-darken-4 {
  border-color: #C2185B !important;
  background-color: #880E4F !important; }
  .btn-pink.btn-darken-4:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-darken-4:focus, .btn-pink.btn-darken-4:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-darken-4 {
  border-color: #880E4F !important;
  color: #880E4F !important; }
  .btn-outline-pink.btn-outline-darken-4:hover {
    background-color: #880E4F !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #880E4F !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #880E4F !important; }

.border-pink.border-darken-4 {
  border: 1px solid #880E4F !important; }

.border-top-pink.border-top-darken-4 {
  border-top: 1px solid #880E4F !important; }

.border-bottom-pink.border-bottom-darken-4 {
  border-bottom: 1px solid #880E4F !important; }

.border-left-pink.border-left-darken-4 {
  border-left: 1px solid #880E4F !important; }

.border-right-pink.border-right-darken-4 {
  border-right: 1px solid #880E4F !important; }

.overlay-pink.overlay-darken-4 {
  background: #880E4F;
  /* The Fallback */
  background: rgba(136, 14, 79, 0.8); }

.pink.accent-1 {
  color: #FF80AB !important; }

.bg-pink.bg-accent-1 {
  background-color: #FF80AB !important; }

.btn-pink.btn-accent-1 {
  border-color: #C2185B !important;
  background-color: #FF80AB !important; }
  .btn-pink.btn-accent-1:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-accent-1:focus, .btn-pink.btn-accent-1:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-accent-1 {
  border-color: #FF80AB !important;
  color: #FF80AB !important; }
  .btn-outline-pink.btn-outline-accent-1:hover {
    background-color: #FF80AB !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF80AB !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF80AB !important; }

.border-pink.border-accent-1 {
  border: 1px solid #FF80AB !important; }

.border-top-pink.border-top-accent-1 {
  border-top: 1px solid #FF80AB !important; }

.border-bottom-pink.border-bottom-accent-1 {
  border-bottom: 1px solid #FF80AB !important; }

.border-left-pink.border-left-accent-1 {
  border-left: 1px solid #FF80AB !important; }

.border-right-pink.border-right-accent-1 {
  border-right: 1px solid #FF80AB !important; }

.overlay-pink.overlay-accent-1 {
  background: #FF80AB;
  /* The Fallback */
  background: rgba(255, 128, 171, 0.8); }

.pink.accent-2 {
  color: #FF4081 !important; }

.bg-pink.bg-accent-2 {
  background-color: #FF4081 !important; }

.btn-pink.btn-accent-2 {
  border-color: #C2185B !important;
  background-color: #FF4081 !important; }
  .btn-pink.btn-accent-2:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-accent-2:focus, .btn-pink.btn-accent-2:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-accent-2 {
  border-color: #FF4081 !important;
  color: #FF4081 !important; }
  .btn-outline-pink.btn-outline-accent-2:hover {
    background-color: #FF4081 !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF4081 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF4081 !important; }

.border-pink.border-accent-2 {
  border: 1px solid #FF4081 !important; }

.border-top-pink.border-top-accent-2 {
  border-top: 1px solid #FF4081 !important; }

.border-bottom-pink.border-bottom-accent-2 {
  border-bottom: 1px solid #FF4081 !important; }

.border-left-pink.border-left-accent-2 {
  border-left: 1px solid #FF4081 !important; }

.border-right-pink.border-right-accent-2 {
  border-right: 1px solid #FF4081 !important; }

.overlay-pink.overlay-accent-2 {
  background: #FF4081;
  /* The Fallback */
  background: rgba(255, 64, 129, 0.8); }

.pink.accent-3 {
  color: #F50057 !important; }

.bg-pink.bg-accent-3 {
  background-color: #F50057 !important; }

.btn-pink.btn-accent-3 {
  border-color: #C2185B !important;
  background-color: #F50057 !important; }
  .btn-pink.btn-accent-3:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-accent-3:focus, .btn-pink.btn-accent-3:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-accent-3 {
  border-color: #F50057 !important;
  color: #F50057 !important; }
  .btn-outline-pink.btn-outline-accent-3:hover {
    background-color: #F50057 !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F50057 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F50057 !important; }

.border-pink.border-accent-3 {
  border: 1px solid #F50057 !important; }

.border-top-pink.border-top-accent-3 {
  border-top: 1px solid #F50057 !important; }

.border-bottom-pink.border-bottom-accent-3 {
  border-bottom: 1px solid #F50057 !important; }

.border-left-pink.border-left-accent-3 {
  border-left: 1px solid #F50057 !important; }

.border-right-pink.border-right-accent-3 {
  border-right: 1px solid #F50057 !important; }

.overlay-pink.overlay-accent-3 {
  background: #F50057;
  /* The Fallback */
  background: rgba(245, 0, 87, 0.8); }

.pink.accent-4 {
  color: #C51162 !important; }

.bg-pink.bg-accent-4 {
  background-color: #C51162 !important; }

.btn-pink.btn-accent-4 {
  border-color: #C2185B !important;
  background-color: #C51162 !important; }
  .btn-pink.btn-accent-4:hover {
    border-color: #C2185B !important;
    background-color: #AD1457 !important; }
  .btn-pink.btn-accent-4:focus, .btn-pink.btn-accent-4:active {
    border-color: #AD1457 !important;
    background-color: #880E4F !important; }

.btn-outline-pink.btn-outline-accent-4 {
  border-color: #C51162 !important;
  color: #C51162 !important; }
  .btn-outline-pink.btn-outline-accent-4:hover {
    background-color: #C51162 !important; }

input:focus ~ .bg-pink {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C51162 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C51162 !important; }

.border-pink.border-accent-4 {
  border: 1px solid #C51162 !important; }

.border-top-pink.border-top-accent-4 {
  border-top: 1px solid #C51162 !important; }

.border-bottom-pink.border-bottom-accent-4 {
  border-bottom: 1px solid #C51162 !important; }

.border-left-pink.border-left-accent-4 {
  border-left: 1px solid #C51162 !important; }

.border-right-pink.border-right-accent-4 {
  border-right: 1px solid #C51162 !important; }

.overlay-pink.overlay-accent-4 {
  background: #C51162;
  /* The Fallback */
  background: rgba(197, 17, 98, 0.8); }

.purple.lighten-5 {
  color: #F3E5F5 !important; }

.bg-purple.bg-lighten-5 {
  background-color: #F3E5F5 !important; }

.btn-purple.btn-lighten-5 {
  border-color: #7B1FA2 !important;
  background-color: #F3E5F5 !important; }
  .btn-purple.btn-lighten-5:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-lighten-5:focus, .btn-purple.btn-lighten-5:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-lighten-5 {
  border-color: #F3E5F5 !important;
  color: #F3E5F5 !important; }
  .btn-outline-purple.btn-outline-lighten-5:hover {
    background-color: #F3E5F5 !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F3E5F5 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F3E5F5 !important; }

.border-purple.border-lighten-5 {
  border: 1px solid #F3E5F5 !important; }

.border-top-purple.border-top-lighten-5 {
  border-top: 1px solid #F3E5F5 !important; }

.border-bottom-purple.border-bottom-lighten-5 {
  border-bottom: 1px solid #F3E5F5 !important; }

.border-left-purple.border-left-lighten-5 {
  border-left: 1px solid #F3E5F5 !important; }

.border-right-purple.border-right-lighten-5 {
  border-right: 1px solid #F3E5F5 !important; }

.overlay-purple.overlay-lighten-5 {
  background: #F3E5F5;
  /* The Fallback */
  background: rgba(243, 229, 245, 0.8); }

.purple.lighten-4 {
  color: #E1BEE7 !important; }

.bg-purple.bg-lighten-4 {
  background-color: #E1BEE7 !important; }

.btn-purple.btn-lighten-4 {
  border-color: #7B1FA2 !important;
  background-color: #E1BEE7 !important; }
  .btn-purple.btn-lighten-4:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-lighten-4:focus, .btn-purple.btn-lighten-4:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-lighten-4 {
  border-color: #E1BEE7 !important;
  color: #E1BEE7 !important; }
  .btn-outline-purple.btn-outline-lighten-4:hover {
    background-color: #E1BEE7 !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E1BEE7 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E1BEE7 !important; }

.border-purple.border-lighten-4 {
  border: 1px solid #E1BEE7 !important; }

.border-top-purple.border-top-lighten-4 {
  border-top: 1px solid #E1BEE7 !important; }

.border-bottom-purple.border-bottom-lighten-4 {
  border-bottom: 1px solid #E1BEE7 !important; }

.border-left-purple.border-left-lighten-4 {
  border-left: 1px solid #E1BEE7 !important; }

.border-right-purple.border-right-lighten-4 {
  border-right: 1px solid #E1BEE7 !important; }

.overlay-purple.overlay-lighten-4 {
  background: #E1BEE7;
  /* The Fallback */
  background: rgba(225, 190, 231, 0.8); }

.purple.lighten-3 {
  color: #CE93D8 !important; }

.bg-purple.bg-lighten-3 {
  background-color: #CE93D8 !important; }

.btn-purple.btn-lighten-3 {
  border-color: #7B1FA2 !important;
  background-color: #CE93D8 !important; }
  .btn-purple.btn-lighten-3:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-lighten-3:focus, .btn-purple.btn-lighten-3:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-lighten-3 {
  border-color: #CE93D8 !important;
  color: #CE93D8 !important; }
  .btn-outline-purple.btn-outline-lighten-3:hover {
    background-color: #CE93D8 !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CE93D8 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CE93D8 !important; }

.border-purple.border-lighten-3 {
  border: 1px solid #CE93D8 !important; }

.border-top-purple.border-top-lighten-3 {
  border-top: 1px solid #CE93D8 !important; }

.border-bottom-purple.border-bottom-lighten-3 {
  border-bottom: 1px solid #CE93D8 !important; }

.border-left-purple.border-left-lighten-3 {
  border-left: 1px solid #CE93D8 !important; }

.border-right-purple.border-right-lighten-3 {
  border-right: 1px solid #CE93D8 !important; }

.overlay-purple.overlay-lighten-3 {
  background: #CE93D8;
  /* The Fallback */
  background: rgba(206, 147, 216, 0.8); }

.purple.lighten-2 {
  color: #BA68C8 !important; }

.bg-purple.bg-lighten-2 {
  background-color: #BA68C8 !important; }

.btn-purple.btn-lighten-2 {
  border-color: #7B1FA2 !important;
  background-color: #BA68C8 !important; }
  .btn-purple.btn-lighten-2:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-lighten-2:focus, .btn-purple.btn-lighten-2:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-lighten-2 {
  border-color: #BA68C8 !important;
  color: #BA68C8 !important; }
  .btn-outline-purple.btn-outline-lighten-2:hover {
    background-color: #BA68C8 !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BA68C8 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BA68C8 !important; }

.border-purple.border-lighten-2 {
  border: 1px solid #BA68C8 !important; }

.border-top-purple.border-top-lighten-2 {
  border-top: 1px solid #BA68C8 !important; }

.border-bottom-purple.border-bottom-lighten-2 {
  border-bottom: 1px solid #BA68C8 !important; }

.border-left-purple.border-left-lighten-2 {
  border-left: 1px solid #BA68C8 !important; }

.border-right-purple.border-right-lighten-2 {
  border-right: 1px solid #BA68C8 !important; }

.overlay-purple.overlay-lighten-2 {
  background: #BA68C8;
  /* The Fallback */
  background: rgba(186, 104, 200, 0.8); }

.purple.lighten-1 {
  color: #AB47BC !important; }

.bg-purple.bg-lighten-1 {
  background-color: #AB47BC !important; }

.btn-purple.btn-lighten-1 {
  border-color: #7B1FA2 !important;
  background-color: #AB47BC !important; }
  .btn-purple.btn-lighten-1:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-lighten-1:focus, .btn-purple.btn-lighten-1:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-lighten-1 {
  border-color: #AB47BC !important;
  color: #AB47BC !important; }
  .btn-outline-purple.btn-outline-lighten-1:hover {
    background-color: #AB47BC !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AB47BC !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AB47BC !important; }

.border-purple.border-lighten-1 {
  border: 1px solid #AB47BC !important; }

.border-top-purple.border-top-lighten-1 {
  border-top: 1px solid #AB47BC !important; }

.border-bottom-purple.border-bottom-lighten-1 {
  border-bottom: 1px solid #AB47BC !important; }

.border-left-purple.border-left-lighten-1 {
  border-left: 1px solid #AB47BC !important; }

.border-right-purple.border-right-lighten-1 {
  border-right: 1px solid #AB47BC !important; }

.overlay-purple.overlay-lighten-1 {
  background: #AB47BC;
  /* The Fallback */
  background: rgba(171, 71, 188, 0.8); }

.purple {
  color: #9C27B0 !important; }

.bg-purple {
  background-color: #9C27B0 !important; }
  .bg-purple .card-header, .bg-purple .card-footer {
    background-color: transparent; }

.alert-purple {
  border-color: #9C27B0 !important;
  background-color: #c248d6 !important;
  color: #1a071e !important; }
  .alert-purple .alert-link {
    color: black !important; }

.border-purple {
  border-color: #9C27B0; }

.bg-purple.badge-glow, .border-purple.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #9C27B0;
  box-shadow: 0px 0px 10px #9C27B0; }

.overlay-purple {
  background: #9C27B0;
  /* The Fallback */
  background: rgba(156, 39, 176, 0.8); }

/* .card-outline-purple{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-purple {
  border-color: #7B1FA2 !important;
  background-color: #9C27B0 !important;
  color: #FFFFFF; }
  .btn-purple:hover {
    border-color: #8E24AA !important;
    background-color: #AB47BC !important;
    color: #FFF !important; }
  .btn-purple:focus, .btn-purple:active {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important;
    color: #FFF !important; }

.btn-outline-purple {
  border-color: #9C27B0;
  background-color: transparent;
  color: #9C27B0; }
  .btn-outline-purple:hover {
    background-color: #9C27B0;
    color: #FFF !important; }

input[type="checkbox"].bg-purple + .custom-control-label:before, input[type="radio"].bg-purple + .custom-control-label:before {
  background-color: #9C27B0 !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9C27B0 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9C27B0 !important; }

.border-purple {
  border: 1px solid #9C27B0 !important; }

.border-top-purple {
  border-top: 1px solid #9C27B0; }

.border-bottom-purple {
  border-bottom: 1px solid #9C27B0; }

.border-left-purple {
  border-left: 1px solid #9C27B0; }

.border-right-purple {
  border-right: 1px solid #9C27B0; }

.purple.darken-1 {
  color: #8E24AA !important; }

.bg-purple.bg-darken-1 {
  background-color: #8E24AA !important; }

.btn-purple.btn-darken-1 {
  border-color: #7B1FA2 !important;
  background-color: #8E24AA !important; }
  .btn-purple.btn-darken-1:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-darken-1:focus, .btn-purple.btn-darken-1:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-darken-1 {
  border-color: #8E24AA !important;
  color: #8E24AA !important; }
  .btn-outline-purple.btn-outline-darken-1:hover {
    background-color: #8E24AA !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8E24AA !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8E24AA !important; }

.border-purple.border-darken-1 {
  border: 1px solid #8E24AA !important; }

.border-top-purple.border-top-darken-1 {
  border-top: 1px solid #8E24AA !important; }

.border-bottom-purple.border-bottom-darken-1 {
  border-bottom: 1px solid #8E24AA !important; }

.border-left-purple.border-left-darken-1 {
  border-left: 1px solid #8E24AA !important; }

.border-right-purple.border-right-darken-1 {
  border-right: 1px solid #8E24AA !important; }

.overlay-purple.overlay-darken-1 {
  background: #8E24AA;
  /* The Fallback */
  background: rgba(142, 36, 170, 0.8); }

.purple.darken-2 {
  color: #7B1FA2 !important; }

.bg-purple.bg-darken-2 {
  background-color: #7B1FA2 !important; }

.btn-purple.btn-darken-2 {
  border-color: #7B1FA2 !important;
  background-color: #7B1FA2 !important; }
  .btn-purple.btn-darken-2:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-darken-2:focus, .btn-purple.btn-darken-2:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-darken-2 {
  border-color: #7B1FA2 !important;
  color: #7B1FA2 !important; }
  .btn-outline-purple.btn-outline-darken-2:hover {
    background-color: #7B1FA2 !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7B1FA2 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7B1FA2 !important; }

.border-purple.border-darken-2 {
  border: 1px solid #7B1FA2 !important; }

.border-top-purple.border-top-darken-2 {
  border-top: 1px solid #7B1FA2 !important; }

.border-bottom-purple.border-bottom-darken-2 {
  border-bottom: 1px solid #7B1FA2 !important; }

.border-left-purple.border-left-darken-2 {
  border-left: 1px solid #7B1FA2 !important; }

.border-right-purple.border-right-darken-2 {
  border-right: 1px solid #7B1FA2 !important; }

.overlay-purple.overlay-darken-2 {
  background: #7B1FA2;
  /* The Fallback */
  background: rgba(123, 31, 162, 0.8); }

.purple.darken-3 {
  color: #6A1B9A !important; }

.bg-purple.bg-darken-3 {
  background-color: #6A1B9A !important; }

.btn-purple.btn-darken-3 {
  border-color: #7B1FA2 !important;
  background-color: #6A1B9A !important; }
  .btn-purple.btn-darken-3:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-darken-3:focus, .btn-purple.btn-darken-3:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-darken-3 {
  border-color: #6A1B9A !important;
  color: #6A1B9A !important; }
  .btn-outline-purple.btn-outline-darken-3:hover {
    background-color: #6A1B9A !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6A1B9A !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6A1B9A !important; }

.border-purple.border-darken-3 {
  border: 1px solid #6A1B9A !important; }

.border-top-purple.border-top-darken-3 {
  border-top: 1px solid #6A1B9A !important; }

.border-bottom-purple.border-bottom-darken-3 {
  border-bottom: 1px solid #6A1B9A !important; }

.border-left-purple.border-left-darken-3 {
  border-left: 1px solid #6A1B9A !important; }

.border-right-purple.border-right-darken-3 {
  border-right: 1px solid #6A1B9A !important; }

.overlay-purple.overlay-darken-3 {
  background: #6A1B9A;
  /* The Fallback */
  background: rgba(106, 27, 154, 0.8); }

.purple.darken-4 {
  color: #4A148C !important; }

.bg-purple.bg-darken-4 {
  background-color: #4A148C !important; }

.btn-purple.btn-darken-4 {
  border-color: #7B1FA2 !important;
  background-color: #4A148C !important; }
  .btn-purple.btn-darken-4:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-darken-4:focus, .btn-purple.btn-darken-4:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-darken-4 {
  border-color: #4A148C !important;
  color: #4A148C !important; }
  .btn-outline-purple.btn-outline-darken-4:hover {
    background-color: #4A148C !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4A148C !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4A148C !important; }

.border-purple.border-darken-4 {
  border: 1px solid #4A148C !important; }

.border-top-purple.border-top-darken-4 {
  border-top: 1px solid #4A148C !important; }

.border-bottom-purple.border-bottom-darken-4 {
  border-bottom: 1px solid #4A148C !important; }

.border-left-purple.border-left-darken-4 {
  border-left: 1px solid #4A148C !important; }

.border-right-purple.border-right-darken-4 {
  border-right: 1px solid #4A148C !important; }

.overlay-purple.overlay-darken-4 {
  background: #4A148C;
  /* The Fallback */
  background: rgba(74, 20, 140, 0.8); }

.purple.accent-1 {
  color: #EA80FC !important; }

.bg-purple.bg-accent-1 {
  background-color: #EA80FC !important; }

.btn-purple.btn-accent-1 {
  border-color: #7B1FA2 !important;
  background-color: #EA80FC !important; }
  .btn-purple.btn-accent-1:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-accent-1:focus, .btn-purple.btn-accent-1:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-accent-1 {
  border-color: #EA80FC !important;
  color: #EA80FC !important; }
  .btn-outline-purple.btn-outline-accent-1:hover {
    background-color: #EA80FC !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EA80FC !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EA80FC !important; }

.border-purple.border-accent-1 {
  border: 1px solid #EA80FC !important; }

.border-top-purple.border-top-accent-1 {
  border-top: 1px solid #EA80FC !important; }

.border-bottom-purple.border-bottom-accent-1 {
  border-bottom: 1px solid #EA80FC !important; }

.border-left-purple.border-left-accent-1 {
  border-left: 1px solid #EA80FC !important; }

.border-right-purple.border-right-accent-1 {
  border-right: 1px solid #EA80FC !important; }

.overlay-purple.overlay-accent-1 {
  background: #EA80FC;
  /* The Fallback */
  background: rgba(234, 128, 252, 0.8); }

.purple.accent-2 {
  color: #E040FB !important; }

.bg-purple.bg-accent-2 {
  background-color: #E040FB !important; }

.btn-purple.btn-accent-2 {
  border-color: #7B1FA2 !important;
  background-color: #E040FB !important; }
  .btn-purple.btn-accent-2:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-accent-2:focus, .btn-purple.btn-accent-2:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-accent-2 {
  border-color: #E040FB !important;
  color: #E040FB !important; }
  .btn-outline-purple.btn-outline-accent-2:hover {
    background-color: #E040FB !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E040FB !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E040FB !important; }

.border-purple.border-accent-2 {
  border: 1px solid #E040FB !important; }

.border-top-purple.border-top-accent-2 {
  border-top: 1px solid #E040FB !important; }

.border-bottom-purple.border-bottom-accent-2 {
  border-bottom: 1px solid #E040FB !important; }

.border-left-purple.border-left-accent-2 {
  border-left: 1px solid #E040FB !important; }

.border-right-purple.border-right-accent-2 {
  border-right: 1px solid #E040FB !important; }

.overlay-purple.overlay-accent-2 {
  background: #E040FB;
  /* The Fallback */
  background: rgba(224, 64, 251, 0.8); }

.purple.accent-3 {
  color: #D500F9 !important; }

.bg-purple.bg-accent-3 {
  background-color: #D500F9 !important; }

.btn-purple.btn-accent-3 {
  border-color: #7B1FA2 !important;
  background-color: #D500F9 !important; }
  .btn-purple.btn-accent-3:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-accent-3:focus, .btn-purple.btn-accent-3:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-accent-3 {
  border-color: #D500F9 !important;
  color: #D500F9 !important; }
  .btn-outline-purple.btn-outline-accent-3:hover {
    background-color: #D500F9 !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D500F9 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D500F9 !important; }

.border-purple.border-accent-3 {
  border: 1px solid #D500F9 !important; }

.border-top-purple.border-top-accent-3 {
  border-top: 1px solid #D500F9 !important; }

.border-bottom-purple.border-bottom-accent-3 {
  border-bottom: 1px solid #D500F9 !important; }

.border-left-purple.border-left-accent-3 {
  border-left: 1px solid #D500F9 !important; }

.border-right-purple.border-right-accent-3 {
  border-right: 1px solid #D500F9 !important; }

.overlay-purple.overlay-accent-3 {
  background: #D500F9;
  /* The Fallback */
  background: rgba(213, 0, 249, 0.8); }

.purple.accent-4 {
  color: #DD00FF !important; }

.bg-purple.bg-accent-4 {
  background-color: #DD00FF !important; }

.btn-purple.btn-accent-4 {
  border-color: #7B1FA2 !important;
  background-color: #DD00FF !important; }
  .btn-purple.btn-accent-4:hover {
    border-color: #7B1FA2 !important;
    background-color: #6A1B9A !important; }
  .btn-purple.btn-accent-4:focus, .btn-purple.btn-accent-4:active {
    border-color: #6A1B9A !important;
    background-color: #4A148C !important; }

.btn-outline-purple.btn-outline-accent-4 {
  border-color: #DD00FF !important;
  color: #DD00FF !important; }
  .btn-outline-purple.btn-outline-accent-4:hover {
    background-color: #DD00FF !important; }

input:focus ~ .bg-purple {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #DD00FF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #DD00FF !important; }

.border-purple.border-accent-4 {
  border: 1px solid #DD00FF !important; }

.border-top-purple.border-top-accent-4 {
  border-top: 1px solid #DD00FF !important; }

.border-bottom-purple.border-bottom-accent-4 {
  border-bottom: 1px solid #DD00FF !important; }

.border-left-purple.border-left-accent-4 {
  border-left: 1px solid #DD00FF !important; }

.border-right-purple.border-right-accent-4 {
  border-right: 1px solid #DD00FF !important; }

.overlay-purple.overlay-accent-4 {
  background: #DD00FF;
  /* The Fallback */
  background: rgba(221, 0, 255, 0.8); }

.blue.lighten-5 {
  color: #E3F2FD !important; }

.bg-blue.bg-lighten-5 {
  background-color: #E3F2FD !important; }

.btn-blue.btn-lighten-5 {
  border-color: #1976D2 !important;
  background-color: #E3F2FD !important; }
  .btn-blue.btn-lighten-5:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-lighten-5:focus, .btn-blue.btn-lighten-5:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-lighten-5 {
  border-color: #E3F2FD !important;
  color: #E3F2FD !important; }
  .btn-outline-blue.btn-outline-lighten-5:hover {
    background-color: #E3F2FD !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E3F2FD !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E3F2FD !important; }

.border-blue.border-lighten-5 {
  border: 1px solid #E3F2FD !important; }

.border-top-blue.border-top-lighten-5 {
  border-top: 1px solid #E3F2FD !important; }

.border-bottom-blue.border-bottom-lighten-5 {
  border-bottom: 1px solid #E3F2FD !important; }

.border-left-blue.border-left-lighten-5 {
  border-left: 1px solid #E3F2FD !important; }

.border-right-blue.border-right-lighten-5 {
  border-right: 1px solid #E3F2FD !important; }

.overlay-blue.overlay-lighten-5 {
  background: #E3F2FD;
  /* The Fallback */
  background: rgba(227, 242, 253, 0.8); }

.blue.lighten-4 {
  color: #BBDEFB !important; }

.bg-blue.bg-lighten-4 {
  background-color: #BBDEFB !important; }

.btn-blue.btn-lighten-4 {
  border-color: #1976D2 !important;
  background-color: #BBDEFB !important; }
  .btn-blue.btn-lighten-4:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-lighten-4:focus, .btn-blue.btn-lighten-4:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-lighten-4 {
  border-color: #BBDEFB !important;
  color: #BBDEFB !important; }
  .btn-outline-blue.btn-outline-lighten-4:hover {
    background-color: #BBDEFB !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BBDEFB !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BBDEFB !important; }

.border-blue.border-lighten-4 {
  border: 1px solid #BBDEFB !important; }

.border-top-blue.border-top-lighten-4 {
  border-top: 1px solid #BBDEFB !important; }

.border-bottom-blue.border-bottom-lighten-4 {
  border-bottom: 1px solid #BBDEFB !important; }

.border-left-blue.border-left-lighten-4 {
  border-left: 1px solid #BBDEFB !important; }

.border-right-blue.border-right-lighten-4 {
  border-right: 1px solid #BBDEFB !important; }

.overlay-blue.overlay-lighten-4 {
  background: #BBDEFB;
  /* The Fallback */
  background: rgba(187, 222, 251, 0.8); }

.blue.lighten-3 {
  color: #90CAF9 !important; }

.bg-blue.bg-lighten-3 {
  background-color: #90CAF9 !important; }

.btn-blue.btn-lighten-3 {
  border-color: #1976D2 !important;
  background-color: #90CAF9 !important; }
  .btn-blue.btn-lighten-3:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-lighten-3:focus, .btn-blue.btn-lighten-3:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-lighten-3 {
  border-color: #90CAF9 !important;
  color: #90CAF9 !important; }
  .btn-outline-blue.btn-outline-lighten-3:hover {
    background-color: #90CAF9 !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #90CAF9 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #90CAF9 !important; }

.border-blue.border-lighten-3 {
  border: 1px solid #90CAF9 !important; }

.border-top-blue.border-top-lighten-3 {
  border-top: 1px solid #90CAF9 !important; }

.border-bottom-blue.border-bottom-lighten-3 {
  border-bottom: 1px solid #90CAF9 !important; }

.border-left-blue.border-left-lighten-3 {
  border-left: 1px solid #90CAF9 !important; }

.border-right-blue.border-right-lighten-3 {
  border-right: 1px solid #90CAF9 !important; }

.overlay-blue.overlay-lighten-3 {
  background: #90CAF9;
  /* The Fallback */
  background: rgba(144, 202, 249, 0.8); }

.blue.lighten-2 {
  color: #64B5F6 !important; }

.bg-blue.bg-lighten-2 {
  background-color: #64B5F6 !important; }

.btn-blue.btn-lighten-2 {
  border-color: #1976D2 !important;
  background-color: #64B5F6 !important; }
  .btn-blue.btn-lighten-2:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-lighten-2:focus, .btn-blue.btn-lighten-2:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-lighten-2 {
  border-color: #64B5F6 !important;
  color: #64B5F6 !important; }
  .btn-outline-blue.btn-outline-lighten-2:hover {
    background-color: #64B5F6 !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64B5F6 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64B5F6 !important; }

.border-blue.border-lighten-2 {
  border: 1px solid #64B5F6 !important; }

.border-top-blue.border-top-lighten-2 {
  border-top: 1px solid #64B5F6 !important; }

.border-bottom-blue.border-bottom-lighten-2 {
  border-bottom: 1px solid #64B5F6 !important; }

.border-left-blue.border-left-lighten-2 {
  border-left: 1px solid #64B5F6 !important; }

.border-right-blue.border-right-lighten-2 {
  border-right: 1px solid #64B5F6 !important; }

.overlay-blue.overlay-lighten-2 {
  background: #64B5F6;
  /* The Fallback */
  background: rgba(100, 181, 246, 0.8); }

.blue.lighten-1 {
  color: #42A5F5 !important; }

.bg-blue.bg-lighten-1 {
  background-color: #42A5F5 !important; }

.btn-blue.btn-lighten-1 {
  border-color: #1976D2 !important;
  background-color: #42A5F5 !important; }
  .btn-blue.btn-lighten-1:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-lighten-1:focus, .btn-blue.btn-lighten-1:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-lighten-1 {
  border-color: #42A5F5 !important;
  color: #42A5F5 !important; }
  .btn-outline-blue.btn-outline-lighten-1:hover {
    background-color: #42A5F5 !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #42A5F5 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #42A5F5 !important; }

.border-blue.border-lighten-1 {
  border: 1px solid #42A5F5 !important; }

.border-top-blue.border-top-lighten-1 {
  border-top: 1px solid #42A5F5 !important; }

.border-bottom-blue.border-bottom-lighten-1 {
  border-bottom: 1px solid #42A5F5 !important; }

.border-left-blue.border-left-lighten-1 {
  border-left: 1px solid #42A5F5 !important; }

.border-right-blue.border-right-lighten-1 {
  border-right: 1px solid #42A5F5 !important; }

.overlay-blue.overlay-lighten-1 {
  background: #42A5F5;
  /* The Fallback */
  background: rgba(66, 165, 245, 0.8); }

.blue {
  color: #2196F3 !important; }

.bg-blue {
  background-color: #2196F3 !important; }
  .bg-blue .card-header, .bg-blue .card-footer {
    background-color: transparent; }

.alert-blue {
  border-color: #2196F3 !important;
  background-color: #65b6f7 !important;
  color: #05365d !important; }
  .alert-blue .alert-link {
    color: #031f36 !important; }

.border-blue {
  border-color: #2196F3; }

.bg-blue.badge-glow, .border-blue.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #2196F3;
  box-shadow: 0px 0px 10px #2196F3; }

.overlay-blue {
  background: #2196F3;
  /* The Fallback */
  background: rgba(33, 150, 243, 0.8); }

/* .card-outline-blue{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-blue {
  border-color: #1976D2 !important;
  background-color: #2196F3 !important;
  color: #FFFFFF; }
  .btn-blue:hover {
    border-color: #1E88E5 !important;
    background-color: #42A5F5 !important;
    color: #FFF !important; }
  .btn-blue:focus, .btn-blue:active {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important;
    color: #FFF !important; }

.btn-outline-blue {
  border-color: #2196F3;
  background-color: transparent;
  color: #2196F3; }
  .btn-outline-blue:hover {
    background-color: #2196F3;
    color: #FFF !important; }

input[type="checkbox"].bg-blue + .custom-control-label:before, input[type="radio"].bg-blue + .custom-control-label:before {
  background-color: #2196F3 !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2196F3 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2196F3 !important; }

.border-blue {
  border: 1px solid #2196F3 !important; }

.border-top-blue {
  border-top: 1px solid #2196F3; }

.border-bottom-blue {
  border-bottom: 1px solid #2196F3; }

.border-left-blue {
  border-left: 1px solid #2196F3; }

.border-right-blue {
  border-right: 1px solid #2196F3; }

.blue.darken-1 {
  color: #1E88E5 !important; }

.bg-blue.bg-darken-1 {
  background-color: #1E88E5 !important; }

.btn-blue.btn-darken-1 {
  border-color: #1976D2 !important;
  background-color: #1E88E5 !important; }
  .btn-blue.btn-darken-1:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-darken-1:focus, .btn-blue.btn-darken-1:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-darken-1 {
  border-color: #1E88E5 !important;
  color: #1E88E5 !important; }
  .btn-outline-blue.btn-outline-darken-1:hover {
    background-color: #1E88E5 !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1E88E5 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1E88E5 !important; }

.border-blue.border-darken-1 {
  border: 1px solid #1E88E5 !important; }

.border-top-blue.border-top-darken-1 {
  border-top: 1px solid #1E88E5 !important; }

.border-bottom-blue.border-bottom-darken-1 {
  border-bottom: 1px solid #1E88E5 !important; }

.border-left-blue.border-left-darken-1 {
  border-left: 1px solid #1E88E5 !important; }

.border-right-blue.border-right-darken-1 {
  border-right: 1px solid #1E88E5 !important; }

.overlay-blue.overlay-darken-1 {
  background: #1E88E5;
  /* The Fallback */
  background: rgba(30, 136, 229, 0.8); }

.blue.darken-2 {
  color: #1976D2 !important; }

.bg-blue.bg-darken-2 {
  background-color: #1976D2 !important; }

.btn-blue.btn-darken-2 {
  border-color: #1976D2 !important;
  background-color: #1976D2 !important; }
  .btn-blue.btn-darken-2:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-darken-2:focus, .btn-blue.btn-darken-2:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-darken-2 {
  border-color: #1976D2 !important;
  color: #1976D2 !important; }
  .btn-outline-blue.btn-outline-darken-2:hover {
    background-color: #1976D2 !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1976D2 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1976D2 !important; }

.border-blue.border-darken-2 {
  border: 1px solid #1976D2 !important; }

.border-top-blue.border-top-darken-2 {
  border-top: 1px solid #1976D2 !important; }

.border-bottom-blue.border-bottom-darken-2 {
  border-bottom: 1px solid #1976D2 !important; }

.border-left-blue.border-left-darken-2 {
  border-left: 1px solid #1976D2 !important; }

.border-right-blue.border-right-darken-2 {
  border-right: 1px solid #1976D2 !important; }

.overlay-blue.overlay-darken-2 {
  background: #1976D2;
  /* The Fallback */
  background: rgba(25, 118, 210, 0.8); }

.blue.darken-3 {
  color: #1565C0 !important; }

.bg-blue.bg-darken-3 {
  background-color: #1565C0 !important; }

.btn-blue.btn-darken-3 {
  border-color: #1976D2 !important;
  background-color: #1565C0 !important; }
  .btn-blue.btn-darken-3:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-darken-3:focus, .btn-blue.btn-darken-3:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-darken-3 {
  border-color: #1565C0 !important;
  color: #1565C0 !important; }
  .btn-outline-blue.btn-outline-darken-3:hover {
    background-color: #1565C0 !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1565C0 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1565C0 !important; }

.border-blue.border-darken-3 {
  border: 1px solid #1565C0 !important; }

.border-top-blue.border-top-darken-3 {
  border-top: 1px solid #1565C0 !important; }

.border-bottom-blue.border-bottom-darken-3 {
  border-bottom: 1px solid #1565C0 !important; }

.border-left-blue.border-left-darken-3 {
  border-left: 1px solid #1565C0 !important; }

.border-right-blue.border-right-darken-3 {
  border-right: 1px solid #1565C0 !important; }

.overlay-blue.overlay-darken-3 {
  background: #1565C0;
  /* The Fallback */
  background: rgba(21, 101, 192, 0.8); }

.blue.darken-4 {
  color: #0D47A1 !important; }

.bg-blue.bg-darken-4 {
  background-color: #0D47A1 !important; }

.btn-blue.btn-darken-4 {
  border-color: #1976D2 !important;
  background-color: #0D47A1 !important; }
  .btn-blue.btn-darken-4:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-darken-4:focus, .btn-blue.btn-darken-4:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-darken-4 {
  border-color: #0D47A1 !important;
  color: #0D47A1 !important; }
  .btn-outline-blue.btn-outline-darken-4:hover {
    background-color: #0D47A1 !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0D47A1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0D47A1 !important; }

.border-blue.border-darken-4 {
  border: 1px solid #0D47A1 !important; }

.border-top-blue.border-top-darken-4 {
  border-top: 1px solid #0D47A1 !important; }

.border-bottom-blue.border-bottom-darken-4 {
  border-bottom: 1px solid #0D47A1 !important; }

.border-left-blue.border-left-darken-4 {
  border-left: 1px solid #0D47A1 !important; }

.border-right-blue.border-right-darken-4 {
  border-right: 1px solid #0D47A1 !important; }

.overlay-blue.overlay-darken-4 {
  background: #0D47A1;
  /* The Fallback */
  background: rgba(13, 71, 161, 0.8); }

.blue.accent-1 {
  color: #82B1FF !important; }

.bg-blue.bg-accent-1 {
  background-color: #82B1FF !important; }

.btn-blue.btn-accent-1 {
  border-color: #1976D2 !important;
  background-color: #82B1FF !important; }
  .btn-blue.btn-accent-1:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-accent-1:focus, .btn-blue.btn-accent-1:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-accent-1 {
  border-color: #82B1FF !important;
  color: #82B1FF !important; }
  .btn-outline-blue.btn-outline-accent-1:hover {
    background-color: #82B1FF !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #82B1FF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #82B1FF !important; }

.border-blue.border-accent-1 {
  border: 1px solid #82B1FF !important; }

.border-top-blue.border-top-accent-1 {
  border-top: 1px solid #82B1FF !important; }

.border-bottom-blue.border-bottom-accent-1 {
  border-bottom: 1px solid #82B1FF !important; }

.border-left-blue.border-left-accent-1 {
  border-left: 1px solid #82B1FF !important; }

.border-right-blue.border-right-accent-1 {
  border-right: 1px solid #82B1FF !important; }

.overlay-blue.overlay-accent-1 {
  background: #82B1FF;
  /* The Fallback */
  background: rgba(130, 177, 255, 0.8); }

.blue.accent-2 {
  color: #448AFF !important; }

.bg-blue.bg-accent-2 {
  background-color: #448AFF !important; }

.btn-blue.btn-accent-2 {
  border-color: #1976D2 !important;
  background-color: #448AFF !important; }
  .btn-blue.btn-accent-2:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-accent-2:focus, .btn-blue.btn-accent-2:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-accent-2 {
  border-color: #448AFF !important;
  color: #448AFF !important; }
  .btn-outline-blue.btn-outline-accent-2:hover {
    background-color: #448AFF !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #448AFF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #448AFF !important; }

.border-blue.border-accent-2 {
  border: 1px solid #448AFF !important; }

.border-top-blue.border-top-accent-2 {
  border-top: 1px solid #448AFF !important; }

.border-bottom-blue.border-bottom-accent-2 {
  border-bottom: 1px solid #448AFF !important; }

.border-left-blue.border-left-accent-2 {
  border-left: 1px solid #448AFF !important; }

.border-right-blue.border-right-accent-2 {
  border-right: 1px solid #448AFF !important; }

.overlay-blue.overlay-accent-2 {
  background: #448AFF;
  /* The Fallback */
  background: rgba(68, 138, 255, 0.8); }

.blue.accent-3 {
  color: #2979FF !important; }

.bg-blue.bg-accent-3 {
  background-color: #2979FF !important; }

.btn-blue.btn-accent-3 {
  border-color: #1976D2 !important;
  background-color: #2979FF !important; }
  .btn-blue.btn-accent-3:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-accent-3:focus, .btn-blue.btn-accent-3:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-accent-3 {
  border-color: #2979FF !important;
  color: #2979FF !important; }
  .btn-outline-blue.btn-outline-accent-3:hover {
    background-color: #2979FF !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2979FF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2979FF !important; }

.border-blue.border-accent-3 {
  border: 1px solid #2979FF !important; }

.border-top-blue.border-top-accent-3 {
  border-top: 1px solid #2979FF !important; }

.border-bottom-blue.border-bottom-accent-3 {
  border-bottom: 1px solid #2979FF !important; }

.border-left-blue.border-left-accent-3 {
  border-left: 1px solid #2979FF !important; }

.border-right-blue.border-right-accent-3 {
  border-right: 1px solid #2979FF !important; }

.overlay-blue.overlay-accent-3 {
  background: #2979FF;
  /* The Fallback */
  background: rgba(41, 121, 255, 0.8); }

.blue.accent-4 {
  color: #2962FF !important; }

.bg-blue.bg-accent-4 {
  background-color: #2962FF !important; }

.btn-blue.btn-accent-4 {
  border-color: #1976D2 !important;
  background-color: #2962FF !important; }
  .btn-blue.btn-accent-4:hover {
    border-color: #1976D2 !important;
    background-color: #1565C0 !important; }
  .btn-blue.btn-accent-4:focus, .btn-blue.btn-accent-4:active {
    border-color: #1565C0 !important;
    background-color: #0D47A1 !important; }

.btn-outline-blue.btn-outline-accent-4 {
  border-color: #2962FF !important;
  color: #2962FF !important; }
  .btn-outline-blue.btn-outline-accent-4:hover {
    background-color: #2962FF !important; }

input:focus ~ .bg-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2962FF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2962FF !important; }

.border-blue.border-accent-4 {
  border: 1px solid #2962FF !important; }

.border-top-blue.border-top-accent-4 {
  border-top: 1px solid #2962FF !important; }

.border-bottom-blue.border-bottom-accent-4 {
  border-bottom: 1px solid #2962FF !important; }

.border-left-blue.border-left-accent-4 {
  border-left: 1px solid #2962FF !important; }

.border-right-blue.border-right-accent-4 {
  border-right: 1px solid #2962FF !important; }

.overlay-blue.overlay-accent-4 {
  background: #2962FF;
  /* The Fallback */
  background: rgba(41, 98, 255, 0.8); }

.cyan.lighten-5 {
  color: #E0F7FA !important; }

.bg-cyan.bg-lighten-5 {
  background-color: #E0F7FA !important; }

.btn-cyan.btn-lighten-5 {
  border-color: #0097A7 !important;
  background-color: #E0F7FA !important; }
  .btn-cyan.btn-lighten-5:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-lighten-5:focus, .btn-cyan.btn-lighten-5:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-5 {
  border-color: #E0F7FA !important;
  color: #E0F7FA !important; }
  .btn-outline-cyan.btn-outline-lighten-5:hover {
    background-color: #E0F7FA !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E0F7FA !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E0F7FA !important; }

.border-cyan.border-lighten-5 {
  border: 1px solid #E0F7FA !important; }

.border-top-cyan.border-top-lighten-5 {
  border-top: 1px solid #E0F7FA !important; }

.border-bottom-cyan.border-bottom-lighten-5 {
  border-bottom: 1px solid #E0F7FA !important; }

.border-left-cyan.border-left-lighten-5 {
  border-left: 1px solid #E0F7FA !important; }

.border-right-cyan.border-right-lighten-5 {
  border-right: 1px solid #E0F7FA !important; }

.overlay-cyan.overlay-lighten-5 {
  background: #E0F7FA;
  /* The Fallback */
  background: rgba(224, 247, 250, 0.8); }

.cyan.lighten-4 {
  color: #B2EBF2 !important; }

.bg-cyan.bg-lighten-4 {
  background-color: #B2EBF2 !important; }

.btn-cyan.btn-lighten-4 {
  border-color: #0097A7 !important;
  background-color: #B2EBF2 !important; }
  .btn-cyan.btn-lighten-4:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-lighten-4:focus, .btn-cyan.btn-lighten-4:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-4 {
  border-color: #B2EBF2 !important;
  color: #B2EBF2 !important; }
  .btn-outline-cyan.btn-outline-lighten-4:hover {
    background-color: #B2EBF2 !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B2EBF2 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B2EBF2 !important; }

.border-cyan.border-lighten-4 {
  border: 1px solid #B2EBF2 !important; }

.border-top-cyan.border-top-lighten-4 {
  border-top: 1px solid #B2EBF2 !important; }

.border-bottom-cyan.border-bottom-lighten-4 {
  border-bottom: 1px solid #B2EBF2 !important; }

.border-left-cyan.border-left-lighten-4 {
  border-left: 1px solid #B2EBF2 !important; }

.border-right-cyan.border-right-lighten-4 {
  border-right: 1px solid #B2EBF2 !important; }

.overlay-cyan.overlay-lighten-4 {
  background: #B2EBF2;
  /* The Fallback */
  background: rgba(178, 235, 242, 0.8); }

.cyan.lighten-3 {
  color: #80DEEA !important; }

.bg-cyan.bg-lighten-3 {
  background-color: #80DEEA !important; }

.btn-cyan.btn-lighten-3 {
  border-color: #0097A7 !important;
  background-color: #80DEEA !important; }
  .btn-cyan.btn-lighten-3:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-lighten-3:focus, .btn-cyan.btn-lighten-3:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-3 {
  border-color: #80DEEA !important;
  color: #80DEEA !important; }
  .btn-outline-cyan.btn-outline-lighten-3:hover {
    background-color: #80DEEA !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80DEEA !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80DEEA !important; }

.border-cyan.border-lighten-3 {
  border: 1px solid #80DEEA !important; }

.border-top-cyan.border-top-lighten-3 {
  border-top: 1px solid #80DEEA !important; }

.border-bottom-cyan.border-bottom-lighten-3 {
  border-bottom: 1px solid #80DEEA !important; }

.border-left-cyan.border-left-lighten-3 {
  border-left: 1px solid #80DEEA !important; }

.border-right-cyan.border-right-lighten-3 {
  border-right: 1px solid #80DEEA !important; }

.overlay-cyan.overlay-lighten-3 {
  background: #80DEEA;
  /* The Fallback */
  background: rgba(128, 222, 234, 0.8); }

.cyan.lighten-2 {
  color: #4DD0E1 !important; }

.bg-cyan.bg-lighten-2 {
  background-color: #4DD0E1 !important; }

.btn-cyan.btn-lighten-2 {
  border-color: #0097A7 !important;
  background-color: #4DD0E1 !important; }
  .btn-cyan.btn-lighten-2:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-lighten-2:focus, .btn-cyan.btn-lighten-2:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-2 {
  border-color: #4DD0E1 !important;
  color: #4DD0E1 !important; }
  .btn-outline-cyan.btn-outline-lighten-2:hover {
    background-color: #4DD0E1 !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4DD0E1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4DD0E1 !important; }

.border-cyan.border-lighten-2 {
  border: 1px solid #4DD0E1 !important; }

.border-top-cyan.border-top-lighten-2 {
  border-top: 1px solid #4DD0E1 !important; }

.border-bottom-cyan.border-bottom-lighten-2 {
  border-bottom: 1px solid #4DD0E1 !important; }

.border-left-cyan.border-left-lighten-2 {
  border-left: 1px solid #4DD0E1 !important; }

.border-right-cyan.border-right-lighten-2 {
  border-right: 1px solid #4DD0E1 !important; }

.overlay-cyan.overlay-lighten-2 {
  background: #4DD0E1;
  /* The Fallback */
  background: rgba(77, 208, 225, 0.8); }

.cyan.lighten-1 {
  color: #26C6DA !important; }

.bg-cyan.bg-lighten-1 {
  background-color: #26C6DA !important; }

.btn-cyan.btn-lighten-1 {
  border-color: #0097A7 !important;
  background-color: #26C6DA !important; }
  .btn-cyan.btn-lighten-1:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-lighten-1:focus, .btn-cyan.btn-lighten-1:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-1 {
  border-color: #26C6DA !important;
  color: #26C6DA !important; }
  .btn-outline-cyan.btn-outline-lighten-1:hover {
    background-color: #26C6DA !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26C6DA !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26C6DA !important; }

.border-cyan.border-lighten-1 {
  border: 1px solid #26C6DA !important; }

.border-top-cyan.border-top-lighten-1 {
  border-top: 1px solid #26C6DA !important; }

.border-bottom-cyan.border-bottom-lighten-1 {
  border-bottom: 1px solid #26C6DA !important; }

.border-left-cyan.border-left-lighten-1 {
  border-left: 1px solid #26C6DA !important; }

.border-right-cyan.border-right-lighten-1 {
  border-right: 1px solid #26C6DA !important; }

.overlay-cyan.overlay-lighten-1 {
  background: #26C6DA;
  /* The Fallback */
  background: rgba(38, 198, 218, 0.8); }

.cyan {
  color: #00BCD4 !important; }

.bg-cyan {
  background-color: #00BCD4 !important; }
  .bg-cyan .card-header, .bg-cyan .card-footer {
    background-color: transparent; }

.alert-cyan {
  border-color: #00BCD4 !important;
  background-color: #1ce5ff !important;
  color: #001e22 !important; }
  .alert-cyan .alert-link {
    color: black !important; }

.border-cyan {
  border-color: #00BCD4; }

.bg-cyan.badge-glow, .border-cyan.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #00BCD4;
  box-shadow: 0px 0px 10px #00BCD4; }

.overlay-cyan {
  background: #00BCD4;
  /* The Fallback */
  background: rgba(0, 188, 212, 0.8); }

/* .card-outline-cyan{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-cyan {
  border-color: #0097A7 !important;
  background-color: #00BCD4 !important;
  color: #FFFFFF; }
  .btn-cyan:hover {
    border-color: #00ACC1 !important;
    background-color: #26C6DA !important;
    color: #FFF !important; }
  .btn-cyan:focus, .btn-cyan:active {
    border-color: #0097A7 !important;
    background-color: #00838F !important;
    color: #FFF !important; }

.btn-outline-cyan {
  border-color: #00BCD4;
  background-color: transparent;
  color: #00BCD4; }
  .btn-outline-cyan:hover {
    background-color: #00BCD4;
    color: #FFF !important; }

input[type="checkbox"].bg-cyan + .custom-control-label:before, input[type="radio"].bg-cyan + .custom-control-label:before {
  background-color: #00BCD4 !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00BCD4 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00BCD4 !important; }

.border-cyan {
  border: 1px solid #00BCD4 !important; }

.border-top-cyan {
  border-top: 1px solid #00BCD4; }

.border-bottom-cyan {
  border-bottom: 1px solid #00BCD4; }

.border-left-cyan {
  border-left: 1px solid #00BCD4; }

.border-right-cyan {
  border-right: 1px solid #00BCD4; }

.cyan.darken-1 {
  color: #00ACC1 !important; }

.bg-cyan.bg-darken-1 {
  background-color: #00ACC1 !important; }

.btn-cyan.btn-darken-1 {
  border-color: #0097A7 !important;
  background-color: #00ACC1 !important; }
  .btn-cyan.btn-darken-1:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-darken-1:focus, .btn-cyan.btn-darken-1:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-darken-1 {
  border-color: #00ACC1 !important;
  color: #00ACC1 !important; }
  .btn-outline-cyan.btn-outline-darken-1:hover {
    background-color: #00ACC1 !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00ACC1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00ACC1 !important; }

.border-cyan.border-darken-1 {
  border: 1px solid #00ACC1 !important; }

.border-top-cyan.border-top-darken-1 {
  border-top: 1px solid #00ACC1 !important; }

.border-bottom-cyan.border-bottom-darken-1 {
  border-bottom: 1px solid #00ACC1 !important; }

.border-left-cyan.border-left-darken-1 {
  border-left: 1px solid #00ACC1 !important; }

.border-right-cyan.border-right-darken-1 {
  border-right: 1px solid #00ACC1 !important; }

.overlay-cyan.overlay-darken-1 {
  background: #00ACC1;
  /* The Fallback */
  background: rgba(0, 172, 193, 0.8); }

.cyan.darken-2 {
  color: #0097A7 !important; }

.bg-cyan.bg-darken-2 {
  background-color: #0097A7 !important; }

.btn-cyan.btn-darken-2 {
  border-color: #0097A7 !important;
  background-color: #0097A7 !important; }
  .btn-cyan.btn-darken-2:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-darken-2:focus, .btn-cyan.btn-darken-2:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-darken-2 {
  border-color: #0097A7 !important;
  color: #0097A7 !important; }
  .btn-outline-cyan.btn-outline-darken-2:hover {
    background-color: #0097A7 !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0097A7 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0097A7 !important; }

.border-cyan.border-darken-2 {
  border: 1px solid #0097A7 !important; }

.border-top-cyan.border-top-darken-2 {
  border-top: 1px solid #0097A7 !important; }

.border-bottom-cyan.border-bottom-darken-2 {
  border-bottom: 1px solid #0097A7 !important; }

.border-left-cyan.border-left-darken-2 {
  border-left: 1px solid #0097A7 !important; }

.border-right-cyan.border-right-darken-2 {
  border-right: 1px solid #0097A7 !important; }

.overlay-cyan.overlay-darken-2 {
  background: #0097A7;
  /* The Fallback */
  background: rgba(0, 151, 167, 0.8); }

.cyan.darken-3 {
  color: #00838F !important; }

.bg-cyan.bg-darken-3 {
  background-color: #00838F !important; }

.btn-cyan.btn-darken-3 {
  border-color: #0097A7 !important;
  background-color: #00838F !important; }
  .btn-cyan.btn-darken-3:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-darken-3:focus, .btn-cyan.btn-darken-3:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-darken-3 {
  border-color: #00838F !important;
  color: #00838F !important; }
  .btn-outline-cyan.btn-outline-darken-3:hover {
    background-color: #00838F !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00838F !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00838F !important; }

.border-cyan.border-darken-3 {
  border: 1px solid #00838F !important; }

.border-top-cyan.border-top-darken-3 {
  border-top: 1px solid #00838F !important; }

.border-bottom-cyan.border-bottom-darken-3 {
  border-bottom: 1px solid #00838F !important; }

.border-left-cyan.border-left-darken-3 {
  border-left: 1px solid #00838F !important; }

.border-right-cyan.border-right-darken-3 {
  border-right: 1px solid #00838F !important; }

.overlay-cyan.overlay-darken-3 {
  background: #00838F;
  /* The Fallback */
  background: rgba(0, 131, 143, 0.8); }

.cyan.darken-4 {
  color: #006064 !important; }

.bg-cyan.bg-darken-4 {
  background-color: #006064 !important; }

.btn-cyan.btn-darken-4 {
  border-color: #0097A7 !important;
  background-color: #006064 !important; }
  .btn-cyan.btn-darken-4:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-darken-4:focus, .btn-cyan.btn-darken-4:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-darken-4 {
  border-color: #006064 !important;
  color: #006064 !important; }
  .btn-outline-cyan.btn-outline-darken-4:hover {
    background-color: #006064 !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #006064 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #006064 !important; }

.border-cyan.border-darken-4 {
  border: 1px solid #006064 !important; }

.border-top-cyan.border-top-darken-4 {
  border-top: 1px solid #006064 !important; }

.border-bottom-cyan.border-bottom-darken-4 {
  border-bottom: 1px solid #006064 !important; }

.border-left-cyan.border-left-darken-4 {
  border-left: 1px solid #006064 !important; }

.border-right-cyan.border-right-darken-4 {
  border-right: 1px solid #006064 !important; }

.overlay-cyan.overlay-darken-4 {
  background: #006064;
  /* The Fallback */
  background: rgba(0, 96, 100, 0.8); }

.cyan.accent-1 {
  color: #84FFFF !important; }

.bg-cyan.bg-accent-1 {
  background-color: #84FFFF !important; }

.btn-cyan.btn-accent-1 {
  border-color: #0097A7 !important;
  background-color: #84FFFF !important; }
  .btn-cyan.btn-accent-1:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-accent-1:focus, .btn-cyan.btn-accent-1:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-accent-1 {
  border-color: #84FFFF !important;
  color: #84FFFF !important; }
  .btn-outline-cyan.btn-outline-accent-1:hover {
    background-color: #84FFFF !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #84FFFF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #84FFFF !important; }

.border-cyan.border-accent-1 {
  border: 1px solid #84FFFF !important; }

.border-top-cyan.border-top-accent-1 {
  border-top: 1px solid #84FFFF !important; }

.border-bottom-cyan.border-bottom-accent-1 {
  border-bottom: 1px solid #84FFFF !important; }

.border-left-cyan.border-left-accent-1 {
  border-left: 1px solid #84FFFF !important; }

.border-right-cyan.border-right-accent-1 {
  border-right: 1px solid #84FFFF !important; }

.overlay-cyan.overlay-accent-1 {
  background: #84FFFF;
  /* The Fallback */
  background: rgba(132, 255, 255, 0.8); }

.cyan.accent-2 {
  color: #18FFFF !important; }

.bg-cyan.bg-accent-2 {
  background-color: #18FFFF !important; }

.btn-cyan.btn-accent-2 {
  border-color: #0097A7 !important;
  background-color: #18FFFF !important; }
  .btn-cyan.btn-accent-2:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-accent-2:focus, .btn-cyan.btn-accent-2:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-accent-2 {
  border-color: #18FFFF !important;
  color: #18FFFF !important; }
  .btn-outline-cyan.btn-outline-accent-2:hover {
    background-color: #18FFFF !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #18FFFF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #18FFFF !important; }

.border-cyan.border-accent-2 {
  border: 1px solid #18FFFF !important; }

.border-top-cyan.border-top-accent-2 {
  border-top: 1px solid #18FFFF !important; }

.border-bottom-cyan.border-bottom-accent-2 {
  border-bottom: 1px solid #18FFFF !important; }

.border-left-cyan.border-left-accent-2 {
  border-left: 1px solid #18FFFF !important; }

.border-right-cyan.border-right-accent-2 {
  border-right: 1px solid #18FFFF !important; }

.overlay-cyan.overlay-accent-2 {
  background: #18FFFF;
  /* The Fallback */
  background: rgba(24, 255, 255, 0.8); }

.cyan.accent-3 {
  color: #00E5FF !important; }

.bg-cyan.bg-accent-3 {
  background-color: #00E5FF !important; }

.btn-cyan.btn-accent-3 {
  border-color: #0097A7 !important;
  background-color: #00E5FF !important; }
  .btn-cyan.btn-accent-3:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-accent-3:focus, .btn-cyan.btn-accent-3:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-accent-3 {
  border-color: #00E5FF !important;
  color: #00E5FF !important; }
  .btn-outline-cyan.btn-outline-accent-3:hover {
    background-color: #00E5FF !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00E5FF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00E5FF !important; }

.border-cyan.border-accent-3 {
  border: 1px solid #00E5FF !important; }

.border-top-cyan.border-top-accent-3 {
  border-top: 1px solid #00E5FF !important; }

.border-bottom-cyan.border-bottom-accent-3 {
  border-bottom: 1px solid #00E5FF !important; }

.border-left-cyan.border-left-accent-3 {
  border-left: 1px solid #00E5FF !important; }

.border-right-cyan.border-right-accent-3 {
  border-right: 1px solid #00E5FF !important; }

.overlay-cyan.overlay-accent-3 {
  background: #00E5FF;
  /* The Fallback */
  background: rgba(0, 229, 255, 0.8); }

.cyan.accent-4 {
  color: #00B8D4 !important; }

.bg-cyan.bg-accent-4 {
  background-color: #00B8D4 !important; }

.btn-cyan.btn-accent-4 {
  border-color: #0097A7 !important;
  background-color: #00B8D4 !important; }
  .btn-cyan.btn-accent-4:hover {
    border-color: #0097A7 !important;
    background-color: #00838F !important; }
  .btn-cyan.btn-accent-4:focus, .btn-cyan.btn-accent-4:active {
    border-color: #00838F !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-accent-4 {
  border-color: #00B8D4 !important;
  color: #00B8D4 !important; }
  .btn-outline-cyan.btn-outline-accent-4:hover {
    background-color: #00B8D4 !important; }

input:focus ~ .bg-cyan {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00B8D4 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00B8D4 !important; }

.border-cyan.border-accent-4 {
  border: 1px solid #00B8D4 !important; }

.border-top-cyan.border-top-accent-4 {
  border-top: 1px solid #00B8D4 !important; }

.border-bottom-cyan.border-bottom-accent-4 {
  border-bottom: 1px solid #00B8D4 !important; }

.border-left-cyan.border-left-accent-4 {
  border-left: 1px solid #00B8D4 !important; }

.border-right-cyan.border-right-accent-4 {
  border-right: 1px solid #00B8D4 !important; }

.overlay-cyan.overlay-accent-4 {
  background: #00B8D4;
  /* The Fallback */
  background: rgba(0, 184, 212, 0.8); }

.teal.lighten-5 {
  color: #E0F2F1 !important; }

.bg-teal.bg-lighten-5 {
  background-color: #E0F2F1 !important; }

.btn-teal.btn-lighten-5 {
  border-color: #00796B !important;
  background-color: #E0F2F1 !important; }
  .btn-teal.btn-lighten-5:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-lighten-5:focus, .btn-teal.btn-lighten-5:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-lighten-5 {
  border-color: #E0F2F1 !important;
  color: #E0F2F1 !important; }
  .btn-outline-teal.btn-outline-lighten-5:hover {
    background-color: #E0F2F1 !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E0F2F1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E0F2F1 !important; }

.border-teal.border-lighten-5 {
  border: 1px solid #E0F2F1 !important; }

.border-top-teal.border-top-lighten-5 {
  border-top: 1px solid #E0F2F1 !important; }

.border-bottom-teal.border-bottom-lighten-5 {
  border-bottom: 1px solid #E0F2F1 !important; }

.border-left-teal.border-left-lighten-5 {
  border-left: 1px solid #E0F2F1 !important; }

.border-right-teal.border-right-lighten-5 {
  border-right: 1px solid #E0F2F1 !important; }

.overlay-teal.overlay-lighten-5 {
  background: #E0F2F1;
  /* The Fallback */
  background: rgba(224, 242, 241, 0.8); }

.teal.lighten-4 {
  color: #B2DFDB !important; }

.bg-teal.bg-lighten-4 {
  background-color: #B2DFDB !important; }

.btn-teal.btn-lighten-4 {
  border-color: #00796B !important;
  background-color: #B2DFDB !important; }
  .btn-teal.btn-lighten-4:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-lighten-4:focus, .btn-teal.btn-lighten-4:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-lighten-4 {
  border-color: #B2DFDB !important;
  color: #B2DFDB !important; }
  .btn-outline-teal.btn-outline-lighten-4:hover {
    background-color: #B2DFDB !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B2DFDB !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B2DFDB !important; }

.border-teal.border-lighten-4 {
  border: 1px solid #B2DFDB !important; }

.border-top-teal.border-top-lighten-4 {
  border-top: 1px solid #B2DFDB !important; }

.border-bottom-teal.border-bottom-lighten-4 {
  border-bottom: 1px solid #B2DFDB !important; }

.border-left-teal.border-left-lighten-4 {
  border-left: 1px solid #B2DFDB !important; }

.border-right-teal.border-right-lighten-4 {
  border-right: 1px solid #B2DFDB !important; }

.overlay-teal.overlay-lighten-4 {
  background: #B2DFDB;
  /* The Fallback */
  background: rgba(178, 223, 219, 0.8); }

.teal.lighten-3 {
  color: #80CBC4 !important; }

.bg-teal.bg-lighten-3 {
  background-color: #80CBC4 !important; }

.btn-teal.btn-lighten-3 {
  border-color: #00796B !important;
  background-color: #80CBC4 !important; }
  .btn-teal.btn-lighten-3:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-lighten-3:focus, .btn-teal.btn-lighten-3:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-lighten-3 {
  border-color: #80CBC4 !important;
  color: #80CBC4 !important; }
  .btn-outline-teal.btn-outline-lighten-3:hover {
    background-color: #80CBC4 !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80CBC4 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80CBC4 !important; }

.border-teal.border-lighten-3 {
  border: 1px solid #80CBC4 !important; }

.border-top-teal.border-top-lighten-3 {
  border-top: 1px solid #80CBC4 !important; }

.border-bottom-teal.border-bottom-lighten-3 {
  border-bottom: 1px solid #80CBC4 !important; }

.border-left-teal.border-left-lighten-3 {
  border-left: 1px solid #80CBC4 !important; }

.border-right-teal.border-right-lighten-3 {
  border-right: 1px solid #80CBC4 !important; }

.overlay-teal.overlay-lighten-3 {
  background: #80CBC4;
  /* The Fallback */
  background: rgba(128, 203, 196, 0.8); }

.teal.lighten-2 {
  color: #4DB6AC !important; }

.bg-teal.bg-lighten-2 {
  background-color: #4DB6AC !important; }

.btn-teal.btn-lighten-2 {
  border-color: #00796B !important;
  background-color: #4DB6AC !important; }
  .btn-teal.btn-lighten-2:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-lighten-2:focus, .btn-teal.btn-lighten-2:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-lighten-2 {
  border-color: #4DB6AC !important;
  color: #4DB6AC !important; }
  .btn-outline-teal.btn-outline-lighten-2:hover {
    background-color: #4DB6AC !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4DB6AC !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4DB6AC !important; }

.border-teal.border-lighten-2 {
  border: 1px solid #4DB6AC !important; }

.border-top-teal.border-top-lighten-2 {
  border-top: 1px solid #4DB6AC !important; }

.border-bottom-teal.border-bottom-lighten-2 {
  border-bottom: 1px solid #4DB6AC !important; }

.border-left-teal.border-left-lighten-2 {
  border-left: 1px solid #4DB6AC !important; }

.border-right-teal.border-right-lighten-2 {
  border-right: 1px solid #4DB6AC !important; }

.overlay-teal.overlay-lighten-2 {
  background: #4DB6AC;
  /* The Fallback */
  background: rgba(77, 182, 172, 0.8); }

.teal.lighten-1 {
  color: #26A69A !important; }

.bg-teal.bg-lighten-1 {
  background-color: #26A69A !important; }

.btn-teal.btn-lighten-1 {
  border-color: #00796B !important;
  background-color: #26A69A !important; }
  .btn-teal.btn-lighten-1:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-lighten-1:focus, .btn-teal.btn-lighten-1:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-lighten-1 {
  border-color: #26A69A !important;
  color: #26A69A !important; }
  .btn-outline-teal.btn-outline-lighten-1:hover {
    background-color: #26A69A !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26A69A !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26A69A !important; }

.border-teal.border-lighten-1 {
  border: 1px solid #26A69A !important; }

.border-top-teal.border-top-lighten-1 {
  border-top: 1px solid #26A69A !important; }

.border-bottom-teal.border-bottom-lighten-1 {
  border-bottom: 1px solid #26A69A !important; }

.border-left-teal.border-left-lighten-1 {
  border-left: 1px solid #26A69A !important; }

.border-right-teal.border-right-lighten-1 {
  border-right: 1px solid #26A69A !important; }

.overlay-teal.overlay-lighten-1 {
  background: #26A69A;
  /* The Fallback */
  background: rgba(38, 166, 154, 0.8); }

.teal {
  color: #009688 !important; }

.bg-teal {
  background-color: #009688 !important; }
  .bg-teal .card-header, .bg-teal .card-footer {
    background-color: transparent; }

.alert-teal {
  border-color: #009688 !important;
  background-color: #00ddc9 !important;
  color: black !important; }
  .alert-teal .alert-link {
    color: black !important; }

.border-teal {
  border-color: #009688; }

.bg-teal.badge-glow, .border-teal.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #009688;
  box-shadow: 0px 0px 10px #009688; }

.overlay-teal {
  background: #009688;
  /* The Fallback */
  background: rgba(0, 150, 136, 0.8); }

/* .card-outline-teal{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-teal {
  border-color: #00796B !important;
  background-color: #009688 !important;
  color: #FFFFFF; }
  .btn-teal:hover {
    border-color: #00897B !important;
    background-color: #26A69A !important;
    color: #FFF !important; }
  .btn-teal:focus, .btn-teal:active {
    border-color: #00796B !important;
    background-color: #00695C !important;
    color: #FFF !important; }

.btn-outline-teal {
  border-color: #009688;
  background-color: transparent;
  color: #009688; }
  .btn-outline-teal:hover {
    background-color: #009688;
    color: #FFF !important; }

input[type="checkbox"].bg-teal + .custom-control-label:before, input[type="radio"].bg-teal + .custom-control-label:before {
  background-color: #009688 !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009688 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009688 !important; }

.border-teal {
  border: 1px solid #009688 !important; }

.border-top-teal {
  border-top: 1px solid #009688; }

.border-bottom-teal {
  border-bottom: 1px solid #009688; }

.border-left-teal {
  border-left: 1px solid #009688; }

.border-right-teal {
  border-right: 1px solid #009688; }

.teal.darken-1 {
  color: #00897B !important; }

.bg-teal.bg-darken-1 {
  background-color: #00897B !important; }

.btn-teal.btn-darken-1 {
  border-color: #00796B !important;
  background-color: #00897B !important; }
  .btn-teal.btn-darken-1:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-darken-1:focus, .btn-teal.btn-darken-1:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-darken-1 {
  border-color: #00897B !important;
  color: #00897B !important; }
  .btn-outline-teal.btn-outline-darken-1:hover {
    background-color: #00897B !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00897B !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00897B !important; }

.border-teal.border-darken-1 {
  border: 1px solid #00897B !important; }

.border-top-teal.border-top-darken-1 {
  border-top: 1px solid #00897B !important; }

.border-bottom-teal.border-bottom-darken-1 {
  border-bottom: 1px solid #00897B !important; }

.border-left-teal.border-left-darken-1 {
  border-left: 1px solid #00897B !important; }

.border-right-teal.border-right-darken-1 {
  border-right: 1px solid #00897B !important; }

.overlay-teal.overlay-darken-1 {
  background: #00897B;
  /* The Fallback */
  background: rgba(0, 137, 123, 0.8); }

.teal.darken-2 {
  color: #00796B !important; }

.bg-teal.bg-darken-2 {
  background-color: #00796B !important; }

.btn-teal.btn-darken-2 {
  border-color: #00796B !important;
  background-color: #00796B !important; }
  .btn-teal.btn-darken-2:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-darken-2:focus, .btn-teal.btn-darken-2:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-darken-2 {
  border-color: #00796B !important;
  color: #00796B !important; }
  .btn-outline-teal.btn-outline-darken-2:hover {
    background-color: #00796B !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00796B !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00796B !important; }

.border-teal.border-darken-2 {
  border: 1px solid #00796B !important; }

.border-top-teal.border-top-darken-2 {
  border-top: 1px solid #00796B !important; }

.border-bottom-teal.border-bottom-darken-2 {
  border-bottom: 1px solid #00796B !important; }

.border-left-teal.border-left-darken-2 {
  border-left: 1px solid #00796B !important; }

.border-right-teal.border-right-darken-2 {
  border-right: 1px solid #00796B !important; }

.overlay-teal.overlay-darken-2 {
  background: #00796B;
  /* The Fallback */
  background: rgba(0, 121, 107, 0.8); }

.teal.darken-3 {
  color: #00695C !important; }

.bg-teal.bg-darken-3 {
  background-color: #00695C !important; }

.btn-teal.btn-darken-3 {
  border-color: #00796B !important;
  background-color: #00695C !important; }
  .btn-teal.btn-darken-3:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-darken-3:focus, .btn-teal.btn-darken-3:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-darken-3 {
  border-color: #00695C !important;
  color: #00695C !important; }
  .btn-outline-teal.btn-outline-darken-3:hover {
    background-color: #00695C !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00695C !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00695C !important; }

.border-teal.border-darken-3 {
  border: 1px solid #00695C !important; }

.border-top-teal.border-top-darken-3 {
  border-top: 1px solid #00695C !important; }

.border-bottom-teal.border-bottom-darken-3 {
  border-bottom: 1px solid #00695C !important; }

.border-left-teal.border-left-darken-3 {
  border-left: 1px solid #00695C !important; }

.border-right-teal.border-right-darken-3 {
  border-right: 1px solid #00695C !important; }

.overlay-teal.overlay-darken-3 {
  background: #00695C;
  /* The Fallback */
  background: rgba(0, 105, 92, 0.8); }

.teal.darken-4 {
  color: #004D40 !important; }

.bg-teal.bg-darken-4 {
  background-color: #004D40 !important; }

.btn-teal.btn-darken-4 {
  border-color: #00796B !important;
  background-color: #004D40 !important; }
  .btn-teal.btn-darken-4:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-darken-4:focus, .btn-teal.btn-darken-4:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-darken-4 {
  border-color: #004D40 !important;
  color: #004D40 !important; }
  .btn-outline-teal.btn-outline-darken-4:hover {
    background-color: #004D40 !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #004D40 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #004D40 !important; }

.border-teal.border-darken-4 {
  border: 1px solid #004D40 !important; }

.border-top-teal.border-top-darken-4 {
  border-top: 1px solid #004D40 !important; }

.border-bottom-teal.border-bottom-darken-4 {
  border-bottom: 1px solid #004D40 !important; }

.border-left-teal.border-left-darken-4 {
  border-left: 1px solid #004D40 !important; }

.border-right-teal.border-right-darken-4 {
  border-right: 1px solid #004D40 !important; }

.overlay-teal.overlay-darken-4 {
  background: #004D40;
  /* The Fallback */
  background: rgba(0, 77, 64, 0.8); }

.teal.accent-1 {
  color: #A7FFEB !important; }

.bg-teal.bg-accent-1 {
  background-color: #A7FFEB !important; }

.btn-teal.btn-accent-1 {
  border-color: #00796B !important;
  background-color: #A7FFEB !important; }
  .btn-teal.btn-accent-1:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-accent-1:focus, .btn-teal.btn-accent-1:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-accent-1 {
  border-color: #A7FFEB !important;
  color: #A7FFEB !important; }
  .btn-outline-teal.btn-outline-accent-1:hover {
    background-color: #A7FFEB !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #A7FFEB !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #A7FFEB !important; }

.border-teal.border-accent-1 {
  border: 1px solid #A7FFEB !important; }

.border-top-teal.border-top-accent-1 {
  border-top: 1px solid #A7FFEB !important; }

.border-bottom-teal.border-bottom-accent-1 {
  border-bottom: 1px solid #A7FFEB !important; }

.border-left-teal.border-left-accent-1 {
  border-left: 1px solid #A7FFEB !important; }

.border-right-teal.border-right-accent-1 {
  border-right: 1px solid #A7FFEB !important; }

.overlay-teal.overlay-accent-1 {
  background: #A7FFEB;
  /* The Fallback */
  background: rgba(167, 255, 235, 0.8); }

.teal.accent-2 {
  color: #64FFDA !important; }

.bg-teal.bg-accent-2 {
  background-color: #64FFDA !important; }

.btn-teal.btn-accent-2 {
  border-color: #00796B !important;
  background-color: #64FFDA !important; }
  .btn-teal.btn-accent-2:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-accent-2:focus, .btn-teal.btn-accent-2:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-accent-2 {
  border-color: #64FFDA !important;
  color: #64FFDA !important; }
  .btn-outline-teal.btn-outline-accent-2:hover {
    background-color: #64FFDA !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64FFDA !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64FFDA !important; }

.border-teal.border-accent-2 {
  border: 1px solid #64FFDA !important; }

.border-top-teal.border-top-accent-2 {
  border-top: 1px solid #64FFDA !important; }

.border-bottom-teal.border-bottom-accent-2 {
  border-bottom: 1px solid #64FFDA !important; }

.border-left-teal.border-left-accent-2 {
  border-left: 1px solid #64FFDA !important; }

.border-right-teal.border-right-accent-2 {
  border-right: 1px solid #64FFDA !important; }

.overlay-teal.overlay-accent-2 {
  background: #64FFDA;
  /* The Fallback */
  background: rgba(100, 255, 218, 0.8); }

.teal.accent-3 {
  color: #1DE9B6 !important; }

.bg-teal.bg-accent-3 {
  background-color: #1DE9B6 !important; }

.btn-teal.btn-accent-3 {
  border-color: #00796B !important;
  background-color: #1DE9B6 !important; }
  .btn-teal.btn-accent-3:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-accent-3:focus, .btn-teal.btn-accent-3:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-accent-3 {
  border-color: #1DE9B6 !important;
  color: #1DE9B6 !important; }
  .btn-outline-teal.btn-outline-accent-3:hover {
    background-color: #1DE9B6 !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1DE9B6 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1DE9B6 !important; }

.border-teal.border-accent-3 {
  border: 1px solid #1DE9B6 !important; }

.border-top-teal.border-top-accent-3 {
  border-top: 1px solid #1DE9B6 !important; }

.border-bottom-teal.border-bottom-accent-3 {
  border-bottom: 1px solid #1DE9B6 !important; }

.border-left-teal.border-left-accent-3 {
  border-left: 1px solid #1DE9B6 !important; }

.border-right-teal.border-right-accent-3 {
  border-right: 1px solid #1DE9B6 !important; }

.overlay-teal.overlay-accent-3 {
  background: #1DE9B6;
  /* The Fallback */
  background: rgba(29, 233, 182, 0.8); }

.teal.accent-4 {
  color: #00BFA5 !important; }

.bg-teal.bg-accent-4 {
  background-color: #00BFA5 !important; }

.btn-teal.btn-accent-4 {
  border-color: #00796B !important;
  background-color: #00BFA5 !important; }
  .btn-teal.btn-accent-4:hover {
    border-color: #00796B !important;
    background-color: #00695C !important; }
  .btn-teal.btn-accent-4:focus, .btn-teal.btn-accent-4:active {
    border-color: #00695C !important;
    background-color: #004D40 !important; }

.btn-outline-teal.btn-outline-accent-4 {
  border-color: #00BFA5 !important;
  color: #00BFA5 !important; }
  .btn-outline-teal.btn-outline-accent-4:hover {
    background-color: #00BFA5 !important; }

input:focus ~ .bg-teal {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00BFA5 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00BFA5 !important; }

.border-teal.border-accent-4 {
  border: 1px solid #00BFA5 !important; }

.border-top-teal.border-top-accent-4 {
  border-top: 1px solid #00BFA5 !important; }

.border-bottom-teal.border-bottom-accent-4 {
  border-bottom: 1px solid #00BFA5 !important; }

.border-left-teal.border-left-accent-4 {
  border-left: 1px solid #00BFA5 !important; }

.border-right-teal.border-right-accent-4 {
  border-right: 1px solid #00BFA5 !important; }

.overlay-teal.overlay-accent-4 {
  background: #00BFA5;
  /* The Fallback */
  background: rgba(0, 191, 165, 0.8); }

.yellow.lighten-5 {
  color: #FFFDE7 !important; }

.bg-yellow.bg-lighten-5 {
  background-color: #FFFDE7 !important; }

.btn-yellow.btn-lighten-5 {
  border-color: #FBC02D !important;
  background-color: #FFFDE7 !important; }
  .btn-yellow.btn-lighten-5:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-lighten-5:focus, .btn-yellow.btn-lighten-5:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-lighten-5 {
  border-color: #FFFDE7 !important;
  color: #FFFDE7 !important; }
  .btn-outline-yellow.btn-outline-lighten-5:hover {
    background-color: #FFFDE7 !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFDE7 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFDE7 !important; }

.border-yellow.border-lighten-5 {
  border: 1px solid #FFFDE7 !important; }

.border-top-yellow.border-top-lighten-5 {
  border-top: 1px solid #FFFDE7 !important; }

.border-bottom-yellow.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFFDE7 !important; }

.border-left-yellow.border-left-lighten-5 {
  border-left: 1px solid #FFFDE7 !important; }

.border-right-yellow.border-right-lighten-5 {
  border-right: 1px solid #FFFDE7 !important; }

.overlay-yellow.overlay-lighten-5 {
  background: #FFFDE7;
  /* The Fallback */
  background: rgba(255, 253, 231, 0.8); }

.yellow.lighten-4 {
  color: #FFF9C4 !important; }

.bg-yellow.bg-lighten-4 {
  background-color: #FFF9C4 !important; }

.btn-yellow.btn-lighten-4 {
  border-color: #FBC02D !important;
  background-color: #FFF9C4 !important; }
  .btn-yellow.btn-lighten-4:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-lighten-4:focus, .btn-yellow.btn-lighten-4:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-lighten-4 {
  border-color: #FFF9C4 !important;
  color: #FFF9C4 !important; }
  .btn-outline-yellow.btn-outline-lighten-4:hover {
    background-color: #FFF9C4 !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF9C4 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF9C4 !important; }

.border-yellow.border-lighten-4 {
  border: 1px solid #FFF9C4 !important; }

.border-top-yellow.border-top-lighten-4 {
  border-top: 1px solid #FFF9C4 !important; }

.border-bottom-yellow.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFF9C4 !important; }

.border-left-yellow.border-left-lighten-4 {
  border-left: 1px solid #FFF9C4 !important; }

.border-right-yellow.border-right-lighten-4 {
  border-right: 1px solid #FFF9C4 !important; }

.overlay-yellow.overlay-lighten-4 {
  background: #FFF9C4;
  /* The Fallback */
  background: rgba(255, 249, 196, 0.8); }

.yellow.lighten-3 {
  color: #FFF59D !important; }

.bg-yellow.bg-lighten-3 {
  background-color: #FFF59D !important; }

.btn-yellow.btn-lighten-3 {
  border-color: #FBC02D !important;
  background-color: #FFF59D !important; }
  .btn-yellow.btn-lighten-3:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-lighten-3:focus, .btn-yellow.btn-lighten-3:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-lighten-3 {
  border-color: #FFF59D !important;
  color: #FFF59D !important; }
  .btn-outline-yellow.btn-outline-lighten-3:hover {
    background-color: #FFF59D !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF59D !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF59D !important; }

.border-yellow.border-lighten-3 {
  border: 1px solid #FFF59D !important; }

.border-top-yellow.border-top-lighten-3 {
  border-top: 1px solid #FFF59D !important; }

.border-bottom-yellow.border-bottom-lighten-3 {
  border-bottom: 1px solid #FFF59D !important; }

.border-left-yellow.border-left-lighten-3 {
  border-left: 1px solid #FFF59D !important; }

.border-right-yellow.border-right-lighten-3 {
  border-right: 1px solid #FFF59D !important; }

.overlay-yellow.overlay-lighten-3 {
  background: #FFF59D;
  /* The Fallback */
  background: rgba(255, 245, 157, 0.8); }

.yellow.lighten-2 {
  color: #FFF176 !important; }

.bg-yellow.bg-lighten-2 {
  background-color: #FFF176 !important; }

.btn-yellow.btn-lighten-2 {
  border-color: #FBC02D !important;
  background-color: #FFF176 !important; }
  .btn-yellow.btn-lighten-2:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-lighten-2:focus, .btn-yellow.btn-lighten-2:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-lighten-2 {
  border-color: #FFF176 !important;
  color: #FFF176 !important; }
  .btn-outline-yellow.btn-outline-lighten-2:hover {
    background-color: #FFF176 !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF176 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF176 !important; }

.border-yellow.border-lighten-2 {
  border: 1px solid #FFF176 !important; }

.border-top-yellow.border-top-lighten-2 {
  border-top: 1px solid #FFF176 !important; }

.border-bottom-yellow.border-bottom-lighten-2 {
  border-bottom: 1px solid #FFF176 !important; }

.border-left-yellow.border-left-lighten-2 {
  border-left: 1px solid #FFF176 !important; }

.border-right-yellow.border-right-lighten-2 {
  border-right: 1px solid #FFF176 !important; }

.overlay-yellow.overlay-lighten-2 {
  background: #FFF176;
  /* The Fallback */
  background: rgba(255, 241, 118, 0.8); }

.yellow.lighten-1 {
  color: #FFEE58 !important; }

.bg-yellow.bg-lighten-1 {
  background-color: #FFEE58 !important; }

.btn-yellow.btn-lighten-1 {
  border-color: #FBC02D !important;
  background-color: #FFEE58 !important; }
  .btn-yellow.btn-lighten-1:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-lighten-1:focus, .btn-yellow.btn-lighten-1:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-lighten-1 {
  border-color: #FFEE58 !important;
  color: #FFEE58 !important; }
  .btn-outline-yellow.btn-outline-lighten-1:hover {
    background-color: #FFEE58 !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEE58 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEE58 !important; }

.border-yellow.border-lighten-1 {
  border: 1px solid #FFEE58 !important; }

.border-top-yellow.border-top-lighten-1 {
  border-top: 1px solid #FFEE58 !important; }

.border-bottom-yellow.border-bottom-lighten-1 {
  border-bottom: 1px solid #FFEE58 !important; }

.border-left-yellow.border-left-lighten-1 {
  border-left: 1px solid #FFEE58 !important; }

.border-right-yellow.border-right-lighten-1 {
  border-right: 1px solid #FFEE58 !important; }

.overlay-yellow.overlay-lighten-1 {
  background: #FFEE58;
  /* The Fallback */
  background: rgba(255, 238, 88, 0.8); }

.yellow {
  color: #FFEB3B !important; }

.bg-yellow {
  background-color: #FFEB3B !important; }
  .bg-yellow .card-header, .bg-yellow .card-footer {
    background-color: transparent; }

.alert-yellow {
  border-color: #FFEB3B !important;
  background-color: #fff282 !important;
  color: #887a00 !important; }
  .alert-yellow .alert-link {
    color: #5f5500 !important; }

.border-yellow {
  border-color: #FFEB3B; }

.bg-yellow.badge-glow, .border-yellow.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #FFEB3B;
  box-shadow: 0px 0px 10px #FFEB3B; }

.overlay-yellow {
  background: #FFEB3B;
  /* The Fallback */
  background: rgba(255, 235, 59, 0.8); }

/* .card-outline-yellow{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-yellow {
  border-color: #FBC02D !important;
  background-color: #FFEB3B !important;
  color: #FFFFFF; }
  .btn-yellow:hover {
    border-color: #FDD835 !important;
    background-color: #FFEE58 !important;
    color: #FFF !important; }
  .btn-yellow:focus, .btn-yellow:active {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important;
    color: #FFF !important; }

.btn-outline-yellow {
  border-color: #FFEB3B;
  background-color: transparent;
  color: #FFEB3B; }
  .btn-outline-yellow:hover {
    background-color: #FFEB3B;
    color: #FFF !important; }

input[type="checkbox"].bg-yellow + .custom-control-label:before, input[type="radio"].bg-yellow + .custom-control-label:before {
  background-color: #FFEB3B !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEB3B !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEB3B !important; }

.border-yellow {
  border: 1px solid #FFEB3B !important; }

.border-top-yellow {
  border-top: 1px solid #FFEB3B; }

.border-bottom-yellow {
  border-bottom: 1px solid #FFEB3B; }

.border-left-yellow {
  border-left: 1px solid #FFEB3B; }

.border-right-yellow {
  border-right: 1px solid #FFEB3B; }

.yellow.darken-1 {
  color: #FDD835 !important; }

.bg-yellow.bg-darken-1 {
  background-color: #FDD835 !important; }

.btn-yellow.btn-darken-1 {
  border-color: #FBC02D !important;
  background-color: #FDD835 !important; }
  .btn-yellow.btn-darken-1:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-darken-1:focus, .btn-yellow.btn-darken-1:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-darken-1 {
  border-color: #FDD835 !important;
  color: #FDD835 !important; }
  .btn-outline-yellow.btn-outline-darken-1:hover {
    background-color: #FDD835 !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FDD835 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FDD835 !important; }

.border-yellow.border-darken-1 {
  border: 1px solid #FDD835 !important; }

.border-top-yellow.border-top-darken-1 {
  border-top: 1px solid #FDD835 !important; }

.border-bottom-yellow.border-bottom-darken-1 {
  border-bottom: 1px solid #FDD835 !important; }

.border-left-yellow.border-left-darken-1 {
  border-left: 1px solid #FDD835 !important; }

.border-right-yellow.border-right-darken-1 {
  border-right: 1px solid #FDD835 !important; }

.overlay-yellow.overlay-darken-1 {
  background: #FDD835;
  /* The Fallback */
  background: rgba(253, 216, 53, 0.8); }

.yellow.darken-2 {
  color: #FBC02D !important; }

.bg-yellow.bg-darken-2 {
  background-color: #FBC02D !important; }

.btn-yellow.btn-darken-2 {
  border-color: #FBC02D !important;
  background-color: #FBC02D !important; }
  .btn-yellow.btn-darken-2:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-darken-2:focus, .btn-yellow.btn-darken-2:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-darken-2 {
  border-color: #FBC02D !important;
  color: #FBC02D !important; }
  .btn-outline-yellow.btn-outline-darken-2:hover {
    background-color: #FBC02D !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FBC02D !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FBC02D !important; }

.border-yellow.border-darken-2 {
  border: 1px solid #FBC02D !important; }

.border-top-yellow.border-top-darken-2 {
  border-top: 1px solid #FBC02D !important; }

.border-bottom-yellow.border-bottom-darken-2 {
  border-bottom: 1px solid #FBC02D !important; }

.border-left-yellow.border-left-darken-2 {
  border-left: 1px solid #FBC02D !important; }

.border-right-yellow.border-right-darken-2 {
  border-right: 1px solid #FBC02D !important; }

.overlay-yellow.overlay-darken-2 {
  background: #FBC02D;
  /* The Fallback */
  background: rgba(251, 192, 45, 0.8); }

.yellow.darken-3 {
  color: #F9A825 !important; }

.bg-yellow.bg-darken-3 {
  background-color: #F9A825 !important; }

.btn-yellow.btn-darken-3 {
  border-color: #FBC02D !important;
  background-color: #F9A825 !important; }
  .btn-yellow.btn-darken-3:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-darken-3:focus, .btn-yellow.btn-darken-3:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-darken-3 {
  border-color: #F9A825 !important;
  color: #F9A825 !important; }
  .btn-outline-yellow.btn-outline-darken-3:hover {
    background-color: #F9A825 !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F9A825 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F9A825 !important; }

.border-yellow.border-darken-3 {
  border: 1px solid #F9A825 !important; }

.border-top-yellow.border-top-darken-3 {
  border-top: 1px solid #F9A825 !important; }

.border-bottom-yellow.border-bottom-darken-3 {
  border-bottom: 1px solid #F9A825 !important; }

.border-left-yellow.border-left-darken-3 {
  border-left: 1px solid #F9A825 !important; }

.border-right-yellow.border-right-darken-3 {
  border-right: 1px solid #F9A825 !important; }

.overlay-yellow.overlay-darken-3 {
  background: #F9A825;
  /* The Fallback */
  background: rgba(249, 168, 37, 0.8); }

.yellow.darken-4 {
  color: #F57F17 !important; }

.bg-yellow.bg-darken-4 {
  background-color: #F57F17 !important; }

.btn-yellow.btn-darken-4 {
  border-color: #FBC02D !important;
  background-color: #F57F17 !important; }
  .btn-yellow.btn-darken-4:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-darken-4:focus, .btn-yellow.btn-darken-4:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-darken-4 {
  border-color: #F57F17 !important;
  color: #F57F17 !important; }
  .btn-outline-yellow.btn-outline-darken-4:hover {
    background-color: #F57F17 !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F57F17 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #F57F17 !important; }

.border-yellow.border-darken-4 {
  border: 1px solid #F57F17 !important; }

.border-top-yellow.border-top-darken-4 {
  border-top: 1px solid #F57F17 !important; }

.border-bottom-yellow.border-bottom-darken-4 {
  border-bottom: 1px solid #F57F17 !important; }

.border-left-yellow.border-left-darken-4 {
  border-left: 1px solid #F57F17 !important; }

.border-right-yellow.border-right-darken-4 {
  border-right: 1px solid #F57F17 !important; }

.overlay-yellow.overlay-darken-4 {
  background: #F57F17;
  /* The Fallback */
  background: rgba(245, 127, 23, 0.8); }

.yellow.accent-1 {
  color: #FFFF8D !important; }

.bg-yellow.bg-accent-1 {
  background-color: #FFFF8D !important; }

.btn-yellow.btn-accent-1 {
  border-color: #FBC02D !important;
  background-color: #FFFF8D !important; }
  .btn-yellow.btn-accent-1:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-accent-1:focus, .btn-yellow.btn-accent-1:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-accent-1 {
  border-color: #FFFF8D !important;
  color: #FFFF8D !important; }
  .btn-outline-yellow.btn-outline-accent-1:hover {
    background-color: #FFFF8D !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFF8D !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFF8D !important; }

.border-yellow.border-accent-1 {
  border: 1px solid #FFFF8D !important; }

.border-top-yellow.border-top-accent-1 {
  border-top: 1px solid #FFFF8D !important; }

.border-bottom-yellow.border-bottom-accent-1 {
  border-bottom: 1px solid #FFFF8D !important; }

.border-left-yellow.border-left-accent-1 {
  border-left: 1px solid #FFFF8D !important; }

.border-right-yellow.border-right-accent-1 {
  border-right: 1px solid #FFFF8D !important; }

.overlay-yellow.overlay-accent-1 {
  background: #FFFF8D;
  /* The Fallback */
  background: rgba(255, 255, 141, 0.8); }

.yellow.accent-2 {
  color: #FFFF00 !important; }

.bg-yellow.bg-accent-2 {
  background-color: #FFFF00 !important; }

.btn-yellow.btn-accent-2 {
  border-color: #FBC02D !important;
  background-color: #FFFF00 !important; }
  .btn-yellow.btn-accent-2:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-accent-2:focus, .btn-yellow.btn-accent-2:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-accent-2 {
  border-color: #FFFF00 !important;
  color: #FFFF00 !important; }
  .btn-outline-yellow.btn-outline-accent-2:hover {
    background-color: #FFFF00 !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFF00 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFF00 !important; }

.border-yellow.border-accent-2 {
  border: 1px solid #FFFF00 !important; }

.border-top-yellow.border-top-accent-2 {
  border-top: 1px solid #FFFF00 !important; }

.border-bottom-yellow.border-bottom-accent-2 {
  border-bottom: 1px solid #FFFF00 !important; }

.border-left-yellow.border-left-accent-2 {
  border-left: 1px solid #FFFF00 !important; }

.border-right-yellow.border-right-accent-2 {
  border-right: 1px solid #FFFF00 !important; }

.overlay-yellow.overlay-accent-2 {
  background: #FFFF00;
  /* The Fallback */
  background: rgba(255, 255, 0, 0.8); }

.yellow.accent-3 {
  color: #FFEA00 !important; }

.bg-yellow.bg-accent-3 {
  background-color: #FFEA00 !important; }

.btn-yellow.btn-accent-3 {
  border-color: #FBC02D !important;
  background-color: #FFEA00 !important; }
  .btn-yellow.btn-accent-3:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-accent-3:focus, .btn-yellow.btn-accent-3:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-accent-3 {
  border-color: #FFEA00 !important;
  color: #FFEA00 !important; }
  .btn-outline-yellow.btn-outline-accent-3:hover {
    background-color: #FFEA00 !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEA00 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEA00 !important; }

.border-yellow.border-accent-3 {
  border: 1px solid #FFEA00 !important; }

.border-top-yellow.border-top-accent-3 {
  border-top: 1px solid #FFEA00 !important; }

.border-bottom-yellow.border-bottom-accent-3 {
  border-bottom: 1px solid #FFEA00 !important; }

.border-left-yellow.border-left-accent-3 {
  border-left: 1px solid #FFEA00 !important; }

.border-right-yellow.border-right-accent-3 {
  border-right: 1px solid #FFEA00 !important; }

.overlay-yellow.overlay-accent-3 {
  background: #FFEA00;
  /* The Fallback */
  background: rgba(255, 234, 0, 0.8); }

.yellow.accent-4 {
  color: #FFD600 !important; }

.bg-yellow.bg-accent-4 {
  background-color: #FFD600 !important; }

.btn-yellow.btn-accent-4 {
  border-color: #FBC02D !important;
  background-color: #FFD600 !important; }
  .btn-yellow.btn-accent-4:hover {
    border-color: #FBC02D !important;
    background-color: #F9A825 !important; }
  .btn-yellow.btn-accent-4:focus, .btn-yellow.btn-accent-4:active {
    border-color: #F9A825 !important;
    background-color: #F57F17 !important; }

.btn-outline-yellow.btn-outline-accent-4 {
  border-color: #FFD600 !important;
  color: #FFD600 !important; }
  .btn-outline-yellow.btn-outline-accent-4:hover {
    background-color: #FFD600 !important; }

input:focus ~ .bg-yellow {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD600 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD600 !important; }

.border-yellow.border-accent-4 {
  border: 1px solid #FFD600 !important; }

.border-top-yellow.border-top-accent-4 {
  border-top: 1px solid #FFD600 !important; }

.border-bottom-yellow.border-bottom-accent-4 {
  border-bottom: 1px solid #FFD600 !important; }

.border-left-yellow.border-left-accent-4 {
  border-left: 1px solid #FFD600 !important; }

.border-right-yellow.border-right-accent-4 {
  border-right: 1px solid #FFD600 !important; }

.overlay-yellow.overlay-accent-4 {
  background: #FFD600;
  /* The Fallback */
  background: rgba(255, 214, 0, 0.8); }

.amber.lighten-5 {
  color: #FFF8E1 !important; }

.bg-amber.bg-lighten-5 {
  background-color: #FFF8E1 !important; }

.btn-amber.btn-lighten-5 {
  border-color: #FFA000 !important;
  background-color: #FFF8E1 !important; }
  .btn-amber.btn-lighten-5:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-lighten-5:focus, .btn-amber.btn-lighten-5:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-lighten-5 {
  border-color: #FFF8E1 !important;
  color: #FFF8E1 !important; }
  .btn-outline-amber.btn-outline-lighten-5:hover {
    background-color: #FFF8E1 !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF8E1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF8E1 !important; }

.border-amber.border-lighten-5 {
  border: 1px solid #FFF8E1 !important; }

.border-top-amber.border-top-lighten-5 {
  border-top: 1px solid #FFF8E1 !important; }

.border-bottom-amber.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFF8E1 !important; }

.border-left-amber.border-left-lighten-5 {
  border-left: 1px solid #FFF8E1 !important; }

.border-right-amber.border-right-lighten-5 {
  border-right: 1px solid #FFF8E1 !important; }

.overlay-amber.overlay-lighten-5 {
  background: #FFF8E1;
  /* The Fallback */
  background: rgba(255, 248, 225, 0.8); }

.amber.lighten-4 {
  color: #FFECB3 !important; }

.bg-amber.bg-lighten-4 {
  background-color: #FFECB3 !important; }

.btn-amber.btn-lighten-4 {
  border-color: #FFA000 !important;
  background-color: #FFECB3 !important; }
  .btn-amber.btn-lighten-4:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-lighten-4:focus, .btn-amber.btn-lighten-4:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-lighten-4 {
  border-color: #FFECB3 !important;
  color: #FFECB3 !important; }
  .btn-outline-amber.btn-outline-lighten-4:hover {
    background-color: #FFECB3 !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFECB3 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFECB3 !important; }

.border-amber.border-lighten-4 {
  border: 1px solid #FFECB3 !important; }

.border-top-amber.border-top-lighten-4 {
  border-top: 1px solid #FFECB3 !important; }

.border-bottom-amber.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFECB3 !important; }

.border-left-amber.border-left-lighten-4 {
  border-left: 1px solid #FFECB3 !important; }

.border-right-amber.border-right-lighten-4 {
  border-right: 1px solid #FFECB3 !important; }

.overlay-amber.overlay-lighten-4 {
  background: #FFECB3;
  /* The Fallback */
  background: rgba(255, 236, 179, 0.8); }

.amber.lighten-3 {
  color: #FFE082 !important; }

.bg-amber.bg-lighten-3 {
  background-color: #FFE082 !important; }

.btn-amber.btn-lighten-3 {
  border-color: #FFA000 !important;
  background-color: #FFE082 !important; }
  .btn-amber.btn-lighten-3:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-lighten-3:focus, .btn-amber.btn-lighten-3:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-lighten-3 {
  border-color: #FFE082 !important;
  color: #FFE082 !important; }
  .btn-outline-amber.btn-outline-lighten-3:hover {
    background-color: #FFE082 !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE082 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE082 !important; }

.border-amber.border-lighten-3 {
  border: 1px solid #FFE082 !important; }

.border-top-amber.border-top-lighten-3 {
  border-top: 1px solid #FFE082 !important; }

.border-bottom-amber.border-bottom-lighten-3 {
  border-bottom: 1px solid #FFE082 !important; }

.border-left-amber.border-left-lighten-3 {
  border-left: 1px solid #FFE082 !important; }

.border-right-amber.border-right-lighten-3 {
  border-right: 1px solid #FFE082 !important; }

.overlay-amber.overlay-lighten-3 {
  background: #FFE082;
  /* The Fallback */
  background: rgba(255, 224, 130, 0.8); }

.amber.lighten-2 {
  color: #FFD54F !important; }

.bg-amber.bg-lighten-2 {
  background-color: #FFD54F !important; }

.btn-amber.btn-lighten-2 {
  border-color: #FFA000 !important;
  background-color: #FFD54F !important; }
  .btn-amber.btn-lighten-2:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-lighten-2:focus, .btn-amber.btn-lighten-2:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-lighten-2 {
  border-color: #FFD54F !important;
  color: #FFD54F !important; }
  .btn-outline-amber.btn-outline-lighten-2:hover {
    background-color: #FFD54F !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD54F !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD54F !important; }

.border-amber.border-lighten-2 {
  border: 1px solid #FFD54F !important; }

.border-top-amber.border-top-lighten-2 {
  border-top: 1px solid #FFD54F !important; }

.border-bottom-amber.border-bottom-lighten-2 {
  border-bottom: 1px solid #FFD54F !important; }

.border-left-amber.border-left-lighten-2 {
  border-left: 1px solid #FFD54F !important; }

.border-right-amber.border-right-lighten-2 {
  border-right: 1px solid #FFD54F !important; }

.overlay-amber.overlay-lighten-2 {
  background: #FFD54F;
  /* The Fallback */
  background: rgba(255, 213, 79, 0.8); }

.amber.lighten-1 {
  color: #FFCA28 !important; }

.bg-amber.bg-lighten-1 {
  background-color: #FFCA28 !important; }

.btn-amber.btn-lighten-1 {
  border-color: #FFA000 !important;
  background-color: #FFCA28 !important; }
  .btn-amber.btn-lighten-1:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-lighten-1:focus, .btn-amber.btn-lighten-1:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-lighten-1 {
  border-color: #FFCA28 !important;
  color: #FFCA28 !important; }
  .btn-outline-amber.btn-outline-lighten-1:hover {
    background-color: #FFCA28 !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCA28 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCA28 !important; }

.border-amber.border-lighten-1 {
  border: 1px solid #FFCA28 !important; }

.border-top-amber.border-top-lighten-1 {
  border-top: 1px solid #FFCA28 !important; }

.border-bottom-amber.border-bottom-lighten-1 {
  border-bottom: 1px solid #FFCA28 !important; }

.border-left-amber.border-left-lighten-1 {
  border-left: 1px solid #FFCA28 !important; }

.border-right-amber.border-right-lighten-1 {
  border-right: 1px solid #FFCA28 !important; }

.overlay-amber.overlay-lighten-1 {
  background: #FFCA28;
  /* The Fallback */
  background: rgba(255, 202, 40, 0.8); }

.amber {
  color: #FFC107 !important; }

.bg-amber {
  background-color: #FFC107 !important; }
  .bg-amber .card-header, .bg-amber .card-footer {
    background-color: transparent; }

.alert-amber {
  border-color: #FFC107 !important;
  background-color: #ffd34e !important;
  color: #543f00 !important; }
  .alert-amber .alert-link {
    color: #2b2000 !important; }

.border-amber {
  border-color: #FFC107; }

.bg-amber.badge-glow, .border-amber.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #FFC107;
  box-shadow: 0px 0px 10px #FFC107; }

.overlay-amber {
  background: #FFC107;
  /* The Fallback */
  background: rgba(255, 193, 7, 0.8); }

/* .card-outline-amber{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-amber {
  border-color: #FFA000 !important;
  background-color: #FFC107 !important;
  color: #FFFFFF; }
  .btn-amber:hover {
    border-color: #FFB300 !important;
    background-color: #FFCA28 !important;
    color: #FFF !important; }
  .btn-amber:focus, .btn-amber:active {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important;
    color: #FFF !important; }

.btn-outline-amber {
  border-color: #FFC107;
  background-color: transparent;
  color: #FFC107; }
  .btn-outline-amber:hover {
    background-color: #FFC107;
    color: #FFF !important; }

input[type="checkbox"].bg-amber + .custom-control-label:before, input[type="radio"].bg-amber + .custom-control-label:before {
  background-color: #FFC107 !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFC107 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFC107 !important; }

.border-amber {
  border: 1px solid #FFC107 !important; }

.border-top-amber {
  border-top: 1px solid #FFC107; }

.border-bottom-amber {
  border-bottom: 1px solid #FFC107; }

.border-left-amber {
  border-left: 1px solid #FFC107; }

.border-right-amber {
  border-right: 1px solid #FFC107; }

.amber.darken-1 {
  color: #FFB300 !important; }

.bg-amber.bg-darken-1 {
  background-color: #FFB300 !important; }

.btn-amber.btn-darken-1 {
  border-color: #FFA000 !important;
  background-color: #FFB300 !important; }
  .btn-amber.btn-darken-1:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-darken-1:focus, .btn-amber.btn-darken-1:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-darken-1 {
  border-color: #FFB300 !important;
  color: #FFB300 !important; }
  .btn-outline-amber.btn-outline-darken-1:hover {
    background-color: #FFB300 !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFB300 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFB300 !important; }

.border-amber.border-darken-1 {
  border: 1px solid #FFB300 !important; }

.border-top-amber.border-top-darken-1 {
  border-top: 1px solid #FFB300 !important; }

.border-bottom-amber.border-bottom-darken-1 {
  border-bottom: 1px solid #FFB300 !important; }

.border-left-amber.border-left-darken-1 {
  border-left: 1px solid #FFB300 !important; }

.border-right-amber.border-right-darken-1 {
  border-right: 1px solid #FFB300 !important; }

.overlay-amber.overlay-darken-1 {
  background: #FFB300;
  /* The Fallback */
  background: rgba(255, 179, 0, 0.8); }

.amber.darken-2 {
  color: #FFA000 !important; }

.bg-amber.bg-darken-2 {
  background-color: #FFA000 !important; }

.btn-amber.btn-darken-2 {
  border-color: #FFA000 !important;
  background-color: #FFA000 !important; }
  .btn-amber.btn-darken-2:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-darken-2:focus, .btn-amber.btn-darken-2:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-darken-2 {
  border-color: #FFA000 !important;
  color: #FFA000 !important; }
  .btn-outline-amber.btn-outline-darken-2:hover {
    background-color: #FFA000 !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFA000 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFA000 !important; }

.border-amber.border-darken-2 {
  border: 1px solid #FFA000 !important; }

.border-top-amber.border-top-darken-2 {
  border-top: 1px solid #FFA000 !important; }

.border-bottom-amber.border-bottom-darken-2 {
  border-bottom: 1px solid #FFA000 !important; }

.border-left-amber.border-left-darken-2 {
  border-left: 1px solid #FFA000 !important; }

.border-right-amber.border-right-darken-2 {
  border-right: 1px solid #FFA000 !important; }

.overlay-amber.overlay-darken-2 {
  background: #FFA000;
  /* The Fallback */
  background: rgba(255, 160, 0, 0.8); }

.amber.darken-3 {
  color: #FF8F00 !important; }

.bg-amber.bg-darken-3 {
  background-color: #FF8F00 !important; }

.btn-amber.btn-darken-3 {
  border-color: #FFA000 !important;
  background-color: #FF8F00 !important; }
  .btn-amber.btn-darken-3:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-darken-3:focus, .btn-amber.btn-darken-3:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-darken-3 {
  border-color: #FF8F00 !important;
  color: #FF8F00 !important; }
  .btn-outline-amber.btn-outline-darken-3:hover {
    background-color: #FF8F00 !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8F00 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8F00 !important; }

.border-amber.border-darken-3 {
  border: 1px solid #FF8F00 !important; }

.border-top-amber.border-top-darken-3 {
  border-top: 1px solid #FF8F00 !important; }

.border-bottom-amber.border-bottom-darken-3 {
  border-bottom: 1px solid #FF8F00 !important; }

.border-left-amber.border-left-darken-3 {
  border-left: 1px solid #FF8F00 !important; }

.border-right-amber.border-right-darken-3 {
  border-right: 1px solid #FF8F00 !important; }

.overlay-amber.overlay-darken-3 {
  background: #FF8F00;
  /* The Fallback */
  background: rgba(255, 143, 0, 0.8); }

.amber.darken-4 {
  color: #FF6F00 !important; }

.bg-amber.bg-darken-4 {
  background-color: #FF6F00 !important; }

.btn-amber.btn-darken-4 {
  border-color: #FFA000 !important;
  background-color: #FF6F00 !important; }
  .btn-amber.btn-darken-4:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-darken-4:focus, .btn-amber.btn-darken-4:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-darken-4 {
  border-color: #FF6F00 !important;
  color: #FF6F00 !important; }
  .btn-outline-amber.btn-outline-darken-4:hover {
    background-color: #FF6F00 !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF6F00 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF6F00 !important; }

.border-amber.border-darken-4 {
  border: 1px solid #FF6F00 !important; }

.border-top-amber.border-top-darken-4 {
  border-top: 1px solid #FF6F00 !important; }

.border-bottom-amber.border-bottom-darken-4 {
  border-bottom: 1px solid #FF6F00 !important; }

.border-left-amber.border-left-darken-4 {
  border-left: 1px solid #FF6F00 !important; }

.border-right-amber.border-right-darken-4 {
  border-right: 1px solid #FF6F00 !important; }

.overlay-amber.overlay-darken-4 {
  background: #FF6F00;
  /* The Fallback */
  background: rgba(255, 111, 0, 0.8); }

.amber.accent-1 {
  color: #FFE57F !important; }

.bg-amber.bg-accent-1 {
  background-color: #FFE57F !important; }

.btn-amber.btn-accent-1 {
  border-color: #FFA000 !important;
  background-color: #FFE57F !important; }
  .btn-amber.btn-accent-1:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-accent-1:focus, .btn-amber.btn-accent-1:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-accent-1 {
  border-color: #FFE57F !important;
  color: #FFE57F !important; }
  .btn-outline-amber.btn-outline-accent-1:hover {
    background-color: #FFE57F !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE57F !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE57F !important; }

.border-amber.border-accent-1 {
  border: 1px solid #FFE57F !important; }

.border-top-amber.border-top-accent-1 {
  border-top: 1px solid #FFE57F !important; }

.border-bottom-amber.border-bottom-accent-1 {
  border-bottom: 1px solid #FFE57F !important; }

.border-left-amber.border-left-accent-1 {
  border-left: 1px solid #FFE57F !important; }

.border-right-amber.border-right-accent-1 {
  border-right: 1px solid #FFE57F !important; }

.overlay-amber.overlay-accent-1 {
  background: #FFE57F;
  /* The Fallback */
  background: rgba(255, 229, 127, 0.8); }

.amber.accent-2 {
  color: #FFD740 !important; }

.bg-amber.bg-accent-2 {
  background-color: #FFD740 !important; }

.btn-amber.btn-accent-2 {
  border-color: #FFA000 !important;
  background-color: #FFD740 !important; }
  .btn-amber.btn-accent-2:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-accent-2:focus, .btn-amber.btn-accent-2:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-accent-2 {
  border-color: #FFD740 !important;
  color: #FFD740 !important; }
  .btn-outline-amber.btn-outline-accent-2:hover {
    background-color: #FFD740 !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD740 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD740 !important; }

.border-amber.border-accent-2 {
  border: 1px solid #FFD740 !important; }

.border-top-amber.border-top-accent-2 {
  border-top: 1px solid #FFD740 !important; }

.border-bottom-amber.border-bottom-accent-2 {
  border-bottom: 1px solid #FFD740 !important; }

.border-left-amber.border-left-accent-2 {
  border-left: 1px solid #FFD740 !important; }

.border-right-amber.border-right-accent-2 {
  border-right: 1px solid #FFD740 !important; }

.overlay-amber.overlay-accent-2 {
  background: #FFD740;
  /* The Fallback */
  background: rgba(255, 215, 64, 0.8); }

.amber.accent-3 {
  color: #FFC400 !important; }

.bg-amber.bg-accent-3 {
  background-color: #FFC400 !important; }

.btn-amber.btn-accent-3 {
  border-color: #FFA000 !important;
  background-color: #FFC400 !important; }
  .btn-amber.btn-accent-3:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-accent-3:focus, .btn-amber.btn-accent-3:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-accent-3 {
  border-color: #FFC400 !important;
  color: #FFC400 !important; }
  .btn-outline-amber.btn-outline-accent-3:hover {
    background-color: #FFC400 !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFC400 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFC400 !important; }

.border-amber.border-accent-3 {
  border: 1px solid #FFC400 !important; }

.border-top-amber.border-top-accent-3 {
  border-top: 1px solid #FFC400 !important; }

.border-bottom-amber.border-bottom-accent-3 {
  border-bottom: 1px solid #FFC400 !important; }

.border-left-amber.border-left-accent-3 {
  border-left: 1px solid #FFC400 !important; }

.border-right-amber.border-right-accent-3 {
  border-right: 1px solid #FFC400 !important; }

.overlay-amber.overlay-accent-3 {
  background: #FFC400;
  /* The Fallback */
  background: rgba(255, 196, 0, 0.8); }

.amber.accent-4 {
  color: #FFAB00 !important; }

.bg-amber.bg-accent-4 {
  background-color: #FFAB00 !important; }

.btn-amber.btn-accent-4 {
  border-color: #FFA000 !important;
  background-color: #FFAB00 !important; }
  .btn-amber.btn-accent-4:hover {
    border-color: #FFA000 !important;
    background-color: #FF8F00 !important; }
  .btn-amber.btn-accent-4:focus, .btn-amber.btn-accent-4:active {
    border-color: #FF8F00 !important;
    background-color: #FF6F00 !important; }

.btn-outline-amber.btn-outline-accent-4 {
  border-color: #FFAB00 !important;
  color: #FFAB00 !important; }
  .btn-outline-amber.btn-outline-accent-4:hover {
    background-color: #FFAB00 !important; }

input:focus ~ .bg-amber {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFAB00 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFAB00 !important; }

.border-amber.border-accent-4 {
  border: 1px solid #FFAB00 !important; }

.border-top-amber.border-top-accent-4 {
  border-top: 1px solid #FFAB00 !important; }

.border-bottom-amber.border-bottom-accent-4 {
  border-bottom: 1px solid #FFAB00 !important; }

.border-left-amber.border-left-accent-4 {
  border-left: 1px solid #FFAB00 !important; }

.border-right-amber.border-right-accent-4 {
  border-right: 1px solid #FFAB00 !important; }

.overlay-amber.overlay-accent-4 {
  background: #FFAB00;
  /* The Fallback */
  background: rgba(255, 171, 0, 0.8); }

.blue-grey.lighten-5 {
  color: #ECEFF1 !important; }

.bg-blue-grey.bg-lighten-5 {
  background-color: #ECEFF1 !important; }

.btn-blue-grey.btn-lighten-5 {
  border-color: #455A64 !important;
  background-color: #ECEFF1 !important; }
  .btn-blue-grey.btn-lighten-5:hover {
    border-color: #455A64 !important;
    background-color: #37474F !important; }
  .btn-blue-grey.btn-lighten-5:focus, .btn-blue-grey.btn-lighten-5:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-5 {
  border-color: #ECEFF1 !important;
  color: #ECEFF1 !important; }
  .btn-outline-blue-grey.btn-outline-lighten-5:hover {
    background-color: #ECEFF1 !important; }

input:focus ~ .bg-blue-grey {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ECEFF1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ECEFF1 !important; }

.border-blue-grey.border-lighten-5 {
  border: 1px solid #ECEFF1 !important; }

.border-top-blue-grey.border-top-lighten-5 {
  border-top: 1px solid #ECEFF1 !important; }

.border-bottom-blue-grey.border-bottom-lighten-5 {
  border-bottom: 1px solid #ECEFF1 !important; }

.border-left-blue-grey.border-left-lighten-5 {
  border-left: 1px solid #ECEFF1 !important; }

.border-right-blue-grey.border-right-lighten-5 {
  border-right: 1px solid #ECEFF1 !important; }

.overlay-blue-grey.overlay-lighten-5 {
  background: #ECEFF1;
  /* The Fallback */
  background: rgba(236, 239, 241, 0.8); }

.blue-grey.lighten-4 {
  color: #CFD8DC !important; }

.bg-blue-grey.bg-lighten-4 {
  background-color: #CFD8DC !important; }

.btn-blue-grey.btn-lighten-4 {
  border-color: #455A64 !important;
  background-color: #CFD8DC !important; }
  .btn-blue-grey.btn-lighten-4:hover {
    border-color: #455A64 !important;
    background-color: #37474F !important; }
  .btn-blue-grey.btn-lighten-4:focus, .btn-blue-grey.btn-lighten-4:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-4 {
  border-color: #CFD8DC !important;
  color: #CFD8DC !important; }
  .btn-outline-blue-grey.btn-outline-lighten-4:hover {
    background-color: #CFD8DC !important; }

input:focus ~ .bg-blue-grey {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CFD8DC !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CFD8DC !important; }

.border-blue-grey.border-lighten-4 {
  border: 1px solid #CFD8DC !important; }

.border-top-blue-grey.border-top-lighten-4 {
  border-top: 1px solid #CFD8DC !important; }

.border-bottom-blue-grey.border-bottom-lighten-4 {
  border-bottom: 1px solid #CFD8DC !important; }

.border-left-blue-grey.border-left-lighten-4 {
  border-left: 1px solid #CFD8DC !important; }

.border-right-blue-grey.border-right-lighten-4 {
  border-right: 1px solid #CFD8DC !important; }

.overlay-blue-grey.overlay-lighten-4 {
  background: #CFD8DC;
  /* The Fallback */
  background: rgba(207, 216, 220, 0.8); }

.blue-grey.lighten-3 {
  color: #B0BEC5 !important; }

.bg-blue-grey.bg-lighten-3 {
  background-color: #B0BEC5 !important; }

.btn-blue-grey.btn-lighten-3 {
  border-color: #455A64 !important;
  background-color: #B0BEC5 !important; }
  .btn-blue-grey.btn-lighten-3:hover {
    border-color: #455A64 !important;
    background-color: #37474F !important; }
  .btn-blue-grey.btn-lighten-3:focus, .btn-blue-grey.btn-lighten-3:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-3 {
  border-color: #B0BEC5 !important;
  color: #B0BEC5 !important; }
  .btn-outline-blue-grey.btn-outline-lighten-3:hover {
    background-color: #B0BEC5 !important; }

input:focus ~ .bg-blue-grey {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B0BEC5 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B0BEC5 !important; }

.border-blue-grey.border-lighten-3 {
  border: 1px solid #B0BEC5 !important; }

.border-top-blue-grey.border-top-lighten-3 {
  border-top: 1px solid #B0BEC5 !important; }

.border-bottom-blue-grey.border-bottom-lighten-3 {
  border-bottom: 1px solid #B0BEC5 !important; }

.border-left-blue-grey.border-left-lighten-3 {
  border-left: 1px solid #B0BEC5 !important; }

.border-right-blue-grey.border-right-lighten-3 {
  border-right: 1px solid #B0BEC5 !important; }

.overlay-blue-grey.overlay-lighten-3 {
  background: #B0BEC5;
  /* The Fallback */
  background: rgba(176, 190, 197, 0.8); }

.blue-grey.lighten-2 {
  color: #90A4AE !important; }

.bg-blue-grey.bg-lighten-2 {
  background-color: #90A4AE !important; }

.btn-blue-grey.btn-lighten-2 {
  border-color: #455A64 !important;
  background-color: #90A4AE !important; }
  .btn-blue-grey.btn-lighten-2:hover {
    border-color: #455A64 !important;
    background-color: #37474F !important; }
  .btn-blue-grey.btn-lighten-2:focus, .btn-blue-grey.btn-lighten-2:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-2 {
  border-color: #90A4AE !important;
  color: #90A4AE !important; }
  .btn-outline-blue-grey.btn-outline-lighten-2:hover {
    background-color: #90A4AE !important; }

input:focus ~ .bg-blue-grey {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #90A4AE !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #90A4AE !important; }

.border-blue-grey.border-lighten-2 {
  border: 1px solid #90A4AE !important; }

.border-top-blue-grey.border-top-lighten-2 {
  border-top: 1px solid #90A4AE !important; }

.border-bottom-blue-grey.border-bottom-lighten-2 {
  border-bottom: 1px solid #90A4AE !important; }

.border-left-blue-grey.border-left-lighten-2 {
  border-left: 1px solid #90A4AE !important; }

.border-right-blue-grey.border-right-lighten-2 {
  border-right: 1px solid #90A4AE !important; }

.overlay-blue-grey.overlay-lighten-2 {
  background: #90A4AE;
  /* The Fallback */
  background: rgba(144, 164, 174, 0.8); }

.blue-grey.lighten-1 {
  color: #78909C !important; }

.bg-blue-grey.bg-lighten-1 {
  background-color: #78909C !important; }

.btn-blue-grey.btn-lighten-1 {
  border-color: #455A64 !important;
  background-color: #78909C !important; }
  .btn-blue-grey.btn-lighten-1:hover {
    border-color: #455A64 !important;
    background-color: #37474F !important; }
  .btn-blue-grey.btn-lighten-1:focus, .btn-blue-grey.btn-lighten-1:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-1 {
  border-color: #78909C !important;
  color: #78909C !important; }
  .btn-outline-blue-grey.btn-outline-lighten-1:hover {
    background-color: #78909C !important; }

input:focus ~ .bg-blue-grey {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #78909C !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #78909C !important; }

.border-blue-grey.border-lighten-1 {
  border: 1px solid #78909C !important; }

.border-top-blue-grey.border-top-lighten-1 {
  border-top: 1px solid #78909C !important; }

.border-bottom-blue-grey.border-bottom-lighten-1 {
  border-bottom: 1px solid #78909C !important; }

.border-left-blue-grey.border-left-lighten-1 {
  border-left: 1px solid #78909C !important; }

.border-right-blue-grey.border-right-lighten-1 {
  border-right: 1px solid #78909C !important; }

.overlay-blue-grey.overlay-lighten-1 {
  background: #78909C;
  /* The Fallback */
  background: rgba(120, 144, 156, 0.8); }

.blue-grey {
  color: #607D8B !important; }

.bg-blue-grey {
  background-color: #607D8B !important; }
  .bg-blue-grey .card-header, .bg-blue-grey .card-footer {
    background-color: transparent; }

.alert-blue-grey {
  border-color: #607D8B !important;
  background-color: #87a0ac !important;
  color: #171e21 !important; }
  .alert-blue-grey .alert-link {
    color: #060809 !important; }

.border-blue-grey {
  border-color: #607D8B; }

.bg-blue-grey.badge-glow, .border-blue-grey.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #607D8B;
  box-shadow: 0px 0px 10px #607D8B; }

.overlay-blue-grey {
  background: #607D8B;
  /* The Fallback */
  background: rgba(96, 125, 139, 0.8); }

/* .card-outline-blue-grey{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-blue-grey {
  border-color: #455A64 !important;
  background-color: #607D8B !important;
  color: #FFFFFF; }
  .btn-blue-grey:hover {
    border-color: #546E7A !important;
    background-color: #78909C !important;
    color: #FFF !important; }
  .btn-blue-grey:focus, .btn-blue-grey:active {
    border-color: #455A64 !important;
    background-color: #37474F !important;
    color: #FFF !important; }

.btn-outline-blue-grey {
  border-color: #607D8B;
  background-color: transparent;
  color: #607D8B; }
  .btn-outline-blue-grey:hover {
    background-color: #607D8B;
    color: #FFF !important; }

input[type="checkbox"].bg-blue-grey + .custom-control-label:before, input[type="radio"].bg-blue-grey + .custom-control-label:before {
  background-color: #607D8B !important; }

input:focus ~ .bg-blue-grey {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #607D8B !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #607D8B !important; }

.border-blue-grey {
  border: 1px solid #607D8B !important; }

.border-top-blue-grey {
  border-top: 1px solid #607D8B; }

.border-bottom-blue-grey {
  border-bottom: 1px solid #607D8B; }

.border-left-blue-grey {
  border-left: 1px solid #607D8B; }

.border-right-blue-grey {
  border-right: 1px solid #607D8B; }

.blue-grey.darken-1 {
  color: #546E7A !important; }

.bg-blue-grey.bg-darken-1 {
  background-color: #546E7A !important; }

.btn-blue-grey.btn-darken-1 {
  border-color: #455A64 !important;
  background-color: #546E7A !important; }
  .btn-blue-grey.btn-darken-1:hover {
    border-color: #455A64 !important;
    background-color: #37474F !important; }
  .btn-blue-grey.btn-darken-1:focus, .btn-blue-grey.btn-darken-1:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-darken-1 {
  border-color: #546E7A !important;
  color: #546E7A !important; }
  .btn-outline-blue-grey.btn-outline-darken-1:hover {
    background-color: #546E7A !important; }

input:focus ~ .bg-blue-grey {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #546E7A !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #546E7A !important; }

.border-blue-grey.border-darken-1 {
  border: 1px solid #546E7A !important; }

.border-top-blue-grey.border-top-darken-1 {
  border-top: 1px solid #546E7A !important; }

.border-bottom-blue-grey.border-bottom-darken-1 {
  border-bottom: 1px solid #546E7A !important; }

.border-left-blue-grey.border-left-darken-1 {
  border-left: 1px solid #546E7A !important; }

.border-right-blue-grey.border-right-darken-1 {
  border-right: 1px solid #546E7A !important; }

.overlay-blue-grey.overlay-darken-1 {
  background: #546E7A;
  /* The Fallback */
  background: rgba(84, 110, 122, 0.8); }

.blue-grey.darken-2 {
  color: #455A64 !important; }

.bg-blue-grey.bg-darken-2 {
  background-color: #455A64 !important; }

.btn-blue-grey.btn-darken-2 {
  border-color: #455A64 !important;
  background-color: #455A64 !important; }
  .btn-blue-grey.btn-darken-2:hover {
    border-color: #455A64 !important;
    background-color: #37474F !important; }
  .btn-blue-grey.btn-darken-2:focus, .btn-blue-grey.btn-darken-2:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-darken-2 {
  border-color: #455A64 !important;
  color: #455A64 !important; }
  .btn-outline-blue-grey.btn-outline-darken-2:hover {
    background-color: #455A64 !important; }

input:focus ~ .bg-blue-grey {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #455A64 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #455A64 !important; }

.border-blue-grey.border-darken-2 {
  border: 1px solid #455A64 !important; }

.border-top-blue-grey.border-top-darken-2 {
  border-top: 1px solid #455A64 !important; }

.border-bottom-blue-grey.border-bottom-darken-2 {
  border-bottom: 1px solid #455A64 !important; }

.border-left-blue-grey.border-left-darken-2 {
  border-left: 1px solid #455A64 !important; }

.border-right-blue-grey.border-right-darken-2 {
  border-right: 1px solid #455A64 !important; }

.overlay-blue-grey.overlay-darken-2 {
  background: #455A64;
  /* The Fallback */
  background: rgba(69, 90, 100, 0.8); }

.blue-grey.darken-3 {
  color: #37474F !important; }

.bg-blue-grey.bg-darken-3 {
  background-color: #37474F !important; }

.btn-blue-grey.btn-darken-3 {
  border-color: #455A64 !important;
  background-color: #37474F !important; }
  .btn-blue-grey.btn-darken-3:hover {
    border-color: #455A64 !important;
    background-color: #37474F !important; }
  .btn-blue-grey.btn-darken-3:focus, .btn-blue-grey.btn-darken-3:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-darken-3 {
  border-color: #37474F !important;
  color: #37474F !important; }
  .btn-outline-blue-grey.btn-outline-darken-3:hover {
    background-color: #37474F !important; }

input:focus ~ .bg-blue-grey {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #37474F !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #37474F !important; }

.border-blue-grey.border-darken-3 {
  border: 1px solid #37474F !important; }

.border-top-blue-grey.border-top-darken-3 {
  border-top: 1px solid #37474F !important; }

.border-bottom-blue-grey.border-bottom-darken-3 {
  border-bottom: 1px solid #37474F !important; }

.border-left-blue-grey.border-left-darken-3 {
  border-left: 1px solid #37474F !important; }

.border-right-blue-grey.border-right-darken-3 {
  border-right: 1px solid #37474F !important; }

.overlay-blue-grey.overlay-darken-3 {
  background: #37474F;
  /* The Fallback */
  background: rgba(55, 71, 79, 0.8); }

.blue-grey.darken-4 {
  color: #263238 !important; }

.bg-blue-grey.bg-darken-4 {
  background-color: #263238 !important; }

.btn-blue-grey.btn-darken-4 {
  border-color: #455A64 !important;
  background-color: #263238 !important; }
  .btn-blue-grey.btn-darken-4:hover {
    border-color: #455A64 !important;
    background-color: #37474F !important; }
  .btn-blue-grey.btn-darken-4:focus, .btn-blue-grey.btn-darken-4:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-darken-4 {
  border-color: #263238 !important;
  color: #263238 !important; }
  .btn-outline-blue-grey.btn-outline-darken-4:hover {
    background-color: #263238 !important; }

input:focus ~ .bg-blue-grey {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #263238 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #263238 !important; }

.border-blue-grey.border-darken-4 {
  border: 1px solid #263238 !important; }

.border-top-blue-grey.border-top-darken-4 {
  border-top: 1px solid #263238 !important; }

.border-bottom-blue-grey.border-bottom-darken-4 {
  border-bottom: 1px solid #263238 !important; }

.border-left-blue-grey.border-left-darken-4 {
  border-left: 1px solid #263238 !important; }

.border-right-blue-grey.border-right-darken-4 {
  border-right: 1px solid #263238 !important; }

.overlay-blue-grey.overlay-darken-4 {
  background: #263238;
  /* The Fallback */
  background: rgba(38, 50, 56, 0.8); }

.grey-blue.lighten-5 {
  color: #ECEFF1 !important; }

.bg-grey-blue.bg-lighten-5 {
  background-color: #ECEFF1 !important; }

.btn-grey-blue.btn-lighten-5 {
  border-color: #404E67 !important;
  background-color: #ECEFF1 !important; }
  .btn-grey-blue.btn-lighten-5:hover {
    border-color: #404E67 !important;
    background-color: #37474F !important; }
  .btn-grey-blue.btn-lighten-5:focus, .btn-grey-blue.btn-lighten-5:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-grey-blue.btn-outline-lighten-5 {
  border-color: #ECEFF1 !important;
  color: #ECEFF1 !important; }
  .btn-outline-grey-blue.btn-outline-lighten-5:hover {
    background-color: #ECEFF1 !important; }

input:focus ~ .bg-grey-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ECEFF1 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ECEFF1 !important; }

.border-grey-blue.border-lighten-5 {
  border: 1px solid #ECEFF1 !important; }

.border-top-grey-blue.border-top-lighten-5 {
  border-top: 1px solid #ECEFF1 !important; }

.border-bottom-grey-blue.border-bottom-lighten-5 {
  border-bottom: 1px solid #ECEFF1 !important; }

.border-left-grey-blue.border-left-lighten-5 {
  border-left: 1px solid #ECEFF1 !important; }

.border-right-grey-blue.border-right-lighten-5 {
  border-right: 1px solid #ECEFF1 !important; }

.overlay-grey-blue.overlay-lighten-5 {
  background: #ECEFF1;
  /* The Fallback */
  background: rgba(236, 239, 241, 0.8); }

.grey-blue.lighten-4 {
  color: #CFD8DC !important; }

.bg-grey-blue.bg-lighten-4 {
  background-color: #CFD8DC !important; }

.btn-grey-blue.btn-lighten-4 {
  border-color: #404E67 !important;
  background-color: #CFD8DC !important; }
  .btn-grey-blue.btn-lighten-4:hover {
    border-color: #404E67 !important;
    background-color: #37474F !important; }
  .btn-grey-blue.btn-lighten-4:focus, .btn-grey-blue.btn-lighten-4:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-grey-blue.btn-outline-lighten-4 {
  border-color: #CFD8DC !important;
  color: #CFD8DC !important; }
  .btn-outline-grey-blue.btn-outline-lighten-4:hover {
    background-color: #CFD8DC !important; }

input:focus ~ .bg-grey-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CFD8DC !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CFD8DC !important; }

.border-grey-blue.border-lighten-4 {
  border: 1px solid #CFD8DC !important; }

.border-top-grey-blue.border-top-lighten-4 {
  border-top: 1px solid #CFD8DC !important; }

.border-bottom-grey-blue.border-bottom-lighten-4 {
  border-bottom: 1px solid #CFD8DC !important; }

.border-left-grey-blue.border-left-lighten-4 {
  border-left: 1px solid #CFD8DC !important; }

.border-right-grey-blue.border-right-lighten-4 {
  border-right: 1px solid #CFD8DC !important; }

.overlay-grey-blue.overlay-lighten-4 {
  background: #CFD8DC;
  /* The Fallback */
  background: rgba(207, 216, 220, 0.8); }

.grey-blue.lighten-3 {
  color: #B0BEC5 !important; }

.bg-grey-blue.bg-lighten-3 {
  background-color: #B0BEC5 !important; }

.btn-grey-blue.btn-lighten-3 {
  border-color: #404E67 !important;
  background-color: #B0BEC5 !important; }
  .btn-grey-blue.btn-lighten-3:hover {
    border-color: #404E67 !important;
    background-color: #37474F !important; }
  .btn-grey-blue.btn-lighten-3:focus, .btn-grey-blue.btn-lighten-3:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-grey-blue.btn-outline-lighten-3 {
  border-color: #B0BEC5 !important;
  color: #B0BEC5 !important; }
  .btn-outline-grey-blue.btn-outline-lighten-3:hover {
    background-color: #B0BEC5 !important; }

input:focus ~ .bg-grey-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B0BEC5 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B0BEC5 !important; }

.border-grey-blue.border-lighten-3 {
  border: 1px solid #B0BEC5 !important; }

.border-top-grey-blue.border-top-lighten-3 {
  border-top: 1px solid #B0BEC5 !important; }

.border-bottom-grey-blue.border-bottom-lighten-3 {
  border-bottom: 1px solid #B0BEC5 !important; }

.border-left-grey-blue.border-left-lighten-3 {
  border-left: 1px solid #B0BEC5 !important; }

.border-right-grey-blue.border-right-lighten-3 {
  border-right: 1px solid #B0BEC5 !important; }

.overlay-grey-blue.overlay-lighten-3 {
  background: #B0BEC5;
  /* The Fallback */
  background: rgba(176, 190, 197, 0.8); }

.grey-blue.lighten-2 {
  color: #6F85AD !important; }

.bg-grey-blue.bg-lighten-2 {
  background-color: #6F85AD !important; }

.btn-grey-blue.btn-lighten-2 {
  border-color: #404E67 !important;
  background-color: #6F85AD !important; }
  .btn-grey-blue.btn-lighten-2:hover {
    border-color: #404E67 !important;
    background-color: #37474F !important; }
  .btn-grey-blue.btn-lighten-2:focus, .btn-grey-blue.btn-lighten-2:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-grey-blue.btn-outline-lighten-2 {
  border-color: #6F85AD !important;
  color: #6F85AD !important; }
  .btn-outline-grey-blue.btn-outline-lighten-2:hover {
    background-color: #6F85AD !important; }

input:focus ~ .bg-grey-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6F85AD !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6F85AD !important; }

.border-grey-blue.border-lighten-2 {
  border: 1px solid #6F85AD !important; }

.border-top-grey-blue.border-top-lighten-2 {
  border-top: 1px solid #6F85AD !important; }

.border-bottom-grey-blue.border-bottom-lighten-2 {
  border-bottom: 1px solid #6F85AD !important; }

.border-left-grey-blue.border-left-lighten-2 {
  border-left: 1px solid #6F85AD !important; }

.border-right-grey-blue.border-right-lighten-2 {
  border-right: 1px solid #6F85AD !important; }

.overlay-grey-blue.overlay-lighten-2 {
  background: #6F85AD;
  /* The Fallback */
  background: rgba(111, 133, 173, 0.8); }

.grey-blue.lighten-1 {
  color: #78909C !important; }

.bg-grey-blue.bg-lighten-1 {
  background-color: #78909C !important; }

.btn-grey-blue.btn-lighten-1 {
  border-color: #404E67 !important;
  background-color: #78909C !important; }
  .btn-grey-blue.btn-lighten-1:hover {
    border-color: #404E67 !important;
    background-color: #37474F !important; }
  .btn-grey-blue.btn-lighten-1:focus, .btn-grey-blue.btn-lighten-1:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-grey-blue.btn-outline-lighten-1 {
  border-color: #78909C !important;
  color: #78909C !important; }
  .btn-outline-grey-blue.btn-outline-lighten-1:hover {
    background-color: #78909C !important; }

input:focus ~ .bg-grey-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #78909C !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #78909C !important; }

.border-grey-blue.border-lighten-1 {
  border: 1px solid #78909C !important; }

.border-top-grey-blue.border-top-lighten-1 {
  border-top: 1px solid #78909C !important; }

.border-bottom-grey-blue.border-bottom-lighten-1 {
  border-bottom: 1px solid #78909C !important; }

.border-left-grey-blue.border-left-lighten-1 {
  border-left: 1px solid #78909C !important; }

.border-right-grey-blue.border-right-lighten-1 {
  border-right: 1px solid #78909C !important; }

.overlay-grey-blue.overlay-lighten-1 {
  background: #78909C;
  /* The Fallback */
  background: rgba(120, 144, 156, 0.8); }

.grey-blue {
  color: #1B2942 !important; }

.bg-grey-blue {
  background-color: #1B2942 !important; }
  .bg-grey-blue .card-header, .bg-grey-blue .card-footer {
    background-color: transparent; }

.alert-grey-blue {
  border-color: #1B2942 !important;
  background-color: #304875 !important;
  color: black !important; }
  .alert-grey-blue .alert-link {
    color: black !important; }

.border-grey-blue {
  border-color: #1B2942; }

.bg-grey-blue.badge-glow, .border-grey-blue.badge-glow {
  -webkit-box-shadow: 0px 0px 10px #1B2942;
  box-shadow: 0px 0px 10px #1B2942; }

.overlay-grey-blue {
  background: #1B2942;
  /* The Fallback */
  background: rgba(27, 41, 66, 0.8); }

/* .card-outline-grey-blue{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-grey-blue {
  border-color: #404E67 !important;
  background-color: #1B2942 !important;
  color: #FFFFFF; }
  .btn-grey-blue:hover {
    border-color: #546E7A !important;
    background-color: #78909C !important;
    color: #FFF !important; }
  .btn-grey-blue:focus, .btn-grey-blue:active {
    border-color: #404E67 !important;
    background-color: #37474F !important;
    color: #FFF !important; }

.btn-outline-grey-blue {
  border-color: #1B2942;
  background-color: transparent;
  color: #1B2942; }
  .btn-outline-grey-blue:hover {
    background-color: #1B2942;
    color: #FFF !important; }

input[type="checkbox"].bg-grey-blue + .custom-control-label:before, input[type="radio"].bg-grey-blue + .custom-control-label:before {
  background-color: #1B2942 !important; }

input:focus ~ .bg-grey-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1B2942 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1B2942 !important; }

.border-grey-blue {
  border: 1px solid #1B2942 !important; }

.border-top-grey-blue {
  border-top: 1px solid #1B2942; }

.border-bottom-grey-blue {
  border-bottom: 1px solid #1B2942; }

.border-left-grey-blue {
  border-left: 1px solid #1B2942; }

.border-right-grey-blue {
  border-right: 1px solid #1B2942; }

.grey-blue.darken-1 {
  color: #546E7A !important; }

.bg-grey-blue.bg-darken-1 {
  background-color: #546E7A !important; }

.btn-grey-blue.btn-darken-1 {
  border-color: #404E67 !important;
  background-color: #546E7A !important; }
  .btn-grey-blue.btn-darken-1:hover {
    border-color: #404E67 !important;
    background-color: #37474F !important; }
  .btn-grey-blue.btn-darken-1:focus, .btn-grey-blue.btn-darken-1:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-grey-blue.btn-outline-darken-1 {
  border-color: #546E7A !important;
  color: #546E7A !important; }
  .btn-outline-grey-blue.btn-outline-darken-1:hover {
    background-color: #546E7A !important; }

input:focus ~ .bg-grey-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #546E7A !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #546E7A !important; }

.border-grey-blue.border-darken-1 {
  border: 1px solid #546E7A !important; }

.border-top-grey-blue.border-top-darken-1 {
  border-top: 1px solid #546E7A !important; }

.border-bottom-grey-blue.border-bottom-darken-1 {
  border-bottom: 1px solid #546E7A !important; }

.border-left-grey-blue.border-left-darken-1 {
  border-left: 1px solid #546E7A !important; }

.border-right-grey-blue.border-right-darken-1 {
  border-right: 1px solid #546E7A !important; }

.overlay-grey-blue.overlay-darken-1 {
  background: #546E7A;
  /* The Fallback */
  background: rgba(84, 110, 122, 0.8); }

.grey-blue.darken-2 {
  color: #404E67 !important; }

.bg-grey-blue.bg-darken-2 {
  background-color: #404E67 !important; }

.btn-grey-blue.btn-darken-2 {
  border-color: #404E67 !important;
  background-color: #404E67 !important; }
  .btn-grey-blue.btn-darken-2:hover {
    border-color: #404E67 !important;
    background-color: #37474F !important; }
  .btn-grey-blue.btn-darken-2:focus, .btn-grey-blue.btn-darken-2:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-grey-blue.btn-outline-darken-2 {
  border-color: #404E67 !important;
  color: #404E67 !important; }
  .btn-outline-grey-blue.btn-outline-darken-2:hover {
    background-color: #404E67 !important; }

input:focus ~ .bg-grey-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #404E67 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #404E67 !important; }

.border-grey-blue.border-darken-2 {
  border: 1px solid #404E67 !important; }

.border-top-grey-blue.border-top-darken-2 {
  border-top: 1px solid #404E67 !important; }

.border-bottom-grey-blue.border-bottom-darken-2 {
  border-bottom: 1px solid #404E67 !important; }

.border-left-grey-blue.border-left-darken-2 {
  border-left: 1px solid #404E67 !important; }

.border-right-grey-blue.border-right-darken-2 {
  border-right: 1px solid #404E67 !important; }

.overlay-grey-blue.overlay-darken-2 {
  background: #404E67;
  /* The Fallback */
  background: rgba(64, 78, 103, 0.8); }

.grey-blue.darken-3 {
  color: #37474F !important; }

.bg-grey-blue.bg-darken-3 {
  background-color: #37474F !important; }

.btn-grey-blue.btn-darken-3 {
  border-color: #404E67 !important;
  background-color: #37474F !important; }
  .btn-grey-blue.btn-darken-3:hover {
    border-color: #404E67 !important;
    background-color: #37474F !important; }
  .btn-grey-blue.btn-darken-3:focus, .btn-grey-blue.btn-darken-3:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-grey-blue.btn-outline-darken-3 {
  border-color: #37474F !important;
  color: #37474F !important; }
  .btn-outline-grey-blue.btn-outline-darken-3:hover {
    background-color: #37474F !important; }

input:focus ~ .bg-grey-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #37474F !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #37474F !important; }

.border-grey-blue.border-darken-3 {
  border: 1px solid #37474F !important; }

.border-top-grey-blue.border-top-darken-3 {
  border-top: 1px solid #37474F !important; }

.border-bottom-grey-blue.border-bottom-darken-3 {
  border-bottom: 1px solid #37474F !important; }

.border-left-grey-blue.border-left-darken-3 {
  border-left: 1px solid #37474F !important; }

.border-right-grey-blue.border-right-darken-3 {
  border-right: 1px solid #37474F !important; }

.overlay-grey-blue.overlay-darken-3 {
  background: #37474F;
  /* The Fallback */
  background: rgba(55, 71, 79, 0.8); }

.grey-blue.darken-4 {
  color: #263238 !important; }

.bg-grey-blue.bg-darken-4 {
  background-color: #263238 !important; }

.btn-grey-blue.btn-darken-4 {
  border-color: #404E67 !important;
  background-color: #263238 !important; }
  .btn-grey-blue.btn-darken-4:hover {
    border-color: #404E67 !important;
    background-color: #37474F !important; }
  .btn-grey-blue.btn-darken-4:focus, .btn-grey-blue.btn-darken-4:active {
    border-color: #37474F !important;
    background-color: #263238 !important; }

.btn-outline-grey-blue.btn-outline-darken-4 {
  border-color: #263238 !important;
  color: #263238 !important; }
  .btn-outline-grey-blue.btn-outline-darken-4:hover {
    background-color: #263238 !important; }

input:focus ~ .bg-grey-blue {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #263238 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #263238 !important; }

.border-grey-blue.border-darken-4 {
  border: 1px solid #263238 !important; }

.border-top-grey-blue.border-top-darken-4 {
  border-top: 1px solid #263238 !important; }

.border-bottom-grey-blue.border-bottom-darken-4 {
  border-bottom: 1px solid #263238 !important; }

.border-left-grey-blue.border-left-darken-4 {
  border-left: 1px solid #263238 !important; }

.border-right-grey-blue.border-right-darken-4 {
  border-right: 1px solid #263238 !important; }

.overlay-grey-blue.overlay-darken-4 {
  background: #263238;
  /* The Fallback */
  background: rgba(38, 50, 56, 0.8); }

.shades.black {
  color: #000000 !important; }

.bg-shades.bg-black {
  background-color: #000000 !important; }

.btn-shades.btn-black {
  border-color: !important;
  background-color: #000000 !important; }
  .btn-shades.btn-black:hover {
    border-color: !important;
    background-color: !important; }
  .btn-shades.btn-black:focus, .btn-shades.btn-black:active {
    border-color: !important;
    background-color: !important; }

.btn-outline-shades.btn-outline-black {
  border-color: #000000 !important;
  color: #000000 !important; }
  .btn-outline-shades.btn-outline-black:hover {
    background-color: #000000 !important; }

input:focus ~ .bg-shades {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000 !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000 !important; }

.border-shades.border-black {
  border: 1px solid #000000 !important; }

.border-top-shades.border-top-black {
  border-top: 1px solid #000000 !important; }

.border-bottom-shades.border-bottom-black {
  border-bottom: 1px solid #000000 !important; }

.border-left-shades.border-left-black {
  border-left: 1px solid #000000 !important; }

.border-right-shades.border-right-black {
  border-right: 1px solid #000000 !important; }

.overlay-shades.overlay-black {
  background: #000000;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.8); }

.shades.white {
  color: #FFFFFF !important; }

.bg-shades.bg-white {
  background-color: #FFFFFF !important; }

.btn-shades.btn-white {
  border-color: !important;
  background-color: #FFFFFF !important; }
  .btn-shades.btn-white:hover {
    border-color: !important;
    background-color: !important; }
  .btn-shades.btn-white:focus, .btn-shades.btn-white:active {
    border-color: !important;
    background-color: !important; }

.btn-outline-shades.btn-outline-white {
  border-color: #FFFFFF !important;
  color: #FFFFFF !important; }
  .btn-outline-shades.btn-outline-white:hover {
    background-color: #FFFFFF !important; }

input:focus ~ .bg-shades {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important; }

.border-shades.border-white {
  border: 1px solid #FFFFFF !important; }

.border-top-shades.border-top-white {
  border-top: 1px solid #FFFFFF !important; }

.border-bottom-shades.border-bottom-white {
  border-bottom: 1px solid #FFFFFF !important; }

.border-left-shades.border-left-white {
  border-left: 1px solid #FFFFFF !important; }

.border-right-shades.border-right-white {
  border-right: 1px solid #FFFFFF !important; }

.overlay-shades.overlay-white {
  background: #FFFFFF;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8); }

.shades.transparent {
  color: transparent !important; }

.bg-shades.bg-transparent {
  background-color: transparent !important; }

.btn-shades.btn-transparent {
  border-color: !important;
  background-color: transparent !important; }
  .btn-shades.btn-transparent:hover {
    border-color: !important;
    background-color: !important; }
  .btn-shades.btn-transparent:focus, .btn-shades.btn-transparent:active {
    border-color: !important;
    background-color: !important; }

.btn-outline-shades.btn-outline-transparent {
  border-color: transparent !important;
  color: transparent !important; }
  .btn-outline-shades.btn-outline-transparent:hover {
    background-color: transparent !important; }

input:focus ~ .bg-shades {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem transparent !important;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem transparent !important; }

.border-shades.border-transparent {
  border: 1px solid transparent !important; }

.border-top-shades.border-top-transparent {
  border-top: 1px solid transparent !important; }

.border-bottom-shades.border-bottom-transparent {
  border-bottom: 1px solid transparent !important; }

.border-left-shades.border-left-transparent {
  border-left: 1px solid transparent !important; }

.border-right-shades.border-right-transparent {
  border-right: 1px solid transparent !important; }

.overlay-shades.overlay-transparent {
  background: transparent;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.8); }

.black {
  color: #000000; }

.bg-black {
  background-color: #000000; }

input:focus ~ .bg-black {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000; }

.border-black {
  border: 1px solid #000000; }

.border-top-black {
  border-top: 1px solid #000000; }

.border-bottom-black {
  border-bottom: 1px solid #000000; }

.border-left-black {
  border-left: 1px solid #000000; }

.border-right-black {
  border-right: 1px solid #000000; }

.white {
  color: #FFFFFF; }

.bg-white {
  background-color: #FFFFFF; }

input:focus ~ .bg-white {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF; }

.border-white {
  border: 1px solid #FFFFFF; }

.border-top-white {
  border-top: 1px solid #FFFFFF; }

.border-bottom-white {
  border-bottom: 1px solid #FFFFFF; }

.border-left-white {
  border-left: 1px solid #FFFFFF; }

.border-right-white {
  border-right: 1px solid #FFFFFF; }

.transparent {
  color: transparent; }

.bg-transparent {
  background-color: transparent; }

input:focus ~ .bg-transparent {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem transparent;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem transparent; }

.border-transparent {
  border: 1px solid transparent; }

.border-top-transparent {
  border-top: 1px solid transparent; }

.border-bottom-transparent {
  border-bottom: 1px solid transparent; }

.border-left-transparent {
  border-left: 1px solid transparent; }

.border-right-transparent {
  border-right: 1px solid transparent; }
